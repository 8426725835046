import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {apiEndPoints, config, environment, routingEndpoint} from '../../../../../../environments/root/environment';
import {map} from 'rxjs/operators';
import {RequestService} from '../../../../../global/services/request/request.service';
import {TranslateService} from '@ngx-translate/core';
import {SiteService} from '../../../../../global/services/site/site.service';
import {CookiesSettingsAdapt} from '../../../../model/response/cookies-settings';
import {Router} from '@angular/router';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-jp-cookies-settings',
  templateUrl: './jp-cookies-settings.component.html',
  styleUrls: ['./jp-cookies-settings.component.scss'],
  providers:[CookiesSettingsAdapt]
})
export class JpCookiesSettingsComponent implements OnInit, OnDestroy {
public cookiesSettingLinks : any;
public disableLogin : string;
requestServiceGetRequestSubscription:Subscription;
  marketingPrefContactURL:any;
  marketingPrefContactRoute: boolean;
  @Output() isGDPRTracking = new EventEmitter<any>();
  constructor(private router: Router,private requestService: RequestService,private cookiesSettingsAdapt: CookiesSettingsAdapt,
              private _translate :TranslateService, private siteService:SiteService) {
    this._translate.setDefaultLang(this.siteService.getSiteLocale());
    this.disableLogin=environment[config.theme + this.siteService.getSiteLocale()]['config']['disable_login'];
    this.marketingPrefContactURL = environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url][routingEndpoint.marketingPrefContactPage];
    console.log('this.marketingPrefContactURL',this.marketingPrefContactURL);
    if(this.marketingPrefContactURL != undefined){ 
    if (this.router.url.indexOf(this.marketingPrefContactURL) > -1) {
      this.marketingPrefContactRoute = true;
    }
   }
  }

  ngOnInit() {
    this.getCookiesInfo();
  }

  getCookiesInfo(){
    this.requestServiceGetRequestSubscription = this.requestService.getRequest(config.theme,apiEndPoints.gateWayAPI,apiEndPoints.getGdprConfig,null,null).pipe(
      map((res: CookiesSettingsAdapt) => this.cookiesSettingsAdapt.adapt(res)))
      .subscribe((res) => {
        if (res.status === config.SUCCESS && res.data) {
          this.cookiesSettingLinks=res.data;
          this.isGDPRTracking.emit(this.cookiesSettingLinks.isGDPRTrackingEnabled);
        }
      }, (error) => {

      });
  }

  navigateToLogin(){
    let orgName = this.router.routerState.snapshot.root.firstChild.params["orgName"];
    let routingUrl ='';
    if(orgName){
      let url = environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url_org][routingEndpoint.login];
      routingUrl = url.replace(':orgName',orgName);
    }
    else
      routingUrl = environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url][routingEndpoint.login];
    this.router.navigateByUrl(routingUrl);
  }
  ngOnDestroy(): void {
    if(this.requestServiceGetRequestSubscription){
    this.requestServiceGetRequestSubscription.unsubscribe();}
  }
}
