<div class="after-apply-process bg-white" *ngIf="!isJobApplySuccess">
  <h3 *ngIf = "!isHungaryCountry" [translate]="'what_hpn_now'"></h3>
  <h3 class="hu-h3" *ngIf = "isHungaryCountry" [translate]="'what_hpn_now'"></h3>
  <ul>
    <li><i  class="icon-mail-alt"></i><div [translate]="'email_icon_txt'"></div></li>
    <li><i class="icon-ok"></i><div [translate]="'respond_icon_txt'"></div></li>
    <li *ngIf = "haysCareers.indexOf(orgName) <= -1"><i class="icon-search"></i><div [innerHTML]="'search_icon_txt'|translate"></div></li>
  </ul>
</div>


<div class="after-apply-process bg-white" *ngIf="isJobApplySuccess">
  <h3 *ngIf = "!isHungaryCountry" [translate]="'what_hpn_now'"></h3>
  <h3 class="hu-h3" *ngIf = "isHungaryCountry" [translate]="'what_hpn_now'"></h3>
  <ul>
    <li><i  class="icon-mail-alt"></i><div [innerHTML]="'email_icon_txt'|translate"></div></li>
    <li><i class="icon-ok"></i><div><span> <strong [translate]="'respond_icon_txt2'|translate">  </strong></span> <span [translate]="'respond_icon_txt'|translate"> </span><a class="job-underline" [href]="lifeRaybaseURL +officesnearmeURL" target='_blank' [translate]="'respond_icon_txt1'|translate"></a></div></li>
    <li *ngIf = "haysCareers.indexOf(orgName) <= -1"><i class="icon-search"></i>
     <div>
      <span> <strong [translate]="'search_icon_bold_txt'|translate">  </strong></span> <span [translate]="'search_icon_txt'|translate"></span>
      <a class="job-underline"  [href]="lifeRaybaseURL +jobsURL" target='_blank' [translate]="'search_icon_link_txt1'|translate"></a><span [translate]="'search_icon_txt1'|translate"> </span>
      <a class="job-underline" [href]="lifeRaybaseURL +jobalertsURL" target='_blank' [translate]="'search_icon_link_txt2'|translate"></a><span [translate]="'search_icon_txt2'|translate"> </span>
      <a class="job-underline" [href]="lifeRaybaseURL +careeradvice" target='_blank' [translate]="'search_icon_link_txt3'|translate"></a><span [translate]="'search_icon_txt3'|translate"> </span>
      <a class="job-underline" [href]="lifeRaybaseURL +resumescoverletters" target='_blank' [translate]="'search_icon_link_txt4'|translate"></a><span [translate]="'search_icon_txt4'|translate"> </span>
      <a class="job-underline" [href]="lifeRaybaseURL +interviewtips" target='_blank' [translate]="'search_icon_link_txt5'|translate"></a>
    </div>
   </li>
  </ul>
</div>
