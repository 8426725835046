import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {RequestService} from '../../../../global/services/request/request.service';
import {TranslateService} from '@ngx-translate/core';
import {SiteService} from '../../../../global/services/site/site.service';
import {ActivatedRoute, Router} from '@angular/router';
import {apiEndPoints, config,routingEndpoint} from '../../../../../environments/root/environment';
import {Subscription} from 'rxjs';
import {MarketingConsent} from "../../../../jobportal/model/request/marketing-consent";
import {DataSharingService} from "../../../../jobportal/service/data-sharing.service";
import {ExtraFieldsConfig, JobSearchFilter} from "../../../../jobportal/model/response/google-job-response";
import {PersonalDetails} from "../../../../jobportal/model/response/my-profile";
import {JobSearchService} from "../../../../jobportal/service/job-search.service";
import {TokenStorage} from "../../../services/tokenStorage/token-storage";
import {SaveWhatsappRequest} from "../../../../jobportal/model/request/save-whatsapp-request";
import {JobAlertRequest} from "../../../../jobportal/model/request/job-alert-request";
import * as $ from 'jquery';
import {UpdateWhatsappRequest} from "../../../../jobportal/model/request/update-whatsapp-request";


@Component({
  selector: 'app-jp-alert-popup',
  templateUrl: './jp-alert-popup.component.html',
  styleUrls: ['./jp-alert-popup.component.scss']
})
export class JpAlertPopupComponent implements OnInit {

  public extraConfig: ExtraFieldsConfig;
  @Input() keyWord: string;
  @Output() public ismaximumAlertExceeds: EventEmitter<boolean> = new EventEmitter<any>();
  // @Output() public isEmailAlertSaved: EventEmitter<boolean> = new EventEmitter<any>();
  //@Output() public isAlertClosed: EventEmitter<boolean> = new EventEmitter<any>();
  public jobSearchFilter: JobSearchFilter;
  public email: string;
  public whatsappCountryCode: string;
  public whatsappNumber: string;
  private searchedLocation: string;
  private locationFilter: string;
  private industryFilter: string;
  private specialismId: string;
  private subSpecialismId: string;
  private jobSource: string;
  private saveURL: string;
  public disableEmailButton: boolean;
  public saved: boolean;
  public disableWhatsapp: boolean;
  public waCodeError: boolean;
  public whatsappNumberErrorFlag: boolean = false;
  public emailErrorFlag: boolean;
  public personalDetails: PersonalDetails;
  public showSingleAlert: boolean;
  public hideWhatsapp: boolean;
  public saveWhatsapp: boolean;
  public emailWhatsappAlertSaved: boolean;
  private alertId: string;
  public isEmailAlertSaved: boolean;
  public isLoggedin: boolean;
  public maximumAlertExceeds: boolean;
  public isEmailAlert:boolean;
  public keyWordName: string;
  public locationTitle: string;
  public locationTitleName: any;
  public isNetherlandCountry: boolean = false;
  public savedEmailAlert: boolean;
  public emailAlertType: string;
  public resubscribe_mistake_please_click: any;
  public job_alert_max_number_allowed_text1: any;
  public job_alert_max_number_allowed_text2: any;
  public job_alert_create_an_account: any;
  public job_alert_privacy_policy_text: any;
  public job_alert_privacy_policy_text1: any;
  public create_account_url: any;
  public privacy_policy_url: string;
  public displayPopUp = true;
  public industryFilterName: any;
  public locationFilterName: any;
  public emailId: string;
  public job_alert_max_number_allowed_loggedIn_text: any;
  public job_alert_loggedIn_my_account: any;
  public my_account_url: any;
  public job_alert_max_number_allowed_loggedIn_text1: any;
  public isJapanCountry: boolean;
  public isHungaryCountry: boolean;
  public isMaxAlertEmailLoggedIn: boolean;
  public job_alert_privacy_policy_text2: any;
  public isDisableEmailText: boolean;
  existingWhatsNo:any;
  public isButtonGreen: boolean = false;
  public isButtonOrange:boolean = true;
  public isButtonGreenLoggedIn: boolean = false;
  public isButtonOrangeLoggedIn: boolean = true;
  public create_job_alert_text1: any;
  constructor(private jobSearchService: JobSearchService, private requestService: RequestService, private activatedRoute: ActivatedRoute, private siteService: SiteService,
              private translate: TranslateService, private tokenStorage: TokenStorage, private dataSharingService: DataSharingService) {
    this.translate.setDefaultLang(this.siteService.getSiteLocale());
    if (this.tokenStorage.getLoggedInToken()) {
      this.isLoggedin = true;
      this.dataSharingService.sharedPersonalDetails.subscribe(personalDetails => {
        this.personalDetails = personalDetails;
        this.email = personalDetails.email;
        console.log('this.emailCont',this.email);
        this.email = this.personalDetails.email;
        console.log('this.emailCont1',this.email);
        this.isDisableEmailText=true;
        if(this.personalDetails && this.personalDetails.whatsappNumber) {
          this.showSingleAlert = true;
          this.hideWhatsapp = true;
        }
      });
    }
    this.whatsappNumber = '';
    this.searchedLocation = '';
    this.locationFilter = '';
    this.industryFilter = '';
    this.specialismId = '';
    this.subSpecialismId = '';
    this.email = '';
    this.whatsappCountryCode = '';
    this.isNetherlandCountry = this.siteService.getDomain()=='NL' ? true : false;
    this.isJapanCountry = this.siteService.getSiteLocale()=='jp_JP' ? true : false;
    this.isHungaryCountry = this.siteService.getSiteLocale()=='hu_HU' ? true : false;
    this.translate.getTranslation(this.siteService.getSiteLocale()).subscribe(response => {
      this.job_alert_max_number_allowed_text1 = response.job_alert_max_number_allowed_text1;
      this.job_alert_max_number_allowed_text2 = response.job_alert_max_number_allowed_text2;
      this.job_alert_create_an_account = response.job_alert_create_an_account;
      this.job_alert_privacy_policy_text = response.job_alert_privacy_policy_text;
      this.job_alert_privacy_policy_text1 = response.job_alert_privacy_policy_text1;
      this.create_account_url = response.create_account_url;
      this.privacy_policy_url = response.privacy_policy_url;
      this.job_alert_max_number_allowed_loggedIn_text = response.job_alert_max_number_allowed_loggedIn_text;
      this.job_alert_loggedIn_my_account = response.job_alert_loggedIn_my_account;
      this.my_account_url = response.my_account_url;
      this.job_alert_max_number_allowed_loggedIn_text1 = response.job_alert_max_number_allowed_loggedIn_text1;
      this.job_alert_privacy_policy_text2 = response.job_alert_privacy_policy_text2;
      this.create_job_alert_text1 = response.create_job_alert_text1;
    })
  }

  ngOnInit() {
    if (this.tokenStorage.getLoggedInToken()) {
      this.isLoggedin = true;
      this.disableEmailButton=true;
      this.dataSharingService.sharedPersonalDetails.subscribe(personalDetails => {
        this.personalDetails = personalDetails;
        this.email = personalDetails.email;
        this.whatsappNumber = personalDetails.whatsappNumber;
        this.existingWhatsNo= this.personalDetails.whatsappNumber;
        console.log('this.email',this.email);
        console.log('this.whatsappNumber',this.whatsappNumber);
        this.whatsappNumber = this.personalDetails.whatsappNumber;
        this.email = this.personalDetails.email;
        this.whatsappCountryCode = personalDetails.whatsappCountryCode;
        this.whatsappCountryCode = this.personalDetails.whatsappCountryCode;
        console.log('this.whatsappCountryCode',this.whatsappCountryCode);
        if(this.personalDetails && this.personalDetails.whatsappNumber) {
          this.showSingleAlert = true;
          this.hideWhatsapp = true;
        }
      });
    }
    this.activatedRoute.queryParamMap.subscribe(param => {
      this.keyWordName=param.get('q');
      this.locationTitle=param.get('location');
      this.locationTitleName = this.locationTitle.split(',')[0];
      //console.log('param.location','-'+this.locationTitleName)[0];
      if (param.get('location')) {
        this.searchedLocation = param.get('location');
      }
      if (param.get('locationf')) {
        this.locationFilter = param.get('locationf');
        this.locationFilterName=this.locationFilter;
      }
      if (param.get('industryf')) {
        this.industryFilter = param.get('industryf');
        this.industryFilterName=this.industryFilter;
        // console.log('this.industryFilter',this.industryFilterName);
      }
      if (param.get('specialismId')) {
        this.specialismId = param.get('specialismId');
      }
      if (param.get('subSpecialismId')) {
        this.subSpecialismId = param.get('subSpecialismId');
      }
      if (param.get('jobSource')) {
        this.jobSource = param.get('jobSource');
      }
      if (param.get('jobsource')) {
        this.jobSource = param.get('jobsource');
      }
      if (param.get('source')) {
        this.jobSource = param.get('source');
      }
      if (param.get('alertid')) {
        this.alertId = param.get('alertid');
      }
      if (param.get('q')) {
        let defaultJobSource='';
        this.dataSharingService.sharedClientConfig.subscribe(res => defaultJobSource = res?.jobSource );
        this.jobSource = param.get('jobSource')?param.get('jobSource'):param.get('jobsource')?param.get('jobsource'):param.get('source')?param.get('source'):defaultJobSource;
        this.saved = false;
        this.saveWhatsapp = false;
        this.disableEmailButton = false;
        this.disableWhatsapp = false;
      }
      this.saveURL = 'specialismId=' + this.specialismId + '&' + 'subSpecialismId=' + this.subSpecialismId;
    });

    this.jobSearchService.sharedJobSearchFilterData.subscribe(searchFilter => {
      this.jobSearchFilter = searchFilter;
      console.log('this.SearchFilter',this.jobSearchFilter);
    });
    this.jobSearchService.sharedConfigList.subscribe(searchFilter => {
      this.extraConfig = searchFilter;
      if (this.extraConfig) {
        if(!this.isLoggedin) {
          this.whatsappCountryCode = this.extraConfig.wspCountryCode;
        }
        this.isEmailAlert=this.extraConfig.isEmailAlert;
      }
    });

  }
  saveNewJobAlert() {
    var type='';
    this.emailAlertType=this.email;
    if(this.emailAlertType=="" && this.whatsappNumber=="")
    {
      this.emailErrorFlag = true;
      this.whatsappNumberErrorFlag = true;
      return false;
    }
    else{
      // this.emailErrorFlag = false;
      // this.whatsappNumberErrorFlag = false;
      if(this.emailAlertType)
      {
        type='email';
        this.saveJobAlert(type);
      }
      if(this.whatsappCountryCode && this.whatsappNumber)
      {
        type='whatsapp'
        this.saveJobAlert(type);
      }
    }
  }
  saveJobAlert(type: string) {
    // this.whatsappNumberErrorFlag = false;
    if (this.personalDetails) {
      if (type === 'email') {
        this.email = this.personalDetails.email;
        this.whatsappCountryCode = '';
      }
    }

    if (type === 'whatsapp') {
      if (!this.whatsappCountryCode || !this.whatsappNumber || this.waCodeError || this.whatsappNumberErrorFlag) {
        this.whatsappNumberErrorFlag = true;
        this.emailErrorFlag = false;
        return false;
      } else {
        this.disableWhatsapp = true;
      }
    } else {
      if (!this.email.trim() || this.emailErrorFlag) {
        this.emailErrorFlag = true;
        return false;
      } else {
        this.disableEmailButton = true;
      }
    }
    if (this.tokenStorage.getLoggedInToken() && type === 'whatsapp') {
      let saveWhatsappNumber = new SaveWhatsappRequest(this.whatsappNumber, this.whatsappCountryCode, '');
      this.requestService.postRequestGateWay(config.theme, apiEndPoints.gateWayAPI, apiEndPoints.saveWhatsapp, saveWhatsappNumber).subscribe(res => {
        // console.log("res save WhatsappNumber ..", res);
        if (res.status === config.SUCCESS) {
          this.isButtonOrange=false;
          this.isButtonGreen=true;
          console.log('orangeNLWA',this.isButtonOrange);
          console.log('GreenNLWA',this.isButtonGreen);
          // this.closeModal();
          //this.updateWhatsAppnumber();
          this.email = '';
          //this.saved = true;
          this.saveWhatsapp = true;
          this.isButtonOrange=false;
          this.isButtonGreen=true;
          this.whatsappNumber = '';
          this.whatsappCountryCode = '';
          if (this.saved && this.saveWhatsapp) {
            this.emailWhatsappAlertSaved = true;
          }else{
            this.hideWhatsapp = true;
            this.showSingleAlert=true;
          }
        }

      }, error => {

      });

    } else {
      let saveAlertJson = new JobAlertRequest(this.jobSearchFilter ? this.jobSearchFilter.maxSalValue : '', this.keyWordName, this.jobSource,
        this.jobSearchFilter ? this.jobSearchFilter.flexibleworking : '', this.whatsappNumber,
        (type === 'whatsapp' && this.extraConfig.wspCountryCode && !this.whatsappCountryCode) ? this.extraConfig.wspCountryCode : this.whatsappCountryCode, this.email,
        this.specialismId, this.subSpecialismId, this.jobSearchFilter ? this.jobSearchFilter.fullTime : '', this.jobSearchFilter ? this.jobSearchFilter.partTime : '',
        encodeURIComponent(this.saveURL), this.jobSearchFilter ? this.jobSearchFilter.minSalValue : '', this.jobSearchFilter ? this.jobSearchFilter.jobType : '',
        this.searchedLocation, this.locationFilter, this.industryFilter,
        (this.jobSearchFilter && this.jobSearchFilter.maxSalValue) ? 'N' : '', this.alertId ? this.alertId : '',this.jobSearchFilter ? this.jobSearchFilter.payTypeValue : '');
      let endPoint = '';
      if (this.alertId) {
        endPoint = apiEndPoints.editAlert;
      } else {
        endPoint = apiEndPoints.saveAlert;
      }

      this.requestService.postRequestGateWay(config.theme, apiEndPoints.gateWayAPI, endPoint, saveAlertJson).subscribe(res => {
        if (res.status === config.SUCCESS) {
          // this.closeModal();
          this.disableEmailButton = true;
          this.disableWhatsapp = true;
          this.isButtonOrange=false;
          this.isButtonGreen=true;
          console.log('orangeNL',this.isButtonOrange);
          console.log('GreenNL',this.isButtonGreen);
          if (type === 'email') {
            this.email = '';
            this.saved = true;
            if (this.tokenStorage.getLoggedInToken() && this.showSingleAlert ) {
              if (res.data.statuscode === 'WC023'){
                this.maximumAlertExceeds = true;
                this.emailWhatsappAlertSaved = true;
              }else
                this.emailWhatsappAlertSaved = true;
            }else if(this.tokenStorage.getLoggedInToken() && res.data.statuscode === 'WC023'){
              this.maximumAlertExceeds = true;
            }
          } else {
            this.saveWhatsapp = true;
            this.whatsappNumber = '';
            this.whatsappCountryCode = '';
          }
          if (this.saved && this.saveWhatsapp) {
            this.emailWhatsappAlertSaved = true;
          }

        }

      }, error => {
        this.disableEmailButton = false;
        this.disableWhatsapp = true;
      });
    }
  }

  validateInput(email: string, whatsappCountryCode: string, whatsappNumber: string, event) {
    let regexWACode = /^([+]{1})[0-9][0-9]*$/;
    if (event) {
      if ((event.which != 8 && event.which != 0 && event.which < 48) || (event.which > 57 && event.which < 96) || event.which > 105) {
        event.preventDefault();
      }
    }
    if (whatsappCountryCode && whatsappCountryCode.trim()) {
      if (!regexWACode.test(whatsappCountryCode)) {
        this.waCodeError = true;
        return false;
      } else {
        this.waCodeError = false;
      }

    }
    if (email && email.trim()) {
      let emailRegex = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
      if (!emailRegex.test(email)) {
        this.emailErrorFlag = true;
        this.whatsappNumberErrorFlag = false;
        return false;
      } else {
        this.emailErrorFlag = false;
      }
    }
    if(whatsappNumber){
      let phoneRegex = /^\d{8,}$/;
      if(phoneRegex.test(whatsappNumber)) {
        this.whatsappNumberErrorFlag = false;
        this.emailErrorFlag = false;
        this.disableWhatsapp = false;
      }else {
        this.whatsappNumberErrorFlag = true;
        // this.disableWhatsapp = true;
      }
    }
  }


  getSavedEmailAlertValue($event: boolean) {
    this.isEmailAlertSaved = $event;
  }

  isAlertClosed($event: boolean) {
    if($event)
      this.keyWord='';
  }

  isMaxAlert($event: boolean) {
    this.maximumAlertExceeds=$event;
  }

  validateEmail(email) {
    if (email && email.trim()) {
      let emailRegex = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
      if (!emailRegex.test(email)) {
        this.emailErrorFlag = true;
        return false;
      } else {
        this.emailErrorFlag = false;
      }
    }
  }

  saveJobAlertOtherCountry() {
    // console.log("email address..",this.personalDetails);
    if (this.personalDetails && this.personalDetails.email) {
      this.email = this.personalDetails.email;
    }
    if (!this.email) {
      this.emailErrorFlag = true;
    }
    if (this.email && !this.email.trim() || this.emailErrorFlag) {
      //console.log("inside if of check email");
      this.emailErrorFlag = true;
      return false;
    } else {
      //console.log("inside else of check email");
      let searchedLocation = '';
      let locationFilter = '';
      let industryFilter = '';
      let specialismId = '';
      let subSpecialismId = '';
      let jobSource = '';
      let alertId = '';
      let saveURL = '';
      this.disableEmailButton = true;
      let defaultJobSource='';
      this.dataSharingService.sharedClientConfig.subscribe(res => defaultJobSource = res?.jobSource );
      this.activatedRoute.queryParamMap.subscribe(param => {
        if (param.get('location')) {
          searchedLocation = param.get('location');
        }
        if (param.get('locationf')) {
          locationFilter = param.get('locationf');
        }
        if (param.get('industryf')) {
          industryFilter = param.get('industryf');
        }
        if (param.get('specialismId')) {
          specialismId = param.get('specialismId');
        }
        if (param.get('subSpecialismId')) {
          subSpecialismId = param.get('subSpecialismId');
        }
        jobSource=param.get('jobSource')?param.get('jobSource'):param.get('jobsource')?param.get('jobsource'):param.get('source')?param.get('source'):defaultJobSource;
        if (param.get('alertid')) {
          alertId = param.get('alertid');
        }
        if (param.get('q')) {
          this.savedEmailAlert = false;
        }
        saveURL = 'specialismId=' + specialismId + '&' + 'subSpecialismId=' + subSpecialismId;
      });
      let saveAlertJson = new JobAlertRequest(this.jobSearchFilter ? this.jobSearchFilter.maxSalValue : '', this.keyWordName, jobSource,
        this.jobSearchFilter ? this.jobSearchFilter.flexibleworking : '', '',
        '', this.email,
        specialismId, subSpecialismId, this.jobSearchFilter ? this.jobSearchFilter.fullTime : '',
        this.jobSearchFilter ? this.jobSearchFilter.partTime : '',
        encodeURIComponent(saveURL),
        this.jobSearchFilter ? this.jobSearchFilter.minSalValue : '', this.jobSearchFilter ? this.jobSearchFilter.jobType : '',
        searchedLocation, locationFilter, industryFilter,
        (this.jobSearchFilter && this.jobSearchFilter.maxSalValue) ? 'N' : '', alertId ? alertId : '',this.jobSearchFilter.payTypeValue);
      let endPoint = '';
      if (alertId) {
        endPoint = apiEndPoints.editAlert;
      } else {
        endPoint = apiEndPoints.saveAlert;
      }

      this.requestService.postRequestGateWay(config.theme, apiEndPoints.gateWayAPI, endPoint, saveAlertJson).subscribe(res => {
        if (res && (res.status === config.SUCCESS)) {
          this.isButtonOrange=false;
          console.log('this.isButtonOrange',this.isButtonOrange);
          this.isButtonGreen=true;
          console.log('this.isButtonGreen',this.isButtonGreen);
          if (res.data.statuscode === 'WC023') {
            this.maximumAlertExceeds = true;
            this.ismaximumAlertExceeds.emit(this.maximumAlertExceeds);
            this.email = '';
          }else {
            this.maximumAlertExceeds=false;
            this.email = '';
            this.savedEmailAlert = true;
            //this.isEmailAlertSaved.emit(this.savedEmailAlert);
            this.ismaximumAlertExceeds.emit(this.maximumAlertExceeds);
          }
        }

      }, error => {
        this.disableEmailButton = false;
      });
    }
  }

  closeAlert() {
    this.keyWord = '';
    //this.isAlertClosed.emit(true);
  }
  closeModal(){
    this.displayPopUp = false;
    $('.cdk-overlay-backdrop').removeClass('cdk-overlay-dark-backdrop cdk-overlay-backdrop-showing')
    // window.location.reload();
  }

  closeModalLoggedin(){
    this.displayPopUp = false;
    $('.cdk-overlay-backdrop').removeClass('cdk-overlay-dark-backdrop cdk-overlay-backdrop-showing')
    //window.location.reload();
  }

  /*loggedIn save alert method mix*/
  saveNewJobAlertloggedIn() {
    var type='';
    this.emailAlertType=this.email;
    if(this.emailAlertType=="" && this.whatsappNumber=="")
    {
      this.emailErrorFlag = true;
      this.whatsappNumberErrorFlag = true;
      return false;
    }
    else{
      this.emailErrorFlag = false;
      this.whatsappNumberErrorFlag = false;
      // if(this.emailAlertType)
      // {
      //   type='email';
      //   this.saveJobAlertLoggedIn(type);
      // }
      // if(this.whatsappCountryCode && this.whatsappNumber)
      // {
      //   type='whatsapp'
      //   this.saveJobAlertLoggedIn(type);
      // }
      this.saveJobNLAlertLoggedIn()
    }
  }

  /*loggedIn save alert method*/
  saveJobAlertLoggedIn(type: string) {
    // this.whatsappNumberErrorFlag = false;
    if (this.personalDetails) {
      if (type === 'email') {
        this.email = this.personalDetails.email;
        // this.whatsappCountryCode = '';
      }
    }

    if (type === 'whatsapp') {
      if (!this.whatsappCountryCode || !this.whatsappNumber || this.waCodeError || this.whatsappNumberErrorFlag) {
        this.whatsappNumberErrorFlag = true;
        return false;
      } else {
        this.disableWhatsapp = true;
      }
    } else {
      if (!this.email.trim() || this.emailErrorFlag) {
        this.emailErrorFlag = true;
        return false;
      } else {
        this.disableEmailButton = true;
      }
    }

    if (this.tokenStorage.getLoggedInToken() && type === 'whatsapp' && !this.isMaxAlertEmailLoggedIn) {
      let saveWhatsappNumber = new SaveWhatsappRequest(this.whatsappNumber, this.whatsappCountryCode, '');
      this.requestService.postRequestGateWay(config.theme, apiEndPoints.gateWayAPI, apiEndPoints.saveWhatsapp, saveWhatsappNumber).subscribe(res => {
       
        if (res.status === config.SUCCESS) {
          this.isButtonOrangeLoggedIn=false;
          this.isButtonGreenLoggedIn=true;
          this.disableEmailButton = true;
          this.disableWhatsapp = true;
          console.log('disableEmailButton',this.disableEmailButton);
          console.log('disableWhatsapp',this.disableWhatsapp);
          this.updateWhatsAppnumber();
          const isMaxAlertEmailLoggedIn=localStorage.getItem("isMaxAlertEmailLoggedIn");

          if(isMaxAlertEmailLoggedIn=="Y"){
            console.log("isMaxAlertEmailLoggedIn" + isMaxAlertEmailLoggedIn);
          }
          // this.closeModalLoggedin();
          //  this.email = '';
          //this.saved = true;
          this.saveWhatsapp = true;
          //  this.whatsappNumber = '';
          //  this.whatsappCountryCode = '';
          if (this.saved && this.saveWhatsapp) {
            this.emailWhatsappAlertSaved = true;
          }else{
            this.hideWhatsapp = true;
            this.showSingleAlert=true;
          }
        }

      }, error => {

      });

    } else {
      let saveAlertJson = new JobAlertRequest(this.jobSearchFilter ? this.jobSearchFilter.maxSalValue : '', this.keyWordName, this.jobSource,
        this.jobSearchFilter ? this.jobSearchFilter.flexibleworking : '', this.whatsappNumber,
        (type === 'whatsapp' && this.extraConfig.wspCountryCode && !this.whatsappCountryCode) ? this.extraConfig.wspCountryCode : this.whatsappCountryCode, this.email,
        this.specialismId, this.subSpecialismId, this.jobSearchFilter ? this.jobSearchFilter.fullTime : '', this.jobSearchFilter ? this.jobSearchFilter.partTime : '',
        encodeURIComponent(this.saveURL), this.jobSearchFilter ? this.jobSearchFilter.minSalValue : '', this.jobSearchFilter ? this.jobSearchFilter.jobType : '',
        this.searchedLocation, this.locationFilter, this.industryFilter,
        (this.jobSearchFilter && this.jobSearchFilter.maxSalValue) ? 'N' : '', this.alertId ? this.alertId : '',this.jobSearchFilter.payTypeValue);
      let endPoint = '';
      if (this.alertId) {
        endPoint = apiEndPoints.editAlert;
      } else {
        endPoint = apiEndPoints.saveAlert;
      }

      this.requestService.postRequestGateWay(config.theme, apiEndPoints.gateWayAPI, endPoint, saveAlertJson).subscribe(res => {
        if (res.status === config.SUCCESS) {
          this.isButtonOrangeLoggedIn=false;
          this.isButtonGreenLoggedIn=true;
          console.log('orangeNLLogIn',this.isButtonOrangeLoggedIn);
          console.log('GreenNLLogIn',this.isButtonGreenLoggedIn);
          this.disableEmailButton = true;
          this.disableWhatsapp = true;
          console.log('disableEmailButton',this.disableEmailButton);
          console.log('disableWhatsapp',this.disableWhatsapp);
          this.personalDetailsResponse();
          if (type === 'email') {
            //  this.email = '';
            this.saved = true;
            if (this.tokenStorage.getLoggedInToken() && this.showSingleAlert ) {
              if (res.data.statuscode === 'WC023'){
                //  this.maximumAlertExceeds = true;
                this.isMaxAlertEmailLoggedIn = true;
                this.emailWhatsappAlertSaved = true;
                this.isButtonOrangeLoggedIn=true;
                this.isButtonGreenLoggedIn=false;
                const existingWhatsNo=localStorage.getItem("existingWhatsNo");
                console.log("existingWhatsNo" + existingWhatsNo);    
                setTimeout(() => {
                  this.updateWhatsAppExistingnumber(existingWhatsNo);
                }, 200);            
                

              }else
                this.emailWhatsappAlertSaved = true;
            }else if(this.tokenStorage.getLoggedInToken() && res.data.statuscode === 'WC023'){
              // this.maximumAlertExceeds = true;
              this.isMaxAlertEmailLoggedIn = true;
              const existingWhatsNo=localStorage.getItem("existingWhatsNo");
              localStorage.setItem("isMaxAlertEmailLoggedIn","Y");
              console.log("existingWhatsNo" + existingWhatsNo);                
              this.updateWhatsAppExistingnumber(existingWhatsNo)
            }
          } else {
            // this.updateWhatsAppnumber();
            this.saveWhatsapp = true;
            //  this.whatsappNumber = '';
            // this.whatsappCountryCode = '';
          }
          if (this.saved && this.saveWhatsapp) {
            this.emailWhatsappAlertSaved = true;
          }

        }

      }, error => {
        this.disableEmailButton = false;
        this.disableWhatsapp = true;
      });
    }
  }


  saveJobAlertLoggedInOtherCountry() {
    // console.log("email address..",this.personalDetails);
    if (this.personalDetails && this.personalDetails.email) {
      this.email = this.personalDetails.email;
    }
    if (!this.email) {
      this.emailErrorFlag = true;
    }
    if (this.email && !this.email.trim() || this.emailErrorFlag) {
      //console.log("inside if of check email");
      this.emailErrorFlag = true;
      return false;
    } else {
      //console.log("inside else of check email");
      let searchedLocation = '';
      let locationFilter = '';
      let industryFilter = '';
      let specialismId = '';
      let subSpecialismId = '';
      let jobSource = '';
      let alertId = '';
      let saveURL = '';
      this.disableEmailButton = true;
      let defaultJobSource='';
      this.dataSharingService.sharedClientConfig.subscribe(res => defaultJobSource = res?.jobSource );
      this.activatedRoute.queryParamMap.subscribe(param => {
        if (param.get('location')) {
          searchedLocation = param.get('location');
        }
        if (param.get('locationf')) {
          locationFilter = param.get('locationf');
        }
        if (param.get('industryf')) {
          industryFilter = param.get('industryf');
        }
        if (param.get('specialismId')) {
          specialismId = param.get('specialismId');
        }
        if (param.get('subSpecialismId')) {
          subSpecialismId = param.get('subSpecialismId');
        }
        jobSource=param.get('jobSource')?param.get('jobSource'):param.get('jobsource')?param.get('jobsource'):param.get('source')?param.get('source'):defaultJobSource;
        if (param.get('alertid')) {
          alertId = param.get('alertid');
        }
        if (param.get('q')) {
          this.savedEmailAlert = false;
        }
        saveURL = 'specialismId=' + specialismId + '&' + 'subSpecialismId=' + subSpecialismId;
      });
      let saveAlertJson = new JobAlertRequest(this.jobSearchFilter ? this.jobSearchFilter.maxSalValue : '', this.keyWordName, jobSource,
        this.jobSearchFilter ? this.jobSearchFilter.flexibleworking : '', '',
        '', this.email,
        specialismId, subSpecialismId, this.jobSearchFilter ? this.jobSearchFilter.fullTime : '',
        this.jobSearchFilter ? this.jobSearchFilter.partTime : '',
        encodeURIComponent(saveURL),
        this.jobSearchFilter ? this.jobSearchFilter.minSalValue : '', this.jobSearchFilter ? this.jobSearchFilter.jobType : '',
        searchedLocation, locationFilter, industryFilter,
        (this.jobSearchFilter && this.jobSearchFilter.maxSalValue) ? 'N' : '', alertId ? alertId : '',this.jobSearchFilter.payTypeValue);
      let endPoint = '';
      if (alertId) {
        endPoint = apiEndPoints.editAlert;
      } else {
        endPoint = apiEndPoints.saveAlert;
      }

      this.requestService.postRequestGateWay(config.theme, apiEndPoints.gateWayAPI, endPoint, saveAlertJson).subscribe(res => {
        if (res && (res.status === config.SUCCESS)) {
          this.isButtonOrangeLoggedIn=false;
          this.isButtonGreenLoggedIn=true;
          if (res.data.statuscode === 'WC023') {
            this.isMaxAlertEmailLoggedIn = true;
            this.isButtonOrangeLoggedIn=true;
            this.isButtonGreenLoggedIn=false;
            // this.ismaximumAlertExceeds.emit(this.maximumAlertExceeds);
            // this.email = '';
          }else {
            // this.maximumAlertExceeds=;
            this.isMaxAlertEmailLoggedIn = false;
            //this.email = '';
            this.savedEmailAlert = true;
            //  this.isEmailAlertSaved.emit(this.savedEmailAlert);
            //  this.ismaximumAlertExceeds.emit(this.maximumAlertExceeds);
          }
        }

      }, error => {
        this.disableEmailButton = false;
      });
    }
  }

  updateWhatsAppnumber(){
    console.log('max alerts ',this.isMaxAlertEmailLoggedIn);
    localStorage.setItem('existingWhatsNo',this.existingWhatsNo);
    let json=new UpdateWhatsappRequest('0',this.existingWhatsNo,this.whatsappNumber,null,true,this.whatsappCountryCode,this.whatsappCountryCode);
    this.requestService.postRequestGateWay(config.theme,apiEndPoints.gateWayAPI,apiEndPoints.updateWhatsapp,json)
      .subscribe({next: (res) => {
          if (res.status === config.SUCCESS && res.data) {
            //console.log('whatsapp number updated');

          }
        }, error: () => {
          // this._router.navigate([this.errorRoute],{queryParams:{submit:'N',error: error.status}});
        }
      });

  }

  updateWhatsAppExistingnumber(existingWhatsNo){    
    let json=new UpdateWhatsappRequest('0',existingWhatsNo,existingWhatsNo,null,true,this.whatsappCountryCode,this.whatsappCountryCode);
    this.requestService.postRequestGateWay(config.theme,apiEndPoints.gateWayAPI,apiEndPoints.updateWhatsapp,json)
      .subscribe({next: (res) => {
          if (res.status === config.SUCCESS && res.data) {
            //console.log('whatsapp number updated');

          }
        }, error: () => {
          // this._router.navigate([this.errorRoute],{queryParams:{submit:'N',error: error.status}});
        }
      });

  }

  public personalDetailsResponse(){
    const personalDetailsResponseRequestServiceGetRequestSubscription = this.requestService.getRequest(config.theme,apiEndPoints.gateWayAPI,apiEndPoints.getpersonaldetails,null,null).subscribe(
      (res)=>{
        if(res.data && res.status==config.SUCCESS)
          this.personalDetails=res.data;
        this.dataSharingService.setPersonalDetails(this.personalDetails);

      },(error)=>{
      });

  }

  /**
   * NL Saved whats alert
   */

  /*loggedIn save alert method*/
  saveJobNLAlertLoggedIn() {
    // this.whatsappNumberErrorFlag = false;
    if (this.personalDetails) {
      this.email = this.personalDetails.email;
      // if (type === 'email') {
      //   this.email = this.personalDetails.email;
      //   // this.whatsappCountryCode = '';
      // }
    }

    // if (type === 'whatsapp') {
      // if (!this.whatsappCountryCode || !this.whatsappNumber || this.waCodeError || this.whatsappNumberErrorFlag) {
      //   this.whatsappNumberErrorFlag = true;
      //   return false;
      // } else {
      //   this.disableWhatsapp = true;
      // }
      if (!this.email.trim() || this.emailErrorFlag) {
            this.emailErrorFlag = true;
            return false;
          } else {
            this.disableEmailButton = true;
          }

    // } else {
    //   if (!this.email.trim() || this.emailErrorFlag) {
    //     this.emailErrorFlag = true;
    //     return false;
    //   } else {
    //     this.disableEmailButton = true;
    //   }
    // }

    
      let saveAlertJson = new JobAlertRequest(this.jobSearchFilter ? this.jobSearchFilter.maxSalValue : '', this.keyWordName, this.jobSource,
        this.jobSearchFilter ? this.jobSearchFilter.flexibleworking : '', this.whatsappNumber,
        (this.extraConfig.wspCountryCode && !this.whatsappCountryCode) ? this.extraConfig.wspCountryCode : this.whatsappCountryCode, this.email,
        this.specialismId, this.subSpecialismId, this.jobSearchFilter ? this.jobSearchFilter.fullTime : '', this.jobSearchFilter ? this.jobSearchFilter.partTime : '',
        encodeURIComponent(this.saveURL), this.jobSearchFilter ? this.jobSearchFilter.minSalValue : '', this.jobSearchFilter ? this.jobSearchFilter.jobType : '',
        this.searchedLocation, this.locationFilter, this.industryFilter,
        (this.jobSearchFilter && this.jobSearchFilter.maxSalValue) ? 'N' : '', this.alertId ? this.alertId : '',this.jobSearchFilter.payTypeValue);
      let endPoint = '';
      if (this.alertId) {
        endPoint = apiEndPoints.editAlert;
      } else {
        endPoint = apiEndPoints.saveAlert;
      }

      this.requestService.postRequestGateWay(config.theme, apiEndPoints.gateWayAPI, endPoint, saveAlertJson).subscribe(res => {
        if (res.status === config.SUCCESS) {
          this.isButtonOrangeLoggedIn=false;
          this.isButtonGreenLoggedIn=true;
          this.disableEmailButton = true;
          this.disableWhatsapp = true;
          console.log('disableEmailButton',this.disableEmailButton);
          console.log('disableWhatsapp',this.disableWhatsapp);
          console.log('orangeNLLogIn',this.isButtonOrangeLoggedIn);
          console.log('GreenNLLogIn',this.isButtonGreenLoggedIn);
          this.personalDetailsResponse();
         
            this.saved = true;
            if (this.tokenStorage.getLoggedInToken() && this.showSingleAlert ) {
              if (res.data.statuscode === 'WC023'){
                //  this.maximumAlertExceeds = true;
                this.isMaxAlertEmailLoggedIn = true;
                this.emailWhatsappAlertSaved = true;
                this.isButtonOrangeLoggedIn=true;
                this.isButtonGreenLoggedIn=false;
              }else{
                this.emailWhatsappAlertSaved = true;
                if ((this.tokenStorage.getLoggedInToken()) && !this.whatsappNumber) {
                  let saveWhatsappNumber = new SaveWhatsappRequest(this.whatsappNumber, this.whatsappCountryCode, '');
                  this.requestService.postRequestGateWay(config.theme, apiEndPoints.gateWayAPI, apiEndPoints.saveWhatsapp, saveWhatsappNumber).subscribe(res => {
                   
                    if (res.status === config.SUCCESS) {
                      this.isButtonOrangeLoggedIn=false;
                      this.isButtonGreenLoggedIn=true;
                      this.updateWhatsAppnumber();
                      this.saveWhatsapp = true;
                      if (this.saved && this.saveWhatsapp) {
                        this.emailWhatsappAlertSaved = true;
                      }else{
                        this.hideWhatsapp = true;
                        this.showSingleAlert=true;
                      }
                    }
            
                  }, error => {
            
                  });
            
                }
              }
            }else if(this.tokenStorage.getLoggedInToken() && res.data.statuscode === 'WC023'){
              // this.maximumAlertExceeds = true;
              this.isMaxAlertEmailLoggedIn = true;              
            }else{
              this.saveWhatsapp = true;
            

            }
          
          if (this.saved && this.saveWhatsapp) {
            this.emailWhatsappAlertSaved = true;
          }

        }

      }, error => {
        this.disableEmailButton = false;
        this.disableWhatsapp = true;
      });
  }

}
