export const baseUrl = {
  apiBaseUrl:'https://moatapi.hays.com.au/jobportalapi',
  apiBaseUrlWrapper: 'https://moatapi.hays.com.au/jobportalwr'
};
export const en_JP = {
  config:{
    countryCode:', Japan',
    country_name: 'Japan',
    gtm_code:"GTM-MMBKTV",
    view_google_link:'http://maps.google.co.jp/maps?q=',
    zopim_enabled:false,
    zopim_enabled_device:'-Desktop',
    zopim_key_withURL:"//v2.zopim.com/?2SfCzrC8fQC6u5Mn6KEr6KMcqQoU4nJL",
    maximumSize:2097152,
    appleItunesMetaEnabled: true,
    azure_enable: true,
    auzureLoginURL : 'https://testsg.hays.co.jp/portal/auth?application=jobportal&client={orgName}&entityType=candidate&domain=jp&locale=en&hostname=testsg&action=&workFlow=',
    dynamicPageTitleMetaTags: true,
    canonicalSearchLinkCompleteURL: false,
    indeedAPIURL:'https://cprofile1test.hays.com.au/portalservice/resources/registration/applyindeed/',
    addAutoBlockAssetScriptEnabled: true,
    addAutoBlockOptOutScriptEnabled: true,
    isCaptchaValueEnabled: false,
    isResubscibeEnabled: false,
    searchURL:'/en/job-search',
    errorPageCareerAdvice:'/en/advice',
    errorPageRecruitNow:'/en/employers',
    errorPageRecruitment:'/en/employer-services/contract-employment',
    errorPageMarketInsights:'/en/news'
  },
  liferaybaseUrl: 'https://testsg.hays.co.jp',
  liferayUrl: 'https://testsg.hays.co.jp/en',
  cookieDomain:'.hays.co.jp',
  cookieBannerDomain:'.hays.co.jp',
  siteBaseURL: 'https://testsg.hays.co.jp',
  gateWayAPI:{
    location: baseUrl.apiBaseUrl+'/jp/en/jobportal/s/candidate/submit/location',
    isCaptchaDisabled : baseUrl.apiBaseUrl+'/int/s/jp/en/{orgname}/partner/browse/v1/captchaenabled',
    locationOnRecruitingNow:baseUrl.apiBaseUrl+'/int/s/jp/en/{orgname}/recruiting/browse/v1/location?type=recruiting-now-locations',
    employeeType:baseUrl.apiBaseUrl+'/int/s/jp/en/{orgname}/recruiting/browse/v1/employment?type=recruiting-now-employment',
    submitRecruitingForm:baseUrl.apiBaseUrl+'/int/s/jp/en/{orgname}/content/submit/v1/recruitingnow',
    getSector: baseUrl.apiBaseUrl+'/int/s/jp/en/{orgname}/content/browse/v1/specilisms', // post call for getting list of sectors
    getLocationHierarchy :  baseUrl.apiBaseUrl+'/int/s/jp/en/{orgname}/content/browse/v1/hierarchy',
    getStateList : baseUrl.apiBaseUrl+'/int/s/jp/en/{orgname}/content/browse/v1/states',
    getOfficeForSpecialism: baseUrl.apiBaseUrl+'/int/s/jp/en/{orgname}/content/browse/v1/offices',
    getSubSpecialism: baseUrl.apiBaseUrl+'/int/s/jp/en/{orgname}/content/browse/v1/subspecialism ',
    getAllOffice: baseUrl.apiBaseUrl+'/int/s/jp/en/{orgname}/content/browse/v1/alloffices',
    getOfficeResultForAPAC: baseUrl.apiBaseUrl+'/int/s/jp/en/{orgname}/content/browse/v1/officeresultapac',
    getLatLongOfficeResult: baseUrl.apiBaseUrl+'/int/s/jp/en/{orgname}/content/browse/v1/officeresultlatlong',
    isAutoSugesstionEnabled: baseUrl.apiBaseUrl+'/int/s/jp/en/{orgname}/google/browse/v1/isautosugesstionenabled',
    getTermsAndConditions: baseUrl.apiBaseUrl + '/int/s/jp/en/{orgname}/recruiting/browse/v1/consentcheckbox?type=consent_checkboxnew',
    getGDriveAndDropbox: baseUrl.apiBaseUrl + '/int/s/jp/en/{orgname}/partner/browse/v1/isdropboxgdrivedisabled',
    getIsKanaEnabled: baseUrl.apiBaseUrl + '/int/s/jp/en/{orgname}/partner/browse/v1/iskanaenabled',
    register: baseUrl.apiBaseUrlWrapper +'/int/s/jp/en/{orgname}/candidate/submit/v1/register',
    pagemanagement:baseUrl.apiBaseUrl+'/int/s/jp/en/{orgname}/pagemanagement/browse/v1/pagemenu',
    countryLanguage:baseUrl.apiBaseUrl+'/int/s/jp/en/{orgname}/site/browse/v1/getlanguages?type=change_language',
    getBrowseByExpertise:baseUrl.apiBaseUrl+'/int/s/jp/en/{orgname}/content/browse/v1/browsebyexpertise',
    showOfficeEmail:baseUrl.apiBaseUrl+'/int/s/jp/en/{orgname}/content/browse/v1/showofficeemail',
    connectdropbox:baseUrl.apiBaseUrl+'/int/s/jp/en/{orgname}/partner/browse/v1/connectdropbox',
    connectGdrive:baseUrl.apiBaseUrl+'/int/s/jp/en/{orgname}/partner/browse/v1/connectgdrive',
    getDropBoxFiles:baseUrl.apiBaseUrl+'/int/s/jp/en/{orgname}/partner/browse/v1/dropboxfiles',
    getDriveFiles:baseUrl.apiBaseUrl+'/int/s/jp/en/{orgname}/partner/browse/v1/gdrivefiles',
    getFileLink:baseUrl.apiBaseUrl+'/int/s/jp/en/{orgname}/partner/browse/v1/dropboxfilelink',
    gdriveFileLink:baseUrl.apiBaseUrl+'/int/s/jp/en/{orgname}/partner/browse/v1/gdrivefilelink',
    getAutoSuggetion:baseUrl.apiBaseUrl+'/int/s/jp/en/{orgname}/job/browse/v1/autosuggestion?keyWord=',
    submitEmail:baseUrl.apiBaseUrl+'/int/s/jp/en/{orgname}/gdpr/submit/emailconfirmation',
    getgooglejobs:baseUrl.apiBaseUrl+'/int/s/jp/en/{orgname}/job/browse/v1/jobsv2',
    getgooglejobssecure:baseUrl.apiBaseUrl+'/int/s/jp/en/{orgname}/job/browse/v1/jobsv2',
    jobflowconfig:baseUrl.apiBaseUrl+'/int/s/jp/en/{orgname}/pagemanagement/browse/v1/jobflowconfig',
    getJobFilters:baseUrl.apiBaseUrl+'/int/s/jp/en/{orgname}/job/browse/v1/filters?type=job_filter',
    getCustomerServiceEmail: baseUrl.apiBaseUrl + '/int/s/jp/en/{orgname}/pagemanagement/browse/v1/customeremail',
    pagemanagementSecure:baseUrl.apiBaseUrl +'/int/s/jp/en/{orgname}/pagemanagement/browse/v1/pagemenu',
    createToken:baseUrl.apiBaseUrl+'/ext/ns/jp/en/{orgname}/usermanagement/create/v1/token',
    validatePage:baseUrl.apiBaseUrl+'/int/s/jp/en/{orgname}/usermanagement/validate/v1/page',
    submitForgetPassEmail: baseUrl.apiBaseUrl+'/int/s/jp/en/{orgname}/candidate/submit/v1/forgetpassword',
    getInterviewAndCvTips: baseUrl.apiBaseUrl+'/int/s/jp/en/{orgname}/candidate/browse/v1/interviewandcvtips',
    getRecentApplication: baseUrl.apiBaseUrl+'/int/s/jp/en/{orgname}/candidate/browse/v1/recentapplication',
    getInterviewCount: baseUrl.apiBaseUrl+'/int/s/jp/en/{orgname}/notification/browse/v1/interview',
    getUpcomingInterview: baseUrl.apiBaseUrlWrapper+'/int/s/jp/en/{orgname}/candidate/browse/v1/interviewdetails',
    getpersonaldetails: baseUrl.apiBaseUrlWrapper+'/int/s/jp/en/{orgname}/candidate/browse/v1/getpersonaldetailsupgrade',
    getGDriveFiles: baseUrl.apiBaseUrl+'/int/s/jp/en/{orgname}/partner/browse/v1/gdrivefiles',
    gcsPublish:baseUrl.apiBaseUrl+'/int/s/jp/en/{orgname}/job/submit/v1/gcsevent',
    saveAlert:baseUrl.apiBaseUrl+'/int/s/jp/en/{orgname}/job/create/v1/alert',
    saveWhatsapp:baseUrl.apiBaseUrl+'/int/s/jp/en/{orgname}/job/submit/v1/whatsapp',
    updateWhatsapp:baseUrl.apiBaseUrl+'/int/s/jp/en/{orgname}/candidate/update/v1/whatsappnumber',
    getWhatsappNumber:baseUrl.apiBaseUrl+'/int/s/jp/en/{orgname}/browse/v1/whatsappnumber',
    getWhatsAppAlerts:baseUrl.apiBaseUrl+'/int/s/jp/en/{orgname}/decrypt/v1/whatsappalert',
    getJobAlerts:baseUrl.apiBaseUrl+'/int/s/jp/en/{orgname}/job/browse/v1/alert',
    getGdprConfig:baseUrl.apiBaseUrl+'/int/s/jp/en/{orgname}/pagemanagement/browse/v1/gdprconfig',
    getMarketingPreferences:baseUrl.apiBaseUrlWrapper+'/int/s/jp/en/{orgname}/gdpr/browse/v1/marketingpreferences',
    updateMarketingPreferences:baseUrl.apiBaseUrlWrapper+'/int/s/jp/en/{orgname}/gdpr/update/v1/marketingpreferences',
    decryptIdentifier:baseUrl.apiBaseUrl+'/int/ns/jp/en/{orgname}/decrypt/browse/v1/decryptidentifier',
    saveJob: baseUrl.apiBaseUrl + '/int/s/jp/en/{orgname}/job/create/v1/savejob',
    deleteJob: baseUrl.apiBaseUrl + '/int/s/jp/en/{orgname}/job/delete/v1/deletesavedjob',
    getSavedJobs: baseUrl.apiBaseUrl + '/int/s/jp/en/{orgname}/job/browse/v1/getsavedjobs',
    resetPassword:baseUrl.apiBaseUrl+'/int/s/jp/en/{orgname}/candidate/submit/v1/resetpassword',
    deleteJobAlert:baseUrl.apiBaseUrl+'/int/s/jp/en/{orgname}/job/delete/v1/alert',
    editAlert:baseUrl.apiBaseUrl+'/int/s/jp/en/{orgname}/job/update/v1/alert',
    changeEmail:baseUrl.apiBaseUrl+'/int/s/jp/en/{orgname}/candidate/submit/v1/changeemail',
    getCvs:baseUrl.apiBaseUrlWrapper+'/int/s/jp/en/{orgname}/candidate/browse/v1/getcvs',
    downloadCV:baseUrl.apiBaseUrl+'/int/s/jp/en/{orgname}/candidate/browse/v1/downloadcv',
    deleteCv:baseUrl.apiBaseUrlWrapper+'/int/s/jp/en/{orgname}/candidate/delete/v1/deletecv',
    getSingleJob:baseUrl.apiBaseUrl+'/int/s/jp/en/{orgname}/job/browse/v1/getsavedjob',
    changePassword:baseUrl.apiBaseUrl+'/int/s/jp/en/{orgname}/candidate/submit/v1/changepassword',
    uploadcv:baseUrl.apiBaseUrlWrapper+'/int/s/jp/en/{orgname}/candidate/upload/v1/cv',
    jobApply: baseUrl.apiBaseUrlWrapper+'/int/s/jp/en/{orgname}/candidate/submit/v1/jobapply',
    unsubscribe: baseUrl.apiBaseUrl+'/int/s/jp/en/{orgname}/alert/v1/unsubscribe',
    jobAlertVerify: baseUrl.apiBaseUrl+'/int/s/jp/en/{orgname}/alert/v1/verify',
    applyLinkedin: baseUrl.apiBaseUrlWrapper+'/int/s/jp/en/{orgname}/job/v1/applylinkedin',
    getWidgetList: baseUrl.apiBaseUrl+'/int/ns/jp/en/{orgname}/content/browse/v1/widgetlist',
    getTimeSheet: baseUrl.apiBaseUrl+'/int/s/jp/en/{orgname}/auth/browse/v1/pagemenu',
    getKillerQuestions: baseUrl.apiBaseUrl+'/int/s/jp/en/{orgname}/content/browse/v1/killerquestions',
    validateAlert: baseUrl.apiBaseUrl+'/int/ns/jp/en/{orgname}/alert/v1/validatealert',
    signedURL:baseUrl.apiBaseUrl+'/int/s/jp/en/{orgname}/user/create/v1/signedurl',
    getErpMarketingPref:baseUrl.apiBaseUrlWrapper+'/int/s/jp/en/{orgname}/user/browse/v1/marketingpref',
    updateErpMarketingPref:baseUrl.apiBaseUrlWrapper+'/int/s/jp/en/{orgname}/user/save/v1/marketingpref',
    erpValidateEmail:baseUrl.apiBaseUrl+'/int/s/jp/en/{orgname}/user/send/v1/consentemail',
    getClientConfig:baseUrl.apiBaseUrl+'/int/ns/jp/en/{orgname}/client/browse/v1/clientconfig',
    deleteAllAlerts:baseUrl.apiBaseUrl+'/int/s/jp/en/{orgname}/job/delete/v1/deleteallalerts',
    viewEmailLink:baseUrl.apiBaseUrl+'/int/ns/jp/en/{orgname}/alert/v1/viewmaillink',
    getLanguageSpoken:baseUrl.apiBaseUrl+'/int/ns/jp/en/{orgname}/site/browse/v1/getlanguagespoken?type=languages-spoken',
    logoutUser:baseUrl.apiBaseUrl+'/ext/ns/jp/en/{orgname}/auth/delete/v1/session',
  },
  routing_url:{
    searchLinkTitleTerm: '-jobs',
    searchLinklocTerm: 'in-',
    searchLinkTitle: '/en/job-search/{jobTitle}-jobs',
    searchLinkLocation: '/en/job-search/in-{location}',
    searchLinkInLocation: '/en/job-search/{jobTitle}-jobs-in-{location}',
    errorPage:'/en/account/error/',
    successPage:'/en/account/cv-success/',
    successUpdateEmailPrefences:'/en/account/sent-preferences-email',
    confirmationPage: '/en/partner/ps/success',
    officeLocatorResultPage:'/en/contact/offices-near-me/result',
    officeLocatorPage:'/en/contact/offices-near-me',
    speculativeCV:'/en/account/send-cv',
    myProfile:'/en/my-account/profile',
    login:'/en/account/login',
    register: '/en/account/register',
    search:'/en/job-search',
    secureSearch:'/en/job-search',
    secureJobDetails:'/en/job-detail',
    jobdetails:'/en/job-detail',
    forgetPassword:'/en/account/login/forgot-password',
    header:'/JP/en/newheader',
    footer:'/JP/en/newfooter',
    recordIdJobDetail:'/JP/en/jobportal/s/candidate/job/browse/details/',
    jobApply:'/en/job-detail/:recordId/apply',
    secureJobApply:'/en/job-detail/:recordId/apply',
    gdprThankyouPage:'/en/my-account/marketing-preferences-confirmed',
    secureGdprThankyouPage:'/en/my-account/marketing-preferences-confirmed',
    secureMarketingPrefPage:'/en/my-account/marketing-preferences',
    marketingPrefPage:'/en/my-account/marketing-preferences',
    resetPasswordPage:'/en/account/reset',
    manageCV:'/en/my-account/manage-cv',
    secureThankYou:'/en/job-detail/:recordId/job-success',
    thankyou:'/en/job-detail/:recordId/job-success',
    saveWhatsappPage:'/en/my-account/whatsapp-number-confirmed',
    updateWhatsappPage:'/en/my-account/update-whatsapp-number',
    unsubscribePage:'/JP/en/jobportal/as/alert/submit/unsubscribe',
    alertThankYouPage:'/en/account/job-alert-success',
    emailConfirmationPage:'/JP/en/jobportal/as/gdpr/submit/email-confirmation',
    recruitingNow:'/en/recruitment/recruit-now',
    secureRecruitingNow:'/en/recruitment/recruit-now',
    goLoginPage:'/en/account/partner/go1/training/login',
    savedSearchesPage: '/en/my-account/job-alerts',
    shortlistedJobsPage: '/en/account/saved-jobs',
    changeEmailPage:'/en/my-account/change-email',
    emailConfirmation:'/en/account/update-preferences-email',
    updateYourDetailsUrl: '/en/account/send-your-details'
  },
  routing_url_old:{
    errorPage:'/s/error/',
    successPage:'/s/success/',
    officeLocatorResultPage:'/office-locator/result',
    officeLocatorPage:'/office-locator',
    speculativeCV:'/speculativeCV',
    myProfile:'/s/my-profile',
    login:'/login',
    search:'/search',
    secureSearch:'/search',
    secureJobDetails:'/Job/Detail',
    jobdetails:'/Job/Detail',
    forgetPassword:'/login/forgot-password',
    header:'/JP/en/newheader',
    footer:'/JP/en/newfooter',
    recordIdJobDetail:'/JP/en/jobportal/s/candidate/job/browse/details/',
    jobApply:'/job/:recordId/apply',
    secureJobApply:'/job/:recordId/apply',
    gdprThankyouPage:'/as/thankyou',
    marketingPrefPage:'/as/marketing-prefrences',
    resetPasswordPage:'/reset',
    manageCV:'/s/manage-cv',
    secureThankYou:'/job/apply/details',
    thankyou:'/job/apply/details',
    saveWhatsappPage:'/JP/en/jobportal/s/candidate/submit/whatsapp',
    updateWhatsappPage:'/JP/en/jobportal/s/candidate/update/whatsapp',
    unsubscribePage:'/JP/en/jobportal/as/alert/submit/unsubscribe',
    alertThankYouPage:'/job/VerifyAlert/success',
    emailConfirmationPage:'/JP/en/jobportal/as/gdpr/submit/email-confirmation',
    recruitingNow:'/recruiting-now',
    secureRecruitingNow:'/recruiting-now',
    erpMarketingPref: '/erp/as/marketing-pref',
    secureErpMarketingPref: '/erp/s/marketing-pref',
    erpEmailConfirmation: '/erp/as/email-confirmation',
    erpManageJobAlert: '/erp/as/manage-job-alert',
    secureErpManageJobAlert: '/erp/s/manage-job-alert',
    erpThankyouPage: '/erp/thank-you'
  },
  routing_url_org: {
    searchLinkTitleTerm: '-jobs',
    searchLinklocTerm: 'in-',
    searchLinkTitle: '/en/:orgName/job-search/{jobTitle}-jobs',
    searchLinkLocation: '/en/:orgName/job-search/in-{location}',
    searchLinkInLocation: '/en/:orgName/job-search/{jobTitle}-jobs-in-{location}',
    errorPage:'/en/:orgName/account/error/',
    successPage:'/en/:orgName/account/cv-success/',
    successUpdateEmailPrefences:'/en/:orgName/account/sent-preferences-email',
    confirmationPage: '/en/:orgName/partner/ps/success',
    officeLocatorResultPage:'/en/:orgName/contact/offices-near-me/result',
    officeLocatorPage:'/en/:orgName/contact/offices-near-me',
    speculativeCV:'/en/:orgName/account/send-cv',
    myProfile:'/en/:orgName/my-account/profile',
    login:'/en/:orgName/account/login',
    register: '/en/:orgName/account/register',
    search:'/en/:orgName/job-search',
    secureSearch:'/en/:orgName/job-search',
    secureJobDetails:'/en/:orgName/job-detail',
    jobdetails:'/en/:orgName/job-detail',
    forgetPassword:'/en/:orgName/account/login/forgot-password',
    header:'/:orgName/JP/en/header',
    footer:'/:orgName/JP/en/footer',
    recordIdJobDetail:'/:orgName/JP/en/jobportal/s/candidate/job/browse/details/',
    jobApply:'/en/:orgName/job-detail/:recordId/apply',
    secureJobApply:'/en/:orgName/job-detail/:recordId/apply',
    gdprThankyouPage:'/en/:orgName/my-account/marketing-preferences-confirmed',
    secureGdprThankyouPage:'/en/:orgName/my-account/marketing-preferences-confirmed',
    secureMarketingPrefPage:'/en/:orgName/my-account/marketing-preferences',
    marketingPrefPage:'/en/:orgName/my-account/marketing-preferences',
    resetPasswordPage:'/en/:orgName/account/reset',
    manageCV:'/en/:orgName/my-account/manage-cv',
    secureThankYou:'/en/:orgName/job-detail/:recordId/job-success',
    thankyou:'/en/:orgName/job-detail/:recordId/job-success',
    saveWhatsappPage:'/en/:orgName/my-account/whatsapp-number-confirmed',
    updateWhatsappPage:'/en/:orgName/my-account/update-whatsapp-number',
    unsubscribePage:'/:orgName/JP/en/jobportal/as/alert/submit/unsubscribe',
    alertThankYouPage:'/en/:orgName/my-account/job-alert-success',
    emailConfirmationPage:'/:orgName/JP/en/jobportal/as/gdpr/submit/email-confirmation',
    recruitingNow:'/en/:orgName/recruitment/recruit-now',
    secureRecruitingNow:'/en/:orgName/recruitment/recruit-now',
    goLoginPage:'/en/:orgName/account/partner/go1/training/login',
    savedSearchesPage: '/en/:orgName/my-account/job-alerts',
    shortlistedJobsPage: '/en/:orgName/account/saved-jobs',
    changeEmailPage:'/en/:orgName/my-account/change-email',
    emailConfirmation:'/en/:orgName/account/update-preferences-email',
    updateYourDetailsUrl: '/en/:orgName/account/send-your-details'
  }

};

