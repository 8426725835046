<div class="setting-box cookie-info">
  <h3 [translate]="'cookie_info_text'"></h3>
  <p class="heading-text" [translate]="'cookie_info_subheading_text'"></p>
  <p class="heading-text" [translate]="'markeing_consents_cookie_text2'"></p>
  <div  class="cookies-option-box border">
    <ul class="browser-details" *ngIf="cookiesSettingLinks">
      <li><i class="chrome"></i>
        <em>
          <span [translate]="'chrome_cookie_setting_text'"></span>
        <a [href]="cookiesSettingLinks.chromeCookieLink" target="_blank" [innerText]="cookiesSettingLinks.chromeCookieLink" ></a>
        </em>
      </li>

      <li><i class="firefox"></i>
      <em> <span [translate]="'firefox_cookie_setting_text'"></span>
        <a [href]="cookiesSettingLinks.fireFoxCookieLink"  target="_blank" [innerText]="cookiesSettingLinks.fireFoxCookieLink" ></a></em>
      </li>

      <li><i class="safari"></i>
      <em>
        <span [translate]="'safari_cookie_setting_text'"></span>
        <a [href]="cookiesSettingLinks.safariCookieLink"  target="_blank" [innerText]="cookiesSettingLinks.safariCookieLink" >
        </a>
      </em>
      </li>

      <li><i class="internet-explorer"></i>
      <em>
        <span [translate]="'ie_cookie_setting_text'"></span>
        <a [href]="cookiesSettingLinks.ieCookieLink"  target="_blank" [innerText]="cookiesSettingLinks.ieCookieLink" ></a>
      </em>
      </li>

    </ul>

  </div>
</div>
<div class="border panel-box mt-3 sign-in-footer box" *ngIf="!disableLogin && !marketingPrefContactRoute">
  <div class="sign-in-icon" [translate]="'sign_in_box_text'"></div>
  <div class="text-right"><button id="gtm_backtoLogin_btn" class="btn btn-primary btn-block" (click)="navigateToLogin()"
                                  [translate]="'signIn_register_text'"></button></div>
</div>
