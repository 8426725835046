import {Component, EventEmitter, HostListener, Inject, OnDestroy, OnInit, Optional, Output, PLATFORM_ID} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {SiteService} from '../../../services/site/site.service';
import {RequestService} from '../../../services/request/request.service';
import {apiEndPoints, config, environment, routingEndpoint} from '../../../../../environments/root/environment';
import {AutoSuggestion} from '../../../../jobportal/model/response/auto-suggestion';
import {ActivatedRoute, Router} from '@angular/router';
import {JobSearchService} from '../../../../jobportal/service/job-search.service';
import {DataSharingService} from '../../../../jobportal/service/data-sharing.service';
import {CookieService} from 'ngx-cookie-service';
import {JobSearchFilter} from '../../../../jobportal/model/response/google-job-response';
import {PersonalDetails} from '../../../../jobportal/model/response/my-profile';
import {TokenStorage} from '../../../services/tokenStorage/token-storage';
import {SetTitleService} from "../../../../jobportal/service/set-title.service";
import {isPlatformServer} from '@angular/common';
import { DynamicPageTitlesUtility } from 'src/app/jobportal/shared/utilitiy/dynamicPageTitles';
import { REQUEST, RESPONSE } from '@nguniversal/express-engine/tokens';
import {Request , Response } from 'express';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.scss']
})
export class SearchBoxComponent implements OnInit, OnDestroy {
  public keyWord: string;
  public count: string;
  public autoSuggestion: AutoSuggestion[];
  public showSuggetionList: boolean;
  private specialismId: string;
  private subSpecialismId: string;
  public location: string;
  private locationf: string;
  private industryf: string;
  private sortType = '0';
  private jobType: string;
  private flexiWorkType = '-1';
  private payTypefacet = '-1';
  private minPay = '-1';
  private maxPay = '-1';
  private jobSource: string;
  private countryConfig: any;
  public recentSearches: string[];
  public showRecentSearches: boolean;
  private jobSearchFilter: JobSearchFilter;
  private alertId: string;
  public arrowkeyLocation: number = -1;
  public searching: boolean;
  private searchPageTitle: string;
  private searchPageDesc: string;
  private personalDetails: PersonalDetails;
  private utm_source:string;
  private utm_medium:string;
  private utm_campaign:string;
  private utm_content:string;
  private utm_term:string;
  public routing_url:any;
  public valOrgName:string;
  SubscriptionList:Subscription[]=[];
  marketingPrefContactURL:any;
  marketingPrefContactRoute: boolean;
  @HostListener('document:click')
  clickout() {
    this.showSuggetionList=false;
  }
  constructor(private siteService: SiteService, private requestService: RequestService, private cookieService: CookieService,private titleService: SetTitleService,
              public route: Router, private activatedRoutr: ActivatedRoute, private jobSearchService: JobSearchService, private sharedService: DataSharingService,
              private translate: TranslateService, private tokenStorage: TokenStorage, private dataShareService: DataSharingService,@Inject(PLATFORM_ID) private platformId: object,
              @Optional() @Inject(REQUEST) private request: Request,
              @Optional() @Inject(RESPONSE) private response: Response) {
    this.translate.setDefaultLang(this.siteService.getSiteLocale());
    this.keyWord = '';
    this.location = '';
    this.countryConfig = environment[config.theme + this.siteService.getSiteLocale()]['config']['countryCode'];
    this.valOrgName = this.activatedRoutr.snapshot.params['orgName'];
    if (this.valOrgName) {
      this.routing_url = environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url_org];
      //this.routing_url = this.routing_url.replace(':orgName', orgName);
    }
    else
    {
      this.routing_url = environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url];
    }
    this.marketingPrefContactURL = environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url][routingEndpoint.marketingPrefContactPage];
    console.log('this.marketingPrefContactURL',this.marketingPrefContactURL);
    if(this.marketingPrefContactURL != undefined){ 
    if (this.route.url.indexOf(this.marketingPrefContactURL) > -1) {
      this.marketingPrefContactRoute = true;

    }
    }
  }

  ngOnInit() {
   // if(!isPlatformServer(this.platformId)){
      const activatedRoutrQueryParamMapSubscription = this.activatedRoutr.queryParamMap.subscribe(param => {
        if(this.valOrgName)
        {
          this.routing_url.search= this.routing_url.search.replace(':orgName', this.valOrgName);
          this.routing_url.searchLinkTitle=this.routing_url.searchLinkTitle.replace(':orgName', this.valOrgName);
          this.routing_url.searchLinkLocation=this.routing_url.searchLinkLocation.replace(':orgName', this.valOrgName)
        }
        if (decodeURIComponent(this.route.url).indexOf(this.routing_url.search) > -1 || this.route.url.indexOf('details') > -1 || this.route.url.indexOf('apply') > -1 ||
          this.route.url.indexOf('thankyou') > -1 || this.route.url.indexOf('Job/Details') > -1) {
          if (param.get('q')) {
            this.keyWord = decodeURIComponent(param.get('q'));
          }
          if (param.get('location')) {
            this.location = decodeURIComponent(param.get('location'));
          }
        } else {
          this.keyWord = '';
          this.location = '';
        }
      });
      let baseUrl = environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url];
      let queryParam = this.route.routerState.snapshot.root.firstChild.queryParams;

      if (queryParam['facebook'] > -1 || queryParam['twitter'] > -1) {
        window.location.href = decodeURIComponent(window.location.href);
      }
      if(this.valOrgName)
      {
        if (decodeURIComponent(this.route.url).startsWith(this.routing_url.search.replace(':orgName', this.valOrgName)) || this.route.url.startsWith(this.routing_url.searchLinkTitle.replace(':orgName', this.valOrgName)) || this.route.url.startsWith(this.routing_url.searchLinkLocation.replace(':orgName', this.valOrgName))) {
          this.getParamValues('search');
        }
      }
      else
      {
        if (decodeURIComponent(this.route.url).startsWith(this.routing_url.search)  || this.route.url.startsWith(this.routing_url.searchLinkTitle) || this.route.url.startsWith(this.routing_url.searchLinkLocation)) {
          this.getParamValues('search');
        }
      }
     
      if (decodeURIComponent(this.route.url).indexOf(baseUrl[routingEndpoint.jobdetails]) > -1 || this.route.url.indexOf(baseUrl[routingEndpoint.secureJobDetails]) > -1) {
        this.getParamValues('details');
      }
      const jobSearchServiceSharedRecentSearchesSubscription= this.jobSearchService.sharedRecentSearches.subscribe(keywords => {
        if (keywords) {
          this.recentSearches = decodeURIComponent(keywords).split('|');
        } else {
          this.recentSearches = decodeURIComponent(this.cookieService.get('recentSearches')).split('|');
        }
      });
   // }
   this.SubscriptionList.push(jobSearchServiceSharedRecentSearchesSubscription);
   this.SubscriptionList.push(activatedRoutrQueryParamMapSubscription);
  }

  getAutoComplete($event: KeyboardEvent) {
    if ($event.key == 'Enter') {
      this.keyWord = this.autoSuggestion[this.arrowkeyLocation].suggestion.toString();
      this.autoSuggestion = [];
      this.showSuggetionList = false;
      this.arrowkeyLocation = -1;
      this.redirectToSearch('searchPerformed');
    } else if ($event.key == 'ArrowDown') {
      if (this.arrowkeyLocation < this.autoSuggestion.length - 1) {
        this.arrowkeyLocation++;
      }
    } else if ($event.key == 'ArrowUp') {
      //this.arrowkeyLocation = this.arrowkeyLocation>0? this.arrowkeyLocation-- : 0;
      if (this.arrowkeyLocation >= 0) {
        this.arrowkeyLocation--;
      }
    } else if ($event.key == 'Backspace') {
      this.arrowkeyLocation = -1;
      this.autoSuggestion = [];
    } else {
      this.showRecentSearches = false;
      if (this.keyWord && this.keyWord.length > 2) {
        let query = this.keyWord;
        query = query.replace(/\+/gi, '%2B');
       const getAutoCompleteRequestServiceGetRequestSubscription= this.requestService.getRequest(config.theme, apiEndPoints.gateWayAPI, apiEndPoints.getAutoSuggetion, null, query).subscribe(res => {
          if (res.status == config.SUCCESS) {
            if (res.data.code === 200) {
              this.autoSuggestion = res.data.result;
              this.showSuggetionList = true;
              this.showRecentSearches = false;
            }
          }
        }, error => {
          this.autoSuggestion = [];
        });
        this.SubscriptionList.push(getAutoCompleteRequestServiceGetRequestSubscription);
      } else {
        this.autoSuggestion = [];
      }
    }
  }

  getKeywordValue(suggestion) {
    this.keyWord = suggestion;
    //this.getParamValues();
    this.jobSearchFilter = new JobSearchFilter(this.industryf, this.locationf, '0', '', '',
      '', '', '', 'false',
      'false', 'false', 'RELEVANCE_DESC', '', '', '-1', '-1', '-1',
      '-1', '0', '');
    this.jobSearchService.setJobSearchFilter(this.jobSearchFilter);
    sessionStorage.setItem('jobTypeFilter', JSON.stringify(this.jobSearchFilter));
    this.redirectToSearch('searchPerformed');
  }

  private getParamValues(type: string) {
    let defaultJobSource = '';
    const getParamValuesDataShareServiceSharedClientConfigSubscription = this.dataShareService.sharedClientConfig.subscribe(res => {
      if(res)
        defaultJobSource = res.jobSource;
    });
    const getParamValuesActivatedRoutrQueryParamMapSubscription = this.activatedRoutr.queryParamMap.subscribe(param => {
      if (decodeURIComponent(this.route.url).indexOf(this.routing_url.search) > -1 || this.route.url.indexOf('details') > -1 || this.route.url.indexOf('apply') > -1 ||
        this.route.url.indexOf('thankyou') > -1 || this.route.url.indexOf('Job/Details') > -1 || this.route.url.startsWith(this.routing_url.searchLinkTitle) || this.route.url.startsWith(this.routing_url.searchLinkLocation)) {
        if (param.get('q')) {
          this.keyWord = decodeURIComponent(param.get('q'));
        } else {
          this.keyWord = '';
        }
        if (param.get('location')) {
          this.location = decodeURIComponent(param.get('location'));
        } else {
          this.location = '';
        }
        const searchTerm = this.route.routerState.snapshot.root.children[0].children[0].params['searchTerm'];
        if(this.route.url.startsWith(this.routing_url.search) && searchTerm){
          let search_link = [];
          if(searchTerm.indexOf('-'+this.routing_url.searchLinklocTerm) > -1){
            //search_link = searchTerm.split(this.routing_url.searchLinkTitleTerm);
            const lastIndex = searchTerm.lastIndexOf(this.routing_url.searchLinkTitleTerm);
            search_link = [searchTerm.substring(0,lastIndex)];
            if(search_link[0] === ''){
              search_link.shift();
            }
            // search_link = search_link[0].split('-'+this.routing_url.searchLinklocTerm);
          }else {
           // search_link = searchTerm.split(this.routing_url.searchLinkTitleTerm);
            const lastIndex = searchTerm.lastIndexOf(this.routing_url.searchLinkTitleTerm);
            search_link = [searchTerm.substring(0,lastIndex)];
            if(search_link[0] === ''){
              search_link.shift();
            }
          }
         
          let search_link2 = [];
          if(searchTerm.startsWith(this.routing_url.searchLinklocTerm) || searchTerm.indexOf(this.routing_url.searchLinkTitleTerm+'-'+this.routing_url.searchLinklocTerm)){
            if(searchTerm.startsWith(this.routing_url.searchLinklocTerm)){
              search_link2 = searchTerm.split(this.routing_url.searchLinklocTerm);
           }else if(searchTerm.indexOf(this.routing_url.searchLinkTitleTerm+'-'+this.routing_url.searchLinklocTerm) > -1) {
              search_link2 = searchTerm.split(this.routing_url.searchLinkTitleTerm+'-'+this.routing_url.searchLinklocTerm);
           }
          }
        
          let search_link_job = search_link[0] ? search_link[0]: '';
          search_link2.shift();
          if(search_link_job && searchTerm.indexOf(this.routing_url.searchLinkTitleTerm) > -1){
            this.keyWord = search_link_job ? this.convertSlugTitle(search_link_job) : this.keyWord;
            this.keyWord = decodeURIComponent(this.keyWord);
          }
          if(search_link2[0]){
              this.location = search_link2[0] && this.location == '' ? this.convertSlugToLocation(search_link2[0]) : this.location;
              this.location = decodeURIComponent(this.location);
              //this.location =  this.location.trim();
              //this.location = this.location.charAt(0).toUpperCase() + this.location.slice(1)
          }

        }
      } else {
        this.keyWord = '';
        this.location = '';
      }
      if (param.get('specialismId')) {
        this.specialismId = param.get('specialismId');
      }
      if (param.get('subSpecialismId')) {
        this.subSpecialismId = param.get('subSpecialismId');
      }
      if (param.get('locationf')) {
        this.locationf = param.get('locationf');
      }
      if (param.get('alertid')) {
        this.alertId = param.get('alertid');
        localStorage.setItem('alertid', this.alertId);
      }
      if (param.get('industryf')) {
        this.industryf = param.get('industryf');
      }
      if (param.get('sortType')) {
        this.sortType = param.get('sortType');
      }
      if (param.get('jobType')) {
        this.jobType = param.get('jobType');
      }
      if (param.get('flexiWorkType')) {
        this.flexiWorkType = param.get('flexiWorkType');
      }
      if (param.get('payTypefacet')) {
        this.payTypefacet = param.get('payTypefacet');
      }
      if (param.get('minPay')) {
        this.minPay = param.get('minPay');
      }
      if (param.get('maxPay')) {
        this.maxPay = param.get('maxPay');
      }
      if (param.get('utm_medium')) {
        this.utm_medium = param.get('utm_medium');
      }
      if (param.get('utm_source')) {
        this.utm_source = param.get('utm_source');
      }
      if (param.get('utm_campaign')) {
        this.utm_campaign = param.get('utm_campaign');
      }
      if (param.get('utm_content')) {
        this.utm_content = param.get('utm_content');
      }
      if (param.get('utm_term')) {
        this.utm_term = param.get('utm_term');
      }
      this.jobSource = param.get('jobSource') ? param.get('jobSource') : param.get('jobsource') ? param.get('jobsource') : param.get('source') ? param.get('source') : defaultJobSource;
    });
    this.SubscriptionList.push(getParamValuesActivatedRoutrQueryParamMapSubscription);
    if (type === 'search') {
      this.redirectToSearch('');
    }
    this.SubscriptionList.push(getParamValuesDataShareServiceSharedClientConfigSubscription);
  }

  public redirectToSearch(type: string) {
    let defaultJobSource = '';
    const redirectToSearchDataShareServiceSharedClientConfigSubscription = this.dataShareService.sharedClientConfig.subscribe(res => {
      if(res){
        defaultJobSource = res.jobSource;
      }
    });
    const redirectToSearchActivatedRoutrQueryParamMapSubscription= this.activatedRoutr.queryParamMap.subscribe(param => {
      this.jobSource = param.get('jobSource') ? param.get('jobSource') : param.get('jobsource') ? param.get('jobsource') : param.get('source') ? param.get('source') : defaultJobSource;
      if (param.get('alertid')) {
        this.alertId = param.get('alertid');
      }
    });
    this.searching = true;
    let searchPerformed: boolean;
    if (type === 'searchPerformed') {
      searchPerformed = true;
      this.jobSearchService.setSerchPerfomedValue(searchPerformed);
      this.locationf = '';
      this.specialismId = '';
      this.subSpecialismId = '';
      this.industryf = '';
      this.sortType = '0';
      this.jobType = '-1';
      this.flexiWorkType = '-1';
      this.payTypefacet = '-1';
      this.minPay = '-1';
      this.maxPay = '-1';
      this.utm_medium = '';
      this.utm_source = '';
      this.utm_campaign = '';
      this.utm_content = '';
      this.utm_term = '';
      this.jobSearchService.setJobFilterValue(this.flexiWorkType, this.jobType, this.sortType);
      this.sharedService.sendLocation(this.locationf);
      this.sharedService.sendIndustry(this.industryf);
      this.jobSearchFilter = new JobSearchFilter(this.locationf, this.industryf, this.payTypefacet, '', '',
        '', '', '', 'false', 'false', 'false', '', '', '', '-1',
        '-1', '-1', '-1', '0', '');
      this.jobSearchService.setJobSearchFilter(this.jobSearchFilter);
      sessionStorage.setItem('jobTypeFilter', JSON.stringify(this.jobSearchFilter));
    }
    if (this.countryConfig) {
    //  this.countryConfig = this.countryConfig.replace(/\s*,\s*/g, ",");
      this.location = this.location ? this.location.toLowerCase().indexOf(this.countryConfig.toLowerCase()) == -1 ? this.location + this.countryConfig : this.location : '';
    }
    let routingUrl = '';
    //redirect for orgName
    let orgName = this.activatedRoutr.snapshot.params['orgName'];
    if (orgName) {

      if(this.routing_url.searchLinkTitle && this.routing_url.searchLinkInLocation && this.routing_url.searchLinkLocation){
        if(this.keyWord && this.location) {
          routingUrl = this.routing_url.searchLinkInLocation.replace('{jobTitle}',this.convertTitleToSlug(this.keyWord)).replace('{location}',this.convertLocationToSlug(this.location));
        }else if(this.keyWord) {
          routingUrl = this.routing_url.searchLinkTitle.replace('{jobTitle}',this.convertTitleToSlug(this.keyWord));
        }else if(this.location){
          routingUrl = this.routing_url.searchLinkLocation.replace('{location}',this.convertLocationToSlug(this.location));
        }else {
          routingUrl = environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url_org][routingEndpoint.search];
         
        }
      }else {
        routingUrl = environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url_org][routingEndpoint.search];
      }
      routingUrl = routingUrl.replace(':orgName', orgName);
    } else {
      if(this.routing_url.searchLinkTitle && this.routing_url.searchLinkInLocation && this.routing_url.searchLinkLocation){
        if(this.keyWord && this.location) {
          routingUrl = this.routing_url.searchLinkInLocation.replace('{jobTitle}',this.convertTitleToSlug(this.keyWord)).replace('{location}',this.convertLocationToSlug(this.location));
        }else if(this.keyWord) {
          routingUrl = this.routing_url.searchLinkTitle.replace('{jobTitle}',this.convertTitleToSlug(this.keyWord));
        }else if(this.location){
          routingUrl = this.routing_url.searchLinkLocation.replace('{location}',this.convertLocationToSlug(this.location));
        }else {
          routingUrl = environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url][routingEndpoint.search];
        }
      }else {
         routingUrl = environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url][routingEndpoint.search];
      }
    }
    if(this.location &&  this.location.trim()){
      this.location = this.location.trim();
      this.location = this.formatLocation(this.location);
    }
    if(this.keyWord && this.keyWord.trim()){
      this.keyWord = this.keyWord.trim();
      this.keyWord = this.formatTitle(this.keyWord);
    }
   

    this.jobSearchService.setKeyWords(this.keyWord, this.location,this.count);
    this.showSuggetionList = false;
    /*let localAlertId=localStorage.getItem("alertid");
    if(localAlertId && localAlertId!=='undefined' && localAlertId !=='null')
      this.alertId=localAlertId;*/
    if (this.tokenStorage.getLoggedInToken()) {
      this.personalDetailsResponse();
    }
    this.keyWord=this.keyWord.trim();
    const redirectToSearchTranslateGetTranslationSubscription = this.translate.getTranslation(this.siteService.getSiteLocale()).subscribe((res) => {
      let jobTitle=this.keyWord?this.keyWord+" ":" ";
      let loc=this.location?this.location+" ":environment[config.theme+this.siteService.getSiteLocale()]['config']['country_name']+" ";
      if (res['search_result_title'])
        this.searchPageTitle = res['search_result_title'];
      if (res['search_page_description_text'])
        this.searchPageDesc = res['search_page_description_text'];
      if (res['search_result_title1'])
        this.searchPageTitle = jobTitle+res['search_result_title1']+loc+res['search_result_title2'];
      if (res['meta_desc_text1'])
        this.searchPageDesc = res['meta_desc_text1']+jobTitle+res['meta_desc_text2']+loc+res['meta_desc_text3']+jobTitle+res['meta_desc_text4'];

      this.searchPageDesc = environment[config.theme+this.siteService.getSiteLocale()]['config']['dynamicPageTitleMetaTags'] ? this.setPageTitleDescription(res, 'meta') : this.searchPageDesc;
      this.searchPageTitle = environment[config.theme+this.siteService.getSiteLocale()]['config']['dynamicPageTitleMetaTags'] ? this.setPageTitleDescription(res, 'title') : this.searchPageTitle;
      this.titleService.setTitleForPage(this.searchPageTitle);
      if (decodeURIComponent(this.route.url).indexOf(this.routing_url.search) > -1 || this.route.url.startsWith(this.routing_url.searchLinkTitle) || this.route.url.startsWith(this.routing_url.searchLinkLocation)) {
        const queryParamsObj = {
          alertid: this.alertId,
          q: this.keyWord ? this.keyWord : '',
          location: this.location ? this.location : '',
          specialismId: this.specialismId ? this.specialismId : '',
          subSpecialismId: this.subSpecialismId ? this.subSpecialismId : '',
          locationf: this.locationf ? this.locationf : '',
          industryf: this.industryf ? this.industryf : '',
          sortType: this.sortType ? this.sortType : '0',
          jobType: this.jobType ? this.jobType : '-1',
          flexiWorkType: this.flexiWorkType ? this.flexiWorkType : '-1',
          payTypefacet: this.payTypefacet ? this.payTypefacet : '-1',
          minPay: this.minPay ? this.minPay : '-1',
          maxPay: this.maxPay ? this.maxPay : '-1',
          jobSource: this.jobSource ? this.jobSource : defaultJobSource,
          searchPageTitle: this.searchPageTitle ? this.searchPageTitle : '',
          searchPageDesc: this.searchPageDesc ? this.searchPageDesc : ''
        };
        this.setUTMParams(queryParamsObj, this.utm_medium, this.utm_source, this.utm_campaign, this.utm_content, this.utm_term);
        this.route.navigate([routingUrl], {
          queryParams: queryParamsObj,
          replaceUrl:true
        }).then(r => {
          this.searching = false;
        });
        if (isPlatformServer(this.platformId)) {
        const searchTerm = this.route.routerState.snapshot.root.children[0].children[0].params['searchTerm'];
        if(this.route.url.startsWith(this.routing_url.search) && !searchTerm){
          if(this.location || this.keyWord){
              this.response.status(301);
            }
          }
        }

      } else {
        let searchRedirectURL: string;
        this.jobSource = this.jobSource ? this.jobSource : defaultJobSource;
        if (this.alertId) {
          searchRedirectURL = window.location.origin + routingUrl + '?alertid=' + this.alertId + '&q=' + encodeURIComponent(this.keyWord) + '&location=' + this.location +
            '&specialismId=' + this.specialismId + '&subSpecialismId=' + this.subSpecialismId + '&locationf=' + this.locationf + '&industryf=' + this.industryf + '&sortType=' + this.sortType +
            '&jobType=' + this.jobType + '&flexiWorkType=' + this.flexiWorkType + '&payTypefacet=' + this.payTypefacet + '&minPay=' + this.minPay + '&maxPay=' + this.maxPay + '&jobSource=' + this.jobSource +
            '&searchPageTitle=' + this.searchPageTitle + '&searchPageDesc=' + this.searchPageDesc;
        } else {
          searchRedirectURL = window.location.origin + routingUrl + '?q=' + encodeURIComponent(this.keyWord) + '&location=' + this.location +
            '&specialismId=' + this.specialismId + '&subSpecialismId=' + this.subSpecialismId + '&locationf=' + this.locationf + '&industryf=' + this.industryf + '&sortType=' + this.sortType +
            '&jobType=' + this.jobType + '&flexiWorkType=' + this.flexiWorkType + '&payTypefacet=' + this.payTypefacet + '&minPay=' + this.minPay + '&maxPay=' + this.maxPay + '&jobSource=' + this.jobSource +
            '&searchPageTitle=' + this.searchPageTitle + '&searchPageDesc=' + this.searchPageDesc;
        }
        if(this.utm_medium && this.utm_source && this.utm_campaign && this.utm_content && this.utm_term) {
          if (this.alertId) {
            searchRedirectURL = window.location.origin + routingUrl + '?alertid=' + this.alertId + '&q=' + encodeURIComponent(this.keyWord) + '&location=' + this.location +
              '&specialismId=' + this.specialismId + '&subSpecialismId=' + this.subSpecialismId + '&locationf=' + this.locationf + '&industryf=' + this.industryf + '&sortType=' + this.sortType +
              '&jobType=' + this.jobType + '&flexiWorkType=' + this.flexiWorkType + '&payTypefacet=' + this.payTypefacet + '&minPay=' + this.minPay + '&maxPay=' + this.maxPay + '&jobSource=' + this.jobSource +
              '&utm_medium=' + this.utm_medium +'&utm_source=' + this.utm_source + '&utm_campaign=' + this.utm_campaign + '&utm_content=' + this.utm_content + '&utm_term=' + this.utm_term +
              '&searchPageTitle=' + this.searchPageTitle + '&searchPageDesc=' + this.searchPageDesc;

          } else {
            searchRedirectURL = window.location.origin + routingUrl + '?q=' + encodeURIComponent(this.keyWord) + '&location=' + this.location +
              '&specialismId=' + this.specialismId + '&subSpecialismId=' + this.subSpecialismId + '&locationf=' + this.locationf + '&industryf=' + this.industryf + '&sortType=' + this.sortType +
              '&jobType=' + this.jobType + '&flexiWorkType=' + this.flexiWorkType + '&payTypefacet=' + this.payTypefacet + '&minPay=' + this.minPay + '&maxPay=' + this.maxPay + '&jobSource=' + this.jobSource +
              '&utm_medium=' + this.utm_medium + '&utm_source=' + this.utm_source + '&utm_campaign=' + this.utm_campaign + '&utm_content=' + this.utm_content + '&utm_term=' + this.utm_term +
              '&searchPageTitle=' + this.searchPageTitle + '&searchPageDesc=' + this.searchPageDesc;
          }
        }
        window.location.href = searchRedirectURL;

      }
    });
    this.SubscriptionList.push(redirectToSearchDataShareServiceSharedClientConfigSubscription);
    this.SubscriptionList.push(redirectToSearchActivatedRoutrQueryParamMapSubscription);
    this.SubscriptionList.push(redirectToSearchTranslateGetTranslationSubscription);

  }

  private personalDetailsResponse() {
    const personalDetailsResponseRequestServiceGetRequestSubscription = this.requestService.getRequest(config.theme, apiEndPoints.gateWayAPI, apiEndPoints.getpersonaldetails, null, null).subscribe(
      (res) => {
        if (res.data && res.status == config.SUCCESS) {
          this.personalDetails = res.data;
        }
        this.sharedService.setPersonalDetails(this.personalDetails);
      }, (error) => {
      });
      this.SubscriptionList.push(personalDetailsResponseRequestServiceGetRequestSubscription);

  }
  /**
   * Generates content of following tags:
   * Page title
   * Meta
   * @param response 
   * @param propName 
   * @returns 
   */
  private setPageTitleDescription(response, propName: string) : string{
    const splitwithoutspace=environment[config.theme + this.siteService.getSiteLocale()]['config']['splitwithoutspace']?environment[config.theme + this.siteService.getSiteLocale()]['config']['splitwithoutspace']:false;
    return DynamicPageTitlesUtility.getContentBasedOnSearch(this.location, this.keyWord, environment[config.theme+this.siteService.getSiteLocale()]['config']['country_name'],response, propName,splitwithoutspace);
  }

  private convertTitleToSlug(title: string) {
    const str = title.toLowerCase().replace(/ /g, '-')
      //.replace(/[^\w-]+/g, '');
      .replace(/[`~!@#$%^&*()_|+\=?;:'",.<>\{\}\[\]\\\/]/gi, '');
    return str;
  }
  private convertLocationToSlug(location: string){
    location = location.replace(/\s*,\s*/g, ",");
    location = location.replace(/,(?=[^,]*$)/, ' ');
    return this.convertTitleToSlug(location);
  }
  private convertSlugToLocation(slug: string){
    slug = slug.toLowerCase();
     // let words = slug.split('-');
      let words = [];
    if(slug && slug.indexOf('-') > -1){
       words = slug.split('-');
    }else {
       words = [slug];
    }
   
      //words.shift();
      let lastWord = '';
      if(this.countryConfig && words.length > 1)
       lastWord = words.pop();
      return words.join(' ') +(lastWord?', ':'')+ (lastWord.length < 3 ? lastWord.toUpperCase(): lastWord.charAt(0).toUpperCase() + lastWord.slice(1));  
  }
  private convertSlugTitle(slug: string){
    let words = slug.split('-');
    //words.shift();
    for (let i = 0; i < words.length; i++) {
      let word = words[i];
      words[i] = word.charAt(0).toUpperCase() + word.slice(1);
    }
    return words.join(' ');  
}
private formatTitle(title: string){
  title = title.toLowerCase();
  let words = title.split(' ');
  //words.shift();
  for (let i = 0; i < words.length; i++) {
    let word = words[i];
    words[i] = word.charAt(0).toUpperCase() + word.slice(1);
  }
  return words.join(' ');  
}
private formatLocation(slug: string){
  slug = slug.toLowerCase();
  let words = [];
    if(slug && slug.indexOf(',') > -1){
       words = slug.split(',');
    }else {
       words = [slug];
    }
   

    for (let i = 0; i < words.length; i++) {
      let word = words[i];
      words[i] = word.charAt(0).toUpperCase() + word.slice(1);
    }
    //words.shift();
    let lastWord = '';
    if(this.countryConfig && words.length > 1){
      lastWord = words.pop();
      if(lastWord)
      lastWord = lastWord.trim();
    }
    
    return words.join(' ') +(lastWord?', ':'')+ (lastWord.length < 4 ? lastWord.toUpperCase(): lastWord.charAt(0).toUpperCase() + lastWord.slice(1));  
}
/**
 * Checks and set utm params
 * @param queryParamsObj 
 * @param utm_medium 
 * @param utm_source 
 * @param utm_campaign 
 * @param utm_content 
 * @param utm_term 
 */
private setUTMParams(queryParamsObj, utm_medium, utm_source, utm_campaign, utm_content, utm_term){
  if(utm_medium){
    queryParamsObj['utm_medium'] = utm_medium;
  }
  if(utm_source){
    queryParamsObj['utm_source'] = utm_source;
  }
  if(utm_campaign){
    queryParamsObj['utm_campaign'] = utm_campaign;
  }
  if(utm_content){
    queryParamsObj['utm_content'] = utm_content;
  }
  if(utm_term){
    queryParamsObj['utm_term'] = utm_term;
  }
}

ngOnDestroy(): void {
  this.SubscriptionList.forEach(subscribe=>{
    if(subscribe){
      subscribe.unsubscribe();
  }
  });
} 
}
