export const AppRouter = {
  dropbox_link: 'DropBox',
  gdrive_link: 'GDrive',
  seek_apply_link: 'seekApply',
  global_consent_link: 'globalConsent',
  update_consent_link: 'consent',
  thank_you: 'thank-you',
  job_detail_route_link: 'Job/Detail/:recordId',
  job_detail_seek_route_link: 'SeekAuth/:recordId',
  search_link: 'search',
  spec_cv_link: 'speculativeCV',
  register_link: 'register',
  hcp_link: 'partner/ps',
  job_route: 'job',
  job_apply_route: 'apply/choose-cv/:recordId',
  forgot_password_link_1: 'forgot-password',
  forgot_password_link_2: 'forgotPassword',
  forgot_password_redirect_link: '/login/forgot-password',
  reset_link: 'reset',
  my_profile_link: 'my-profile',
  my_profile_redirect_link: '/s/my-profile',
  secure_link: 's',
  login_link: 'login',
  go1_link: 'partner',
  all_office_link: 'all-offices',
  office_locator_link: 'office-locator',
  saved_jobs_link: 'shortlisted-jobs',
  recruiting_now_link: 'recruiting-now',
  gdpr_marketing_pref_link: 'marketing-preferences',
  dk_gdpr_marketing_pref_link: 'dk-marketing-preferences',
  dk_thnku_marketing_pref_link: 'dk-marketing-preferences/thankyou',
  whatsapp_alert_link: 'whatsAppAlert',
  unsubscribe_link: 'unsubscribe',
  gdpr_email_confirm_link: 'email-confirmation',
  view_email_link:'viewemailink',
  update_your_details_link: 'update-your-details',
  unsubscribe_email_confirmation: 'unsubscribe-email-confirmation',
  orgName:':orgName',
  update_your_privacy_link: 'update-your-privacy',
  update_your_privacy_link_th: 'UpdateThPrivacyPolicy',
  gdpr_marketing_pref_contact_link: 'marketing-preferences-contact',
};
