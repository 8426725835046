export const baseUrl = {
  apiBaseUrl: 'https://moatapi.hays.com.au/jobportalapi',
  apiBaseUrlWrapper: 'https://moatapi.hays.com.au/jobportalwr'
};
export const jp_JP = {
  config: {
    countryCode: ', 日本',
    country_name: 'Japan',
    gtm_code:"GTM-MMBKTV",
    view_google_link: 'http://maps.google.co.jp/maps?q=',
    zopim_enabled: false,
    zopim_enabled_device: '-Desktop',
    zopim_key_withURL: '//v2.zopim.com/?2SfCzrC8fQC6u5Mn6KEr6KMcqQoU4nJL',
    maximumSize:2097152,
    appleItunesMetaEnabled: true,
    azure_enable: true,
    auzureLoginURL : 'https://testsg.hays.co.jp/portal/auth?application=jobportal&client={orgName}&entityType=candidate&domain=jp&locale=jp&hostname=testsg&action=&workFlow=',
    dynamicPageTitleMetaTags: true,
    canonicalSearchLinkCompleteURL: false,
    indeedAPIURL:'https://cprofile1test.hays.com.au/portalservice/resources/registration/applyindeed/',
    addAutoBlockAssetScriptEnabled: true,
    addAutoBlockOptOutScriptEnabled: true,
    isCaptchaValueEnabled: false,
    isResubscibeEnabled: false,
    searchURL:'/求人-検索',
    errorPageCareerAdvice:'/advice',
    errorPageRecruitNow:'/employers',
    errorPageRecruitment:'/employer-services/contract-employment',
    errorPageMarketInsights:'/news'
  },
  liferaybaseUrl: 'https://testsg.hays.co.jp',
  liferayUrl: 'https://testsg.hays.co.jp',
  cookieDomain: '.hays.co.jp',
  cookieBannerDomain: '.hays.co.jp',
  siteBaseURL: 'https://testsg.hays.co.jp',
  gateWayAPI: {
    location: baseUrl.apiBaseUrl + '/jp/jp/jobportal/s/candidate/submit/location',
    isCaptchaDisabled: baseUrl.apiBaseUrl + '/int/s/jp/jp/{orgname}/partner/browse/v1/captchaenabled',
    locationOnRecruitingNow: baseUrl.apiBaseUrl + '/int/s/jp/jp/{orgname}/recruiting/browse/v1/location?type=recruiting-now-locations',
    employeeType: baseUrl.apiBaseUrl + '/int/s/jp/jp/{orgname}/recruiting/browse/v1/employment?type=recruiting-now-employment',
    submitRecruitingForm: baseUrl.apiBaseUrl + '/int/s/jp/jp/{orgname}/content/submit/v1/recruitingnow',
    getSector: baseUrl.apiBaseUrl + '/int/s/jp/jp/{orgname}/content/browse/v1/specilisms', // post call for getting list of sectors
    getLocationHierarchy: baseUrl.apiBaseUrl + '/int/s/jp/jp/{orgname}/content/browse/v1/hierarchy',
    getStateList: baseUrl.apiBaseUrl + '/int/s/jp/jp/{orgname}/content/browse/v1/states',
    getOfficeForSpecialism: baseUrl.apiBaseUrl + '/int/s/jp/jp/{orgname}/content/browse/v1/offices',
    getSubSpecialism: baseUrl.apiBaseUrl + '/int/s/jp/jp/{orgname}/content/browse/v1/subspecialism ',
    getAllOffice: baseUrl.apiBaseUrl + '/int/s/jp/jp/{orgname}/content/browse/v1/alloffices',
    getOfficeResultForAPAC: baseUrl.apiBaseUrl + '/int/s/jp/jp/{orgname}/content/browse/v1/officeresultapac',
    getLatLongOfficeResult: baseUrl.apiBaseUrl + '/int/s/jp/jp/{orgname}/content/browse/v1/officeresultlatlong',
    isAutoSugesstionEnabled: baseUrl.apiBaseUrl + '/int/s/jp/jp/{orgname}/google/browse/v1/isautosugesstionenabled',
    getTermsAndConditions: baseUrl.apiBaseUrl + '/int/s/jp/jp/{orgname}/recruiting/browse/v1/consentcheckbox?type=consent_checkboxnew',
    getGDriveAndDropbox: baseUrl.apiBaseUrl + '/int/s/jp/jp/{orgname}/partner/browse/v1/isdropboxgdrivedisabled',
    getIsKanaEnabled: baseUrl.apiBaseUrl + '/int/s/jp/jp/{orgname}/partner/browse/v1/iskanaenabled',
    register: baseUrl.apiBaseUrlWrapper + '/int/s/jp/jp/{orgname}/candidate/submit/v1/register',
    pagemanagement: baseUrl.apiBaseUrl + '/int/s/jp/jp/{orgname}/pagemanagement/browse/v1/pagemenu',
    countryLanguage: baseUrl.apiBaseUrl + '/int/s/jp/jp/{orgname}/site/browse/v1/getlanguages?type=change_language',
    getBrowseByExpertise: baseUrl.apiBaseUrl + '/int/s/jp/jp/{orgname}/content/browse/v1/browsebyexpertise',
    showOfficeEmail: baseUrl.apiBaseUrl + '/int/s/jp/jp/{orgname}/content/browse/v1/showofficeemail',
    connectdropbox: baseUrl.apiBaseUrl + '/int/s/jp/jp/{orgname}/partner/browse/v1/connectdropbox',
    connectGdrive: baseUrl.apiBaseUrl + '/int/s/jp/jp/{orgname}/partner/browse/v1/connectgdrive',
    getDropBoxFiles: baseUrl.apiBaseUrl + '/int/s/jp/jp/{orgname}/partner/browse/v1/dropboxfiles',
    getDriveFiles: baseUrl.apiBaseUrl + '/int/s/jp/jp/{orgname}/partner/browse/v1/gdrivefiles',
    getFileLink: baseUrl.apiBaseUrl + '/int/s/jp/jp/{orgname}/partner/browse/v1/dropboxfilelink',
    gdriveFileLink: baseUrl.apiBaseUrl + '/int/s/jp/jp/{orgname}/partner/browse/v1/gdrivefilelink',
    getAutoSuggetion: baseUrl.apiBaseUrl + '/int/s/jp/jp/{orgname}/job/browse/v1/autosuggestion?keyWord=',
    submitEmail: baseUrl.apiBaseUrl + '/int/s/jp/jp/{orgname}/gdpr/submit/emailconfirmation',
    getgooglejobs: baseUrl.apiBaseUrl + '/int/s/jp/jp/{orgname}/job/browse/v1/jobsv2',
    getgooglejobssecure: baseUrl.apiBaseUrl + '/int/s/jp/jp/{orgname}/job/browse/v1/jobsv2',
    jobflowconfig: baseUrl.apiBaseUrl + '/int/s/jp/jp/{orgname}/pagemanagement/browse/v1/jobflowconfig',
    getJobFilters: baseUrl.apiBaseUrl + '/int/s/jp/jp/{orgname}/job/browse/v1/filters?type=job_filter',
    getCustomerServiceEmail: baseUrl.apiBaseUrl + '/int/s/jp/jp/{orgname}/pagemanagement/browse/v1/customeremail',
    pagemanagementSecure: baseUrl.apiBaseUrl + '/int/s/jp/jp/{orgname}/pagemanagement/browse/v1/pagemenu',
    createToken: baseUrl.apiBaseUrl + '/ext/ns/jp/jp/{orgname}/usermanagement/create/v1/token',
    validatePage: baseUrl.apiBaseUrl + '/int/s/jp/jp/{orgname}/usermanagement/validate/v1/page',
    submitForgetPassEmail: baseUrl.apiBaseUrl + '/int/s/jp/jp/{orgname}/candidate/submit/v1/forgetpassword',
    getInterviewAndCvTips: baseUrl.apiBaseUrl + '/int/s/jp/jp/{orgname}/candidate/browse/v1/interviewandcvtips',
    getRecentApplication: baseUrl.apiBaseUrl + '/int/s/jp/jp/{orgname}/candidate/browse/v1/recentapplication',
    getInterviewCount: baseUrl.apiBaseUrl + '/int/s/jp/jp/{orgname}/notification/browse/v1/interview',
    getUpcomingInterview: baseUrl.apiBaseUrlWrapper + '/int/s/jp/jp/{orgname}/candidate/browse/v1/interviewdetails',
    getpersonaldetails: baseUrl.apiBaseUrlWrapper + '/int/s/jp/jp/{orgname}/candidate/browse/v1/getpersonaldetailsupgrade',
    getGDriveFiles: baseUrl.apiBaseUrl + '/int/s/jp/jp/{orgname}/partner/browse/v1/gdrivefiles',
    gcsPublish: baseUrl.apiBaseUrl + '/int/s/jp/jp/{orgname}/job/submit/v1/gcsevent',
    saveAlert: baseUrl.apiBaseUrl + '/int/s/jp/jp/{orgname}/job/create/v1/alert',
    saveWhatsapp: baseUrl.apiBaseUrl + '/int/s/jp/jp/{orgname}/job/submit/v1/whatsapp',
    updateWhatsapp: baseUrl.apiBaseUrl + '/int/s/jp/jp/{orgname}/candidate/update/v1/whatsappnumber',
    getWhatsappNumber: baseUrl.apiBaseUrl + '/int/s/jp/jp/{orgname}/browse/v1/whatsappnumber',
    getWhatsAppAlerts: baseUrl.apiBaseUrl + '/int/s/jp/jp/{orgname}/decrypt/v1/whatsappalert',
    getJobAlerts: baseUrl.apiBaseUrl + '/int/s/jp/jp/{orgname}/job/browse/v1/alert',
    getGdprConfig: baseUrl.apiBaseUrl + '/int/s/jp/jp/{orgname}/pagemanagement/browse/v1/gdprconfig',
    getMarketingPreferences: baseUrl.apiBaseUrlWrapper + '/int/s/jp/jp/{orgname}/gdpr/browse/v1/marketingpreferences',
    updateMarketingPreferences: baseUrl.apiBaseUrlWrapper + '/int/s/jp/jp/{orgname}/gdpr/update/v1/marketingpreferences',
    decryptIdentifier: baseUrl.apiBaseUrl + '/int/ns/jp/jp/{orgname}/decrypt/browse/v1/decryptidentifier',
    saveJob: baseUrl.apiBaseUrl + '/int/s/jp/jp/{orgname}/job/create/v1/savejob',
    deleteJob: baseUrl.apiBaseUrl + '/int/s/jp/jp/{orgname}/job/delete/v1/deletesavedjob',
    getSavedJobs: baseUrl.apiBaseUrl + '/int/s/jp/jp/{orgname}/job/browse/v1/getsavedjobs',
    resetPassword: baseUrl.apiBaseUrl + '/int/s/jp/jp/{orgname}/candidate/submit/v1/resetpassword',
    deleteJobAlert: baseUrl.apiBaseUrl + '/int/s/jp/jp/{orgname}/job/delete/v1/alert',
    editAlert: baseUrl.apiBaseUrl + '/int/s/jp/jp/{orgname}/job/update/v1/alert',
    changeEmail: baseUrl.apiBaseUrl + '/int/s/jp/jp/{orgname}/candidate/submit/v1/changeemail',
    getCvs: baseUrl.apiBaseUrlWrapper + '/int/s/jp/jp/{orgname}/candidate/browse/v1/getcvs',
    downloadCV: baseUrl.apiBaseUrl + '/int/s/jp/jp/{orgname}/candidate/browse/v1/downloadcv',
    deleteCv: baseUrl.apiBaseUrlWrapper + '/int/s/jp/jp/{orgname}/candidate/delete/v1/deletecv',
    getSingleJob: baseUrl.apiBaseUrl + '/int/s/jp/jp/{orgname}/job/browse/v1/getsavedjob',
    changePassword: baseUrl.apiBaseUrl + '/int/s/jp/jp/{orgname}/candidate/submit/v1/changepassword',
    uploadcv: baseUrl.apiBaseUrlWrapper + '/int/s/jp/jp/{orgname}/candidate/upload/v1/cv',
    jobApply: baseUrl.apiBaseUrlWrapper + '/int/s/jp/jp/{orgname}/candidate/submit/v1/jobapply',
    unsubscribe: baseUrl.apiBaseUrl + '/int/s/jp/jp/{orgname}/alert/v1/unsubscribe',
    jobAlertVerify: baseUrl.apiBaseUrl + '/int/s/jp/jp/{orgname}/alert/v1/verify',
    applyLinkedin: baseUrl.apiBaseUrlWrapper + '/int/s/jp/jp/{orgname}/job/v1/applylinkedin',
    getWidgetList: baseUrl.apiBaseUrl + '/int/ns/jp/jp/{orgname}/content/browse/v1/widgetlist',
    getTimeSheet: baseUrl.apiBaseUrl + '/int/s/jp/jp/{orgname}/auth/browse/v1/pagemenu',
    getKillerQuestions: baseUrl.apiBaseUrl + '/int/s/jp/jp/{orgname}/content/browse/v1/killerquestions',
    validateAlert: baseUrl.apiBaseUrl+'/int/ns/jp/jp/{orgname}/alert/v1/validatealert',
    signedURL:baseUrl.apiBaseUrl+'/int/s/jp/jp/{orgname}/user/create/v1/signedurl',
    getErpMarketingPref:baseUrl.apiBaseUrlWrapper+'/int/s/jp/jp/{orgname}/user/browse/v1/marketingpref',
    updateErpMarketingPref:baseUrl.apiBaseUrlWrapper+'/int/s/jp/jp/{orgname}/user/save/v1/marketingpref',
    erpValidateEmail:baseUrl.apiBaseUrl+'/int/s/jp/jp/{orgname}/user/send/v1/consentemail',
    getClientConfig:baseUrl.apiBaseUrl+'/int/ns/jp/jp/{orgname}/client/browse/v1/clientconfig',
    deleteAllAlerts:baseUrl.apiBaseUrl+'/int/s/jp/jp/{orgname}/job/delete/v1/deleteallalerts',
    viewEmailLink:baseUrl.apiBaseUrl+'/int/ns/jp/jp/{orgname}/alert/v1/viewmaillink',
    getLanguageSpoken:baseUrl.apiBaseUrl+'/int/ns/jp/jp/{orgname}/site/browse/v1/getlanguagespoken?type=languages-spoken',
    logoutUser:baseUrl.apiBaseUrl+'/ext/ns/jp/jp/{orgname}/auth/delete/v1/session',
  },
  routing_url:{
    searchLinkTitleTerm: '-求人',
    searchLinklocTerm: '勤務地-',
    searchLinkTitle: '/求人-検索/{jobTitle}-求人',
    searchLinkLocation: '/求人-検索/勤務地-{location}',
    searchLinkInLocation: '/求人-検索/{jobTitle}-求人-勤務地-{location}',
    errorPage:'/アカウント/エラー/',
    successPage:'/アカウント/提出完了/',
    successUpdateEmailPrefences:'/アカウント/ご希望のメールアドレスに送信',
    confirmationPage: '/partner/ps/success',
    officeLocatorResultPage:'/お問い合わせ/最寄りのオフィス/result',
    officeLocatorPage:'/お問い合わせ/最寄りのオフィス',
    speculativeCV:'/アカウント/職務経歴書登録',
    myProfile:'/マイアカウント/プロフィール',
    login:'/アカウント/ログイン',
    register: '/アカウント/登録',
    search:'/求人-検索',
    secureSearch:'/求人-検索',
    secureJobDetails:'/求人-詳細',
    jobdetails:'/求人-詳細',
    forgetPassword:'/アカウント/ログイン/forgot-password',
    header:'/JP/jp/newheader',
    footer:'/JP/jp/newfooter',
    recordIdJobDetail:'/JP/jp/jobportal/s/candidate/job/browse/details/',
    jobApply:'/求人-詳細/:recordId/応募',
    secureJobApply:'/求人-詳細/:recordId/応募',
    gdprThankyouPage:'/マイアカウント/配信の設定確認',
    secureGdprThankyouPage:'/マイアカウント/配信の設定確認',
    secureMarketingPrefPage:'/マイアカウント/配信の設定',
    marketingPrefPage:'/マイアカウント/配信の設定',
    resetPasswordPage:'/アカウント/リセット',
    manageCV:'/マイアカウント/職務経歴書の設定',
    secureThankYou:'/求人-詳細/:recordId/求人応募確認',
    thankyou:'/求人-詳細/:recordId/求人応募確認',
    saveWhatsappPage:'/マイアカウント/whatsapp更新の確認',
    updateWhatsappPage:'/マイアカウント/whatsapp登録',
    unsubscribePage:'/JP/jp/jobportal/as/alert/submit/配信停止',
    alertThankYouPage:'/アカウント/新着求人通知の設定確認',
    emailConfirmationPage:'/JP/jp/jobportal/as/gdpr/submit/email-confirmation',
    recruitingNow:'/アカウント/求人登録',
    secureRecruitingNow:'/アカウント/求人登録',
    goLoginPage:'/アカウント/partner/go1/training/ログイン',
    savedSearchesPage: '/マイアカウント/新着求人通知',
    shortlistedJobsPage: '/アカウント/保存した求人',
    changeEmailPage:'/マイアカウント/メールアドレスの変更',
    emailConfirmation:'/アカウント/メールアドレスの確認',
    updateYourDetailsUrl: '/アカウント/登録情報の更新'
  },
  routing_url_old: {
    errorPage: '/s/error/',
    successPage: '/s/success/',
    officeLocatorResultPage: '/office-locator/result',
    officeLocatorPage: '/office-locator',
    speculativeCV: '/speculativeCV',
    myProfile: '/s/my-profile',
    login: '/login',
    search: '/search',
    secureSearch: '/search',
    secureJobDetails: '/Job/Detail',
    jobdetails: '/Job/Detail',
    forgetPassword: '/login/forgot-password',
    header: '/JP/jp/newheader',
    footer: '/JP/jp/newfooter',
    recordIdJobDetail: '/JP/jp/jobportal/s/candidate/job/browse/details/',
    jobApply: '/job/:recordId/apply',
    secureJobApply: '/job/:recordId/apply',
    gdprThankyouPage: '/as/thankyou',
    marketingPrefPage: '/as/marketing-prefrences',
    resetPasswordPage: '/reset',
    manageCV: '/s/manage-cv',
    secureThankYou: '/job/apply/details',
    thankyou: '/job/apply/details',
    saveWhatsappPage: '/JP/jp/jobportal/s/candidate/submit/whatsapp',
    updateWhatsappPage: '/JP/jp/jobportal/s/candidate/update/whatsapp',
    unsubscribePage: '/JP/jp/jobportal/as/alert/submit/unsubscribe',
    alertThankYouPage:'/job/VerifyAlert/success',
    emailConfirmationPage: '/JP/jp/jobportal/as/gdpr/submit/email-confirmation',
    recruitingNow: '/recruiting-now',
    secureRecruitingNow: '/recruiting-now',
    erpMarketingPref: '/erp/as/marketing-pref',
    secureErpMarketingPref: '/erp/s/marketing-pref',
    erpEmailConfirmation: '/erp/as/email-confirmation',
    erpManageJobAlert: '/erp/as/manage-job-alert',
    secureErpManageJobAlert: '/erp/s/manage-job-alert',
    erpThankyouPage: '/erp/thank-you'
  },
  routing_url_org: {
    searchLinkTitleTerm: '-求人',
    searchLinklocTerm: '勤務地-',
    searchLinkTitle: '/:orgName/求人-検索/{jobTitle}-求人',
    searchLinkLocation: '/:orgName/求人-検索/勤務地-{location}',
    searchLinkInLocation: '/:orgName/求人-検索/{jobTitle}-求人-勤務地-{location}',
    errorPage:'/:orgName/アカウント/エラー/',
    successPage:'/:orgName/アカウント/提出完了/',
    successUpdateEmailPrefences:'/:orgName/アカウント/ご希望のメールアドレスに送信',
    confirmationPage: '/:orgName/partner/ps/success',
    officeLocatorResultPage:'/:orgName/お問い合わせ/最寄りのオフィス/result',
    officeLocatorPage:'/:orgName/お問い合わせ/最寄りのオフィス',
    speculativeCV:'/:orgName/アカウント/職務経歴書登録',
    myProfile:'/:orgName/マイアカウント/プロフィール',
    login:'/:orgName/アカウント/ログイン',
    register: '/:orgName/アカウント/登録',
    search:'/:orgName/求人-検索',
    secureSearch:'/:orgName/求人-検索',
    secureJobDetails:'/:orgName/求人-詳細',
    jobdetails:'/:orgName/求人-詳細',
    forgetPassword:'/:orgName/アカウント/ログイン/forgot-password',
    header: '/:orgName/JP/jp/header',
    footer: '/:orgName/JP/jp/footer',
    recordIdJobDetail: '/:orgName/JP/jp/jobportal/s/candidate/job/browse/details/',
    jobApply:'/:orgName/求人-詳細/:recordId/応募',
    secureJobApply:'/:orgName/求人-詳細/:recordId/応募',
    gdprThankyouPage:'/:orgName/マイアカウント/配信の設定確認',
    secureGdprThankyouPage:'/:orgName/マイアカウント/配信の設定確認',
    secureMarketingPrefPage:'/:orgName/マイアカウント/配信の設定',
    marketingPrefPage:'/:orgName/マイアカウント/配信の設定',
    resetPasswordPage:'/:orgName/アカウント/リセット',
    manageCV:'/:orgName/マイアカウント/職務経歴書の設定',
    secureThankYou:'/:orgName/求人-詳細/:recordId/求人応募確認',
    thankyou:'/:orgName/求人-詳細/:recordId/求人応募確認',
    saveWhatsappPage:'/:orgName/マイアカウント/whatsapp更新の確認',
    updateWhatsappPage:'/:orgName/マイアカウント/whatsapp登録',
    unsubscribePage:'/:orgName/JP/jp/jobportal/as/alert/submit/配信停止',
    alertThankYouPage:'/:orgName/マイアカウント/新着求人通知の設定確認',
    emailConfirmationPage:'/:orgName/JP/jp/jobportal/as/gdpr/submit/email-confirmation',
    recruitingNow:'/:orgName/アカウント/求人登録',
    secureRecruitingNow:'/:orgName/アカウント/求人登録',
    goLoginPage:'/:orgName/アカウント/partner/go1/training/ログイン',
    savedSearchesPage: '/:orgName/マイアカウント/新着求人通知',
    shortlistedJobsPage: '/:orgName/アカウント/保存した求人',
    changeEmailPage:'/:orgName/マイアカウント/メールアドレスの変更',
    emailConfirmation:'/:orgName/アカウント/メールアドレスの確認',
    updateYourDetailsUrl: '/:orgName/アカウント/登録情報の更新'
  }


};


