import { Component, OnDestroy, OnInit } from '@angular/core';
import { SiteService } from '../../../services/site/site.service';
import {ActivatedRoute, Router} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { config, environment, routingEndpoint, keywords } from '../../../../../environments/root/environment';
import { SetTitleService } from 'src/app/jobportal/service/set-title.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-success-pages',
  templateUrl: './success-pages.component.html',
  styleUrls: ['./success-pages.component.scss']
})
export class SuccessPagesComponent implements OnInit, OnDestroy {

  public query: any;
  public signInUrl: string;
  public emailData = '';
  public gdprEmail: string = '';
  public mailtoText: string = '';
  public loginUrl: string;
  SubscriptionList:Subscription[]=[];
  public isRegisterHeader : boolean = false;
  public jobsURL: any;
  public lifeRaybaseURL: any;
  public spec_cv_thank_you_text2 : any;
  constructor(private activateRoute: ActivatedRoute, private titleService:SetTitleService, private translate: TranslateService, private _siteService: SiteService, public route:Router) {
    this.translate.setDefaultLang(this._siteService.getSiteLocale());
    this.isRegisterHeader =environment[config.theme + this._siteService.getSiteLocale()]['config']['isRegisterHeader'];
    this.jobsURL =environment[config.theme + this._siteService.getSiteLocale()]['config']['jobsURL'];
    this.lifeRaybaseURL = environment[config.theme + this._siteService.getSiteLocale()]['liferaybaseUrl'];
    this.translate.getTranslation(this._siteService.getSiteLocale()).subscribe(response => {
      this.spec_cv_thank_you_text2 = response.spec_cv_thank_you_text2;
    })
  }

  ngOnInit() {
    let orgName = this.route.routerState.snapshot.root.firstChild.params["orgName"];
    let loginRoute ='';
    if(orgName)
      loginRoute = (environment[config.theme + this._siteService.getSiteLocale()][routingEndpoint.routing_url_org][routingEndpoint.login]).replace(':orgName', orgName);
    else
      loginRoute = environment[config.theme + this._siteService.getSiteLocale()][routingEndpoint.routing_url][routingEndpoint.login];

    this.loginUrl= window.location.origin + loginRoute;
    const activateRouteQueryParamsSubscription = this.activateRoute.queryParams.subscribe(params => {
      if (params) {
        this.query = params;
        if(this.query && this.query.submitCV==='Y'){
         const translateGetTranslationSubscription= this.translate.getTranslation(this._siteService.getSiteLocale()).subscribe((res)=>{
            if(res['submit_cv_sucess_page_title']){
              this.titleService.setTitleForPage(res['submit_cv_sucess_page_title']);
            }
          });
          this.SubscriptionList.push(translateGetTranslationSubscription);
        }
      
        if (this.query.emailConfirmation === 'Y' || this.query.emailSent === 'Y' ) {
          this.emailData = this.query.userEmailId;
          this.mailtoText = keywords.mailto;
          this.gdprEmail = this.query.customerService;
        }
        if (this.query.submitCV == 'Y' || this.query.emailConfirmation === 'Y' || this.query.emailSent === 'Y') {
          this.signInUrl = loginRoute;
        }
      }
    });
    this.SubscriptionList.push(activateRouteQueryParamsSubscription);
  }

  ngOnDestroy(): void {
    this.SubscriptionList.forEach(subscribe=>{
      if(subscribe){
        subscribe.unsubscribe();
    }
    });
  } 

}
