import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SiteService } from 'src/app/global/services/site/site.service';
import {config, environment} from 'src/environments/root/environment';

@Component({
  selector: 'app-jp-application-process-details',
  templateUrl: './jp-application-process-details.component.html',
  styleUrls: ['./jp-application-process-details.component.scss']
})
export class JpApplicationProcessDetailsComponent implements OnInit, OnDestroy {
  isOrgName: boolean;
  orgName: string;
  haysCareers = config.ExpertClientsName;
  activatedRouteQueryParamMapSubscription:Subscription;
  public isHungaryCountry: boolean = false;
  lifeRaybaseURL: any;
  officesnearmeURL: any;
  jobsURL: any;
  jobalertsURL: any;
  careeradvice: any;
  resumescoverletters: any;
  interviewtips: any;
  public isJobApplySuccess : boolean = false;
  constructor(private activatedRoute:ActivatedRoute, private router : Router, private siteService: SiteService) {
    this.isHungaryCountry = this.siteService.getDomain()== 'HU' ? true : false;
    this.lifeRaybaseURL = environment[config.theme + this.siteService.getSiteLocale()]['liferaybaseUrl'];
    this.officesnearmeURL =environment[config.theme + this.siteService.getSiteLocale()]['config']['officesnearmeURL'];
    this.jobsURL =environment[config.theme + this.siteService.getSiteLocale()]['config']['jobsURL'];
    this.jobalertsURL =environment[config.theme + this.siteService.getSiteLocale()]['config']['jobalertsURL'];
    this.careeradvice =environment[config.theme + this.siteService.getSiteLocale()]['config']['careeradvice'];
    this.resumescoverletters =environment[config.theme + this.siteService.getSiteLocale()]['config']['resumescoverletters'];
    this.interviewtips =environment[config.theme + this.siteService.getSiteLocale()]['config']['interviewtips'];
    this.isJobApplySuccess =environment[config.theme + this.siteService.getSiteLocale()]['config']['isJobApplySuccess'];
  }

  ngOnInit() {
    // this.isOrgName = (this.router.url.indexOf(config.HaysCareer) == 1)  ? true : false;
    this.activatedRouteQueryParamMapSubscription = this.activatedRoute.queryParamMap.subscribe(param=>{
      if(param.get('orgName'))
        this.orgName= param.get('orgName');
    });
  }
  ngOnDestroy(): void {
    if(this.activatedRouteQueryParamMapSubscription){
    this.activatedRouteQueryParamMapSubscription.unsubscribe();}
  }
}
