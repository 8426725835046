const lang_en_routes = {
    dropbox_link: 'DropBox',
    gdrive_link: 'GDrive',
    locale_job_search_link: ':lang/job-search',
    locale_org_job_search_link: ':lang/:orgName/job-search',
    search_link: ':searchTerm',
    locale_job_detail_link: ':lang/job-detail',
    locale_org_job_detail_link: ':lang/:orgName/job-detail',
    job_detail_link: ':recordId',
    job_apply_link: ':recordId/apply',
    job_apply_confirmation_link: ':recordId/job-success',
    locale_account_link: ':lang/account',
    locale_org_account_link: ':lang/:orgName/account',
    spec_cv_link: 'send-cv',
    register_link: 'register',
    success_link: 'cv-success',
    login_link: 'login',
    forgot_password_link: 'login/forgot-password',
    saved_jobs_link: 'saved-jobs',
    unsubscribe_link: 'unsubscribe',
    unsubscribe_email_link: 'unsubscribe-email',
    update_preferences_email_link: 'update-preferences-email',
    sent_preferences_email_link: 'sent-preferences-email',
    update_your_details_link: 'send-your-details',
    update_your_privacy_link: 'update-data-privacy',
    whatsapp_alert_link: 'update-whatsapp-number-email',
    reset_link: 'reset',
    error_link: 'error',
    locale_contact_link: ':lang/contact',
    locale_org_contact_link: ':lang/:orgName/contact',
    all_office_link: 'all-offices',
    office_locator_link: 'offices-near-me',
    office_locator_result_link: 'offices-near-me/result',
    locale_recruitment_link: ':lang/recruitment',
    locale_org_recruitment_link: ':lang/:orgName/recruitment',
    recruiting_now_link: 'recruit-now',
    locale_my_account_link: ':lang/my-account',
    locale_org_my_account_link: ':lang/:orgName/my-account',
    unsubscribe_email_confirmed_link: 'unsubscribe-email-confirmed',
    gdpr_marketing_pref_link: 'marketing-preferences',
    gdpr_marketing_pref_thankyou_link: 'marketing-preferences-confirmed',
    my_profile_link: 'profile',
    saved_searches_link: 'job-alerts',
    verify_alert_link: 'create-job-alert-email',
    job_alert_success_link: 'job-alert-success',
    delete_alert_link: 'delete-alert',
    manage_cv_link: 'manage-cv',
    change_email_link: 'change-email',
    change_password_link: 'change-password',
    update_whatsapp_link: 'update-whatsapp-number',
    save_whatsapp_link: 'whatsapp-number-confirmed',
    go1_link: 'partner/go1/training/login',
    view_email_link: 'viewemailink',
  gdpr_marketing_pref_contact_link: 'marketing-preferences-contact'
};
const en_routes = {
    dropbox_link: 'DropBox',
    gdrive_link: 'GDrive',
    locale_job_search_link: 'job-search',
    locale_org_job_search_link: ':orgName/job-search',
    search_link: ':searchTerm',
    locale_job_detail_link: 'job-detail',
    locale_org_job_detail_link: ':orgName/job-detail',
    job_detail_link: ':recordId',
    job_apply_link: ':recordId/apply',
    job_apply_confirmation_link: ':recordId/job-success',
    locale_account_link: 'account',
    locale_org_account_link: ':orgName/account',
    spec_cv_link: 'send-cv',
    register_link: 'register',
    success_link: 'cv-success',
    login_link: 'login',
    forgot_password_link: 'login/forgot-password',
    saved_jobs_link: 'saved-jobs',
    unsubscribe_link: 'unsubscribe',
    unsubscribe_email_link: 'unsubscribe-email',
    update_preferences_email_link: 'update-preferences-email',
    sent_preferences_email_link: 'sent-preferences-email',
    update_your_details_link: 'send-your-details',
    update_your_privacy_link: 'update-data-privacy',
    whatsapp_alert_link: 'update-whatsapp-number-email',
    reset_link: 'reset',
    error_link: 'error',
    locale_contact_link: 'contact',
    locale_org_contact_link: ':orgName/contact',
    all_office_link: 'all-offices',
    office_locator_link: 'offices-near-me',
    office_locator_result_link: 'offices-near-me/result',
    locale_recruitment_link: 'recruitment',
    locale_org_recruitment_link: ':orgName/recruitment',
    recruiting_now_link: 'recruit-now',
    locale_my_account_link: 'my-account',
    locale_org_my_account_link: ':orgName/my-account',
    unsubscribe_email_confirmed_link: 'unsubscribe-email-confirmed',
    gdpr_marketing_pref_link: 'marketing-preferences',
    gdpr_marketing_pref_thankyou_link: 'marketing-preferences-confirmed',
    my_profile_link: 'profile',
    saved_searches_link: 'job-alerts',
    verify_alert_link: 'create-job-alert-email',
    job_alert_success_link: 'job-alert-success',
    delete_alert_link: 'delete-alert',
    manage_cv_link: 'manage-cv',
    change_email_link: 'change-email',
    change_password_link: 'change-password',
    update_whatsapp_link: 'update-whatsapp-number',
    save_whatsapp_link: 'whatsapp-number-confirmed',
    go1_link: 'partner/go1/training/login',
    view_email_link: 'viewemailink',
  gdpr_marketing_pref_contact_link: 'marketing-preferences-contact'

};
export const ROUTES_PATHS = {
    orgName: ':orgName',
    en_GB: en_routes,
    en_IE: en_routes,
    en_AE: en_routes,
    en_US: {
        dropbox_link: 'DropBox',
        gdrive_link: 'GDrive',
        locale_job_search_link: 'job-search',
        locale_org_job_search_link: ':orgName/job-search',
        search_link: ':searchTerm',
        locale_job_detail_link: 'job-detail',
        locale_org_job_detail_link: ':orgName/job-detail',
        job_detail_link: ':recordId',
        job_apply_link: ':recordId/apply',
        job_apply_confirmation_link: ':recordId/job-apply-confirmation',
        locale_account_link: 'account',
        locale_org_account_link: ':orgName/account',
        spec_cv_link: 'submit-resume',
        register_link: 'register',
        success_link: 'resume-confirmation',
        login_link: 'login',
        forgot_password_link: 'login/forgot-password',
        saved_jobs_link: 'saved-jobs',
        unsubscribe_link: 'unsubscribe',
        unsubscribe_email_link: 'unsubscribe-email',
        update_preferences_email_link: 'update-marketing-preferences-email',
        sent_preferences_email_link: 'sent-marketing-preferences-email',
        update_your_details_link: 'update-your-details',
        update_your_privacy_link: 'update-data-privacy',
        whatsapp_alert_link: 'update-whatsapp-number',
        reset_link: 'reset',
        error_link: 'error',
        locale_contact_link: 'contact',
        locale_org_contact_link: ':orgName/contact',
        all_office_link: 'all-offices',
        office_locator_link: 'offices-near-me',
        office_locator_result_link: 'offices-near-me/result',
        locale_recruitment_link: 'recruitment',
        locale_org_recruitment_link: ':orgName/recruitment',
        recruiting_now_link: 'register-job',
        locale_my_account_link: 'my-account',
        locale_org_my_account_link: ':orgName/my-account',
        unsubscribe_email_confirmed_link: 'unsubscribe-email-confirmation',
        gdpr_marketing_pref_link: 'marketing-preferences',
        gdpr_marketing_pref_thankyou_link: 'marketing-preferences-confirmation',
        my_profile_link: 'profile',
        saved_searches_link: 'job-alerts',
        verify_alert_link: 'create-job-alert-email',
        job_alert_success_link: 'job-alert-confirmation',
        delete_alert_link: 'delete-job-alert',
        manage_cv_link: 'manage-resume',
        change_email_link: 'change-email',
        change_password_link: 'change-password',
        update_whatsapp_link: 'update-whatsapp',
        save_whatsapp_link: 'whatsapp-number-confirmation',
        go1_link: 'partner/go1/training/login',
        view_email_link: 'viewemailink',
      gdpr_marketing_pref_contact_link: 'marketing-preferences-contact'
    },
    eb_BEL: lang_en_routes,
    bf_BEL: {
        dropbox_link: 'DropBox',
        gdrive_link: 'GDrive',
        locale_job_search_link: 'recherche-emploi',
        locale_org_job_search_link: ':orgName/recherche-emploi',
        search_link: ':searchTerm',
        locale_job_detail_link: 'description-emploi',
        locale_org_job_detail_link: ':orgName/description-emploi',
        job_detail_link: ':recordId',
        job_apply_link: ':recordId/candidature',
        job_apply_confirmation_link: ':recordId/validation-candidature',
        locale_account_link: 'compte',
        locale_org_account_link: ':orgName/compte',
        spec_cv_link: 'envoi-cv',
        register_link: 'inscription',
        success_link: 'validation-envoi-cv',
        login_link: 'connexion',
        forgot_password_link: 'connexion/forgot-password',
        saved_jobs_link: 'emplois-favoris',
        unsubscribe_link: 'desinscription',
        unsubscribe_email_link: 'desinscription-email',
        update_preferences_email_link: 'mise-a-jour-preferences-email',
        sent_preferences_email_link: 'envoi-preferences-email',
        update_your_details_link: 'mise-a-jour-informations',
        update_your_privacy_link: 'mise-a-jour-donnees-personnelles',
        whatsapp_alert_link: 'mise-a-jour-alerte-whatsapp',
        reset_link: 'reinitialiser',
        error_link: 'erreur',
        locale_contact_link: 'contacter',
        locale_org_contact_link: ':orgName/contacter',
        all_office_link: 'nos-bureaux',
        office_locator_link: 'bureaux-pres-de-moi',
        office_locator_result_link: 'bureaux-pres-de-moi/result',
        locale_recruitment_link: 'recrutement',
        locale_org_recruitment_link: ':orgName/recrutement',
        recruiting_now_link: 'vous-recrutez',
        locale_my_account_link: 'mon-compte',
        locale_org_my_account_link: ':orgName/mon-compte',
        unsubscribe_email_confirmed_link: 'confirmation-desinscription-email',
        gdpr_marketing_pref_link: 'preferences-marketing',
        gdpr_marketing_pref_thankyou_link: 'confirmation-preferences-marketing',
        my_profile_link: 'profil',
        saved_searches_link: 'alertes-emploi',
        verify_alert_link: 'creation-alerte-emploi',
        job_alert_success_link: 'validation-alerte-emploi',
        delete_alert_link: 'suppression-alerte-emploi',
        manage_cv_link: 'gestion-cv',
        change_email_link: 'modification-email',
        change_password_link: 'changement-mot-de-passe',
        update_whatsapp_link: 'mise-a-jour-numero-whatsapp',
        save_whatsapp_link: 'confirmation-mise-a-jour-whatsapp-alerte',
        go1_link: 'partner/go1/training/connexion',
        view_email_link: 'viewemailink',
      gdpr_marketing_pref_contact_link: 'marketing-preferences-contact'
    },
    bn_BEL: {
        dropbox_link: 'DropBox',
        gdrive_link: 'GDrive',
        locale_job_search_link: ':lang/vacatures-zoeken',
        locale_org_job_search_link: ':lang/:orgName/vacatures-zoeken',
        search_link: ':searchTerm',
        locale_job_detail_link: ':lang/vacature-details',
        locale_org_job_detail_link: ':lang/:orgName/vacature-details',
        job_detail_link: ':recordId',
        job_apply_link: ':recordId/solliciteren',
        job_apply_confirmation_link: ':recordId/sollicitatie-bevestiging',
        locale_account_link: ':lang/account',
        locale_org_account_link: ':lang/:orgName/account',
        spec_cv_link: 'verstuur-cv',
        register_link: 'aanmelden',
        success_link: 'cv-succesvol-verstuurd',
        login_link: 'login',
        forgot_password_link: 'login/forgot-password',
        saved_jobs_link: 'bewaarde-vacatures',
        unsubscribe_link: 'uitschrijven',
        unsubscribe_email_link: 'e-mail-uitschrijven',
        update_preferences_email_link: 'voorkeuren-aanpassen-e-mail',
        sent_preferences_email_link: 'e-mail-voorkeuren-verstuurd',
        update_your_details_link: 'stuur-je-gegevens',
        update_your_privacy_link: 'update-privacyinstellingen',
        whatsapp_alert_link: 'wijzig-mobiele-nummer-e-mail',
        reset_link: 'reset-wachtwoord',
        error_link: 'pagina-niet-gevonden',
        locale_contact_link: ':lang/contact',
        locale_org_contact_link: ':lang/:orgName/contact',
        all_office_link: 'alle-kantoren',
        office_locator_link: 'kantoren-bij-mij-in-de-buurt',
        office_locator_result_link: 'kantoren-bij-mij-in-de-buurt/result',
        locale_recruitment_link: ':lang/rekrutering',
        locale_org_recruitment_link: ':lang/:orgName/rekrutering',
        recruiting_now_link: 'vacature-aanmelden',
        locale_my_account_link: ':lang/mijn-account',
        locale_org_my_account_link: ':lang/:orgName/mijn-account',
        unsubscribe_email_confirmed_link: 'e-mail-uitschrijven-bevestiging',
        gdpr_marketing_pref_link: 'marketingvoorkeuren',
        gdpr_marketing_pref_thankyou_link: 'marketingvoorkeuren-bevestiging',
        my_profile_link: 'profiel',
        saved_searches_link: 'job-alerts',
        verify_alert_link: 'creeer-job-alert-e-mail',
        job_alert_success_link: 'bevestiging-job-alert',
        delete_alert_link: 'verwijder-job-alert',
        manage_cv_link: 'mijn-cvs',
        change_email_link: 'wijzig-e-mailadres',
        change_password_link: 'wijzig-wachtwoord',
        update_whatsapp_link: 'wijzig-mobiele-nummer',
        save_whatsapp_link: 'mobiele-nummer-bevestiging',
        go1_link: 'partner/go1/training/login',
        view_email_link: 'viewemailink',
      gdpr_marketing_pref_contact_link: 'marketing-preferences-contact'
    },
    en_NL: lang_en_routes,
    nl_NL: {
    dropbox_link: 'DropBox',
    gdrive_link: 'GDrive',
    locale_job_search_link: 'vacatures-zoeken',
    locale_org_job_search_link: ':orgName/vacatures-zoeken',
    search_link: ':searchTerm',
    locale_job_detail_link: 'vacature-details',
    locale_org_job_detail_link: ':orgName/vacature-details',
    job_detail_link: ':recordId',
    job_apply_link: ':recordId/solliciteren',
    job_apply_confirmation_link: ':recordId/sollicitatie-bevestiging',
    locale_account_link: 'account',
    locale_org_account_link: ':orgName/account',
    spec_cv_link: 'verstuur-cv',
    register_link: 'aanmelden',
    success_link: 'cv-succesvol-verstuurd',
    login_link: 'login',
    forgot_password_link: 'login/forgot-password',
    saved_jobs_link: 'bewaarde-vacatures',
    unsubscribe_link: 'uitschrijven',
    unsubscribe_email_link: 'e-mail-uitschrijven',
    update_preferences_email_link: 'voorkeuren-aanpassen-e-mail',
    sent_preferences_email_link: 'e-mail-voorkeuren-verstuurd',
    update_your_details_link: 'stuur-je-gegevens',
    update_your_privacy_link: 'update-privacyinstellingen',
    whatsapp_alert_link: 'wijzig-mobiele-nummer-e-mail',
    reset_link: 'reset-wachtwoord',
    error_link: 'pagina-niet-gevonden',
    locale_contact_link: 'contact',
    locale_org_contact_link: ':orgName/contact',
    all_office_link: 'alle-kantoren',
    office_locator_link: 'kantoren-bij-mij-in-de-buurt',
    office_locator_result_link: 'kantoren-bij-mij-in-de-buurt/result',
    locale_recruitment_link: 'recruitment',
    locale_org_recruitment_link: ':orgName/recruitment',
    recruiting_now_link: 'vacature-aanmelden',
    locale_my_account_link: 'mijn-account',
    locale_org_my_account_link: ':orgName/mijn-account',
    unsubscribe_email_confirmed_link: 'e-mail-uitschrijven-bevestiging',
    gdpr_marketing_pref_link: 'marketingvoorkeuren',
    gdpr_marketing_pref_thankyou_link: 'marketingvoorkeuren-bevestiging',
    my_profile_link: 'profiel',
    saved_searches_link: 'job-alerts',
    verify_alert_link: 'creeer-job-alert-e-mail',
    job_alert_success_link: 'bevestiging-job-alert',
    delete_alert_link: 'verwijder-job-alert',
    manage_cv_link: 'mijn-cvs',
    change_email_link: 'wijzig-e-mailadres',
    change_password_link: 'wijzig-wachtwoord',
    update_whatsapp_link: 'wijzig-mobiele-nummer',
    save_whatsapp_link: 'mobiele-nummer-bevestiging',
    go1_link: 'partner/go1/training/login',
    view_email_link: 'viewemailink',
      gdpr_marketing_pref_contact_link: 'marketing-preferences-contact'
    },
    en_PL: lang_en_routes,
    pl_PL: {
        dropbox_link: 'DropBox',
        gdrive_link: 'GDrive',
        locale_job_search_link: 'znajdz-prace',
        locale_org_job_search_link: ':orgName/znajdz-prace',
        search_link: ':searchTerm',
        locale_job_detail_link: 'oferta-pracy-opis',
        locale_org_job_detail_link: ':orgName/oferta-pracy-opisl',
        job_detail_link: ':recordId',
        job_apply_link: ':recordId/aplikuj',
        job_apply_confirmation_link: ':recordId/oferta-pracy/aplikacja/potwierdzenie',
        locale_account_link: 'konto',
        locale_org_account_link: ':orgName/konto',
        spec_cv_link: 'spec-CV',
        register_link: 'rejestracja',
        success_link: 'potwierdzenie-spec-CV',
        login_link: 'zaloguj',
        forgot_password_link: 'zaloguj/forgot-password',
        saved_jobs_link: 'zapisane-oferty',
        unsubscribe_link: 'zrezygnuj-z-subskrypcji',
        unsubscribe_email_link: 'rezygnacja-z-subskrypcji',
        update_preferences_email_link: 'zmiana-preferencji-marketingowych',
        sent_preferences_email_link: 'potwierdzenie-preferencji-marketingowych',
        update_your_details_link: 'aktualizacja-danych',
        update_your_privacy_link: 'aktualizacja-preferencji',
        whatsapp_alert_link: 'whatsapp-zmiana-numeru',
        reset_link: 'zmiana-hasla',
        error_link: 'blad',
        locale_contact_link: 'kontakt',
        locale_org_contact_link: ':orgName/kontakt',
        all_office_link: 'biura',
        office_locator_link: 'najblizsze-biuro',
        office_locator_result_link: 'najblizsze-biuro/result',
        locale_recruitment_link: 'uslugi',
        locale_org_recruitment_link: ':orgName/uslugi',
        recruiting_now_link: 'zlec-rekrutacje',
        locale_my_account_link: 'moje-konto',
        locale_org_my_account_link: ':orgName/moje-konto',
        unsubscribe_email_confirmed_link: 'potwierdzenie-rezygnacji-z-subskrypcji',
        gdpr_marketing_pref_link: 'preferencje-marketingowe',
        gdpr_marketing_pref_thankyou_link: 'preferencje-marketingowe/ustawienia',
        my_profile_link: 'profil',
        saved_searches_link: 'zapisane-wyszukiwania',
        verify_alert_link: 'oferty-pracy/utworzenie-alertu',
        job_alert_success_link: 'oferty-pracy/potwierdzenie-alertu',
        delete_alert_link: 'oferty-pracy/usuniecie-alertu',
        manage_cv_link: 'moje-CV',
        change_email_link: 'zmiana-email',
        change_password_link: 'zmiana-hasla',
        update_whatsapp_link: 'whatsapp',
        save_whatsapp_link: 'whatsapp-potwierdzenie-zmiany-numeru',
        go1_link: 'partner/go1/training/zaloguj',
        view_email_link: 'viewemailink',
        gdpr_marketing_pref_contact_link: 'preferencje-marketingowe-kontakt'
    },
    es_ES: {
        dropbox_link: 'DropBox',
        gdrive_link: 'GDrive',
        locale_job_search_link: 'busqueda-empleo',
        locale_org_job_search_link: ':orgName/busqueda-empleo',
        search_link: ':searchTerm',
        locale_job_detail_link: 'detalles-vacante',
        locale_org_job_detail_link: ':orgName/detalles-vacante',
        job_detail_link: ':recordId',
        job_apply_link: ':recordId/candidatura',
        job_apply_confirmation_link: ':recordId/empleo-confirmacion',
        locale_account_link: 'cuenta',
        locale_org_account_link: ':orgName/cuenta',
        spec_cv_link: 'enviar-cv',
        register_link: 'registro',
        success_link: 'confirmacion-cv',
        login_link: 'inicio-sesion',
        forgot_password_link: 'inicio-sesion/forgot-password',
        saved_jobs_link: 'empleos-guardados',
        unsubscribe_link: 'cancelar-suscripcion',
        unsubscribe_email_link: 'cancelar-suscripcion-email',
        update_preferences_email_link: 'actualiza-preferencias-email',
        sent_preferences_email_link: 'envio-preferencias-email',
        update_your_details_link: 'envia-tus-detalles',
        update_your_privacy_link: 'actualiza-privacidad-datos',
        whatsapp_alert_link: 'actualizar-numero-whatsapp-email',
        reset_link: 'reiniciar',
        error_link: 'error',
        locale_contact_link: 'contacto',
        locale_org_contact_link: ':orgName/contacto',
        all_office_link: 'todas-las-oficinas',
        office_locator_link: 'oficinas-cerca-de-mi',
        office_locator_result_link: 'oficinas-cerca-de-mi/result',
        locale_recruitment_link: 'contratacion',
        locale_org_recruitment_link: ':orgName/contratacion',
        recruiting_now_link: 'registrar-vacante',
        locale_my_account_link: 'mi-cuenta',
        locale_org_my_account_link: ':orgName/mi-cuenta',
        unsubscribe_email_confirmed_link: 'baja-email-confirmado',
        gdpr_marketing_pref_link: 'preferencias-marketing',
        gdpr_marketing_pref_thankyou_link: 'preferencias-marketing-confirmado',
        my_profile_link: 'perfil',
        saved_searches_link: 'alertas-empleo',
        verify_alert_link: 'crear-alerta-empleo-email',
        job_alert_success_link: 'alerta-empleo-completada',
        delete_alert_link: 'eliminar-alerta',
        manage_cv_link: 'gestionar-cv',
        change_email_link: 'cambiar-email',
        change_password_link: 'cambiar-contraseña',
        update_whatsapp_link: 'actualizar-numero-whatsapp',
        save_whatsapp_link: 'umero-whatsapp-confirmado',
        go1_link: 'partner/go1/training/inicio-sesion',
        view_email_link: 'viewemailink',
      gdpr_marketing_pref_contact_link: 'marketing-preferences-contact'
    },
    en_CZ: lang_en_routes,
    cs_CZ: {
        dropbox_link: 'DropBox',
        gdrive_link: 'GDrive',
        locale_job_search_link: 'hledat-praci',
        locale_org_job_search_link: ':orgName/hledat-praci',
        search_link: ':searchTerm',
        locale_job_detail_link: 'detail-nabidky',
        locale_org_job_detail_link: ':orgName/detail-nabidky',
        job_detail_link: ':recordId',
        job_apply_link: ':recordId/odpovedet',
        job_apply_confirmation_link: ':recordId/uspesna-reakce-na-pozici',
        locale_account_link: 'ucet',
        locale_org_account_link: ':orgName/ucet',
        spec_cv_link: 'zaslat-CV',
        register_link: 'registrace',
        success_link: 'CV-zaslano',
        login_link: 'prihlaseni',
        forgot_password_link: 'prihlaseni/forgot-password',
        saved_jobs_link: 'ulozene-pozice',
        unsubscribe_link: 'odhlaseni',
        unsubscribe_email_link: 'odhlaseni-emailu',
        update_preferences_email_link: 'aktualizace-preferenci-email',
        sent_preferences_email_link: 'preference-odeslany-email',
        update_your_details_link: 'zaslete-sve-informace',
        update_your_privacy_link: 'aktualizace-soukromi',
        whatsapp_alert_link: 'whatsApp/aktualizace-cisla-email',
        reset_link: 'reset',
        error_link: 'chyba',
        locale_contact_link: 'kontakty',
        locale_org_contact_link: ':orgName/kontakty',
        all_office_link: 'pobocky',
        office_locator_link: 'pobocky-pobliz',
        office_locator_result_link: 'pobocky-pobliz/result',
        locale_recruitment_link: 'nabor',
        locale_org_recruitment_link: ':orgName/nabor',
        recruiting_now_link: 'hledate-zamestnance',
        locale_my_account_link: 'muj-ucet',
        locale_org_my_account_link: ':orgName/muj-ucet',
        unsubscribe_email_confirmed_link: 'potvrzeni-odhlaseni-emailu',
        gdpr_marketing_pref_link: 'marketingove-preference',
        gdpr_marketing_pref_thankyou_link: 'marketingove-preference-potvrzeni',
        my_profile_link: 'profil',
        saved_searches_link: 'upozorneni-na-pozice',
        verify_alert_link: 'vytvorit-upozorneni-na-pozice',
        job_alert_success_link: 'upozorneni-na-pozice-nastaveno',
        delete_alert_link: 'vymazat-upozornení',
        manage_cv_link: 'sprava-CV',
        change_email_link: 'zmena-emailu',
        change_password_link: 'zmena-hesla',
        update_whatsapp_link: 'aktualizace-whatsapp-cisla',
        save_whatsapp_link: 'whatsapp-cislo-potvrzeni',
        go1_link: 'partner/go1/training/prihlaseni',
        view_email_link: 'viewemailink',
      gdpr_marketing_pref_contact_link: 'marketing-preferences-contact'
    },
    en_HU: lang_en_routes,
    hu_HU: {
        dropbox_link: 'DropBox',
        gdrive_link: 'GDrive',
        locale_job_search_link: 'allas-kereses',
        locale_org_job_search_link: ':orgName/allas-kereses',
        search_link: ':searchTerm',
        locale_job_detail_link: 'pozicio-leiras',
        locale_org_job_detail_link: ':orgName/pozicio-leiras',
        job_detail_link: ':recordId',
        job_apply_link: ':recordId/jelentkezes',
        job_apply_confirmation_link: ':recordId/allas-kereses/jelentkezes/megerosites',
        locale_account_link: 'fiok',
        locale_org_account_link: ':orgName/fiok',
        spec_cv_link: 'oneletrajz-bekuldes',
        register_link: 'regisztracio',
        success_link: 'oneletrajz-bekuldes/megerosites',
        login_link: 'bejelentkezes',
        forgot_password_link: 'bejelentkezes/forgot-password',
        saved_jobs_link: 'mentett-allasok',
        unsubscribe_link: 'leiratkozas',
        unsubscribe_email_link: 'hirlevel-leiratkozas',
        update_preferences_email_link: 'marketing-preferenciak-frissitese',
        sent_preferences_email_link: 'marketing-preferenciak-elkuldve',
        update_your_details_link: 'adataim-frissitese',
        update_your_privacy_link: 'adatvedelmi-beallitasok-frissitese',
        whatsapp_alert_link: 'whatsapp/frissites-igenylese',
        reset_link: 'jelszo-frissites',
        error_link: 'hiba',
        locale_contact_link: 'kapcsolat',
        locale_org_contact_link: ':orgName/kapcsolat',
        all_office_link: 'iroda',
        office_locator_link: 'iroda-kereso',
        office_locator_result_link: 'iroda-kereso/result',
        locale_recruitment_link: 'szolgaltatasok',
        locale_org_recruitment_link: ':orgName/szolgaltatasok',
        recruiting_now_link: 'toborzas',
        locale_my_account_link: 'fiokom',
        locale_org_my_account_link: ':orgName/fiokom',
        unsubscribe_email_confirmed_link: 'hirlevel-leiratkozas/megerosites',
        gdpr_marketing_pref_link: 'marketing-preferenciak',
        gdpr_marketing_pref_thankyou_link: 'marketing-preferenciak/megerosites',
        my_profile_link: 'profil',
        saved_searches_link: 'allas-ertesites',
        verify_alert_link: 'allas-ertesites/letrehozas',
        job_alert_success_link: 'allas-ertesites/megerosites',
        delete_alert_link: 'allas-ertesites/torles',
        manage_cv_link: 'oneletrajz',
        change_email_link: 'email-megvaltoztatasa',
        change_password_link: 'jelszo-frissites',
        update_whatsapp_link: 'whatsapp/frissites',
        save_whatsapp_link: 'whatsapp/frissites/megerosites',
        go1_link: 'partner/go1/training/bejelentkezes',
        view_email_link: 'viewemailink',
      gdpr_marketing_pref_contact_link: 'marketing-preferences-contact'
    },
    en_RO: lang_en_routes,
    ro_RO: {
        dropbox_link: 'DropBox',
        gdrive_link: 'GDrive',
        locale_job_search_link: 'locuri-de-munca-cautare',
        locale_org_job_search_link: ':orgName/locuri-de-munca-cautare',
        search_link: ':searchTerm',
        locale_job_detail_link: 'detalii-loc-de-munca',
        locale_org_job_detail_link: ':orgName/detalii-loc-de-munca',
        job_detail_link: ':recordId',
        job_apply_link: ':recordId/candidatura',
        job_apply_confirmation_link: ':recordId/locuri-de-munca/confirmare-candidatura',
        locale_account_link: 'cont',
        locale_org_account_link: ':orgName/cont',
        spec_cv_link: 'trimitere-CV',
        register_link: 'inregistrare',
        success_link: 'confirmare-trimitere-CV',
        login_link: 'conectare',
        forgot_password_link: 'conectare/forgot-password',
        saved_jobs_link: 'locuri-de-munca-salvate',
        unsubscribe_link: 'dezabonare',
        unsubscribe_email_link: 'dezabonare-adresa-de-email',
        update_preferences_email_link: 'actualizare-preferinte-email',
        sent_preferences_email_link: 'preferinte-trimise',
        update_your_details_link: 'trimite-ne-detaliile-tale',
        update_your_privacy_link: 'actualizare-politica-de-confidentialitate',
        whatsapp_alert_link: 'actualizati-numarul-de-whatsapp-email',
        reset_link: 'resetare',
        error_link: 'eroare',
        locale_contact_link: 'contact',
        locale_org_contact_link: ':orgName/contact',
        all_office_link: 'birou-Hays',
        office_locator_link: 'offices-near-me',
        office_locator_result_link: 'offices-near-me/result',
        locale_recruitment_link: 'recrutare',
        locale_org_recruitment_link: ':orgName/recrutare',
        recruiting_now_link: 'recrutati-acum',
        locale_my_account_link: 'contul-meu',
        locale_org_my_account_link: ':orgName/contul-meu',
        unsubscribe_email_confirmed_link: 'confirmare- dezabonare-adresa-de-email',
        gdpr_marketing_pref_link: 'preferinte-de-marketing',
        gdpr_marketing_pref_thankyou_link: 'preferinte-de-marketing-confirmate',
        my_profile_link: 'profil',
        saved_searches_link: 'alerte-de-locuri-de-munca',
        verify_alert_link: 'activare-alerte-de-locuri-de-munca',
        job_alert_success_link: 'activare-alerte-de-locuri-de-munca-finalizata',
        delete_alert_link: 'dezactivare-alerta',
        manage_cv_link: 'gestionare-cv',
        change_email_link: 'schimbare-adresa-de-e-mail',
        change_password_link: 'schimbare-parola',
        update_whatsapp_link: 'actualizati-numarul-de-whatsapp',
        save_whatsapp_link: 'numarul-de-whatsapp-confirmat',
        go1_link: 'partner/go1/training/login',
        view_email_link: 'viewemailink',
      gdpr_marketing_pref_contact_link: 'marketing-preferences-contact'
    },
    en_SE: lang_en_routes,
    sv_SE: {
        dropbox_link: 'DropBox',
        gdrive_link: 'GDrive',
        locale_job_search_link: 'jobb-sokning',
        locale_org_job_search_link: ':orgName/jobb-sokning',
        search_link: ':searchTerm',
        locale_job_detail_link: 'jobb-information',
        locale_org_job_detail_link: ':orgName/jobb-information',
        job_detail_link: ':recordId',
        job_apply_link: ':recordId/sok',
        job_apply_confirmation_link: ':recordId/jobb-succe',
        locale_account_link: 'konto',
        locale_org_account_link: ':orgName/konto',
        spec_cv_link: 'skicka-cv',
        register_link: 'registrera',
        success_link: 'cv-succe',
        login_link: 'logga-in',
        forgot_password_link: 'logga-in/forgot-password',
        saved_jobs_link: 'sparade-jobb',
        unsubscribe_link: 'avregistrera',
        unsubscribe_email_link: 'avregistrera-epost',
        update_preferences_email_link: 'uppdatera-installningar-epost',
        sent_preferences_email_link: 'skickat-installningar-epost',
        update_your_details_link: 'skicka-din-info',
        update_your_privacy_link: 'uppdatera-data-sekretess',
        whatsapp_alert_link: 'uppdatera-whatsapp-nummer-epost',
        reset_link: 'aterstall',
        error_link: 'fel',
        locale_contact_link: 'kontakt',
        locale_org_contact_link: ':orgName/kontakt',
        all_office_link: 'alla-kontor',
        office_locator_link: 'kontor-nara-mig',
        office_locator_result_link: 'kontor-nara-mig/result',
        locale_recruitment_link: 'rekrytering',
        locale_org_recruitment_link: ':orgName/rekrytering',
        recruiting_now_link: 'rekrytera-nu',
        locale_my_account_link: 'mitt-konto',
        locale_org_my_account_link: ':orgName/mitt-konto',
        unsubscribe_email_confirmed_link: 'avregistrera-epost-bekraftad',
        gdpr_marketing_pref_link: 'marknadsforing-installningar',
        gdpr_marketing_pref_thankyou_link: 'marknadsforing-installningar-bekraftad',
        my_profile_link: 'profil',
        saved_searches_link: 'jobb-meddelanden',
        verify_alert_link: 'skapa-jobb-meddelande-epost',
        job_alert_success_link: 'jobb-meddelanden-succe',
        delete_alert_link: 'radera-meddelande',
        manage_cv_link: 'hantera-cv',
        change_email_link: 'andra-epost',
        change_password_link: 'andra-losenord',
        update_whatsapp_link: 'uppdatera-whatsapp-nummer',
        save_whatsapp_link: 'whatsapp-nummer-bekraftad',
        go1_link: 'partner/go1/training/logga-in',
        view_email_link: 'viewemailink',
      gdpr_marketing_pref_contact_link: 'marketing-preferences-contact'
    },
    it_ITL: {
    dropbox_link: 'DropBox',
    gdrive_link: 'GDrive',
    locale_job_search_link: 'ricerca-offerte',
    locale_org_job_search_link: ':orgName/ricerca-offerte',
    search_link: ':searchTerm',
    locale_job_detail_link: 'lavoro-dettagli',
    locale_org_job_detail_link: ':orgName/lavoro-dettagli',
    job_detail_link: ':recordId',
    job_apply_link: ':recordId/candidatura',
    job_apply_confirmation_link: ':recordId/lavoro-conferma',
    locale_account_link: 'account',
    locale_org_account_link: ':orgName/account',
    spec_cv_link: 'invia-cv',
    register_link: 'iscrizione',
    success_link: 'cv-success',
    login_link: 'login',
    forgot_password_link: 'login/forgot-password',
    saved_jobs_link: 'offerte-salvate',
    unsubscribe_link: 'disiscrizione',
    unsubscribe_email_link: 'disiscrizione/email',
    update_preferences_email_link: 'aggiorna-preferenze-email',
    sent_preferences_email_link: 'invio-preferenze-email',
    update_your_details_link: 'invio-informazioni',
    update_your_privacy_link: 'aggiorna-dati-privacy',
    whatsapp_alert_link: 'aggiorna-numero-whatsapp-email',
    reset_link: 'reset',
    error_link: 'errore',
    locale_contact_link: 'contatti',
    locale_org_contact_link: ':orgName/contatti',
    all_office_link: 'uffici',
    office_locator_link: 'uffici-vicino-a-me',
    office_locator_result_link: 'uffici-vicino-a-me/result',
    locale_recruitment_link: 'recruitment',
    locale_org_recruitment_link: ':orgName/recruitment',
    recruiting_now_link: 'ricerca-selezione-personale',
    locale_my_account_link: 'my-account',
    locale_org_my_account_link: ':orgName/my-account',
    unsubscribe_email_confirmed_link: 'disiscrizione-email-conferma',
    gdpr_marketing_pref_link: 'preferenze-marketing',
    gdpr_marketing_pref_thankyou_link: 'preferenze-marketing-conferma',
    my_profile_link: 'profilo',
    saved_searches_link: 'job-alerts',
    verify_alert_link: 'crea-job-alert-email',
    job_alert_success_link: 'job-alert-conferma',
    delete_alert_link: 'cancella-alert',
    manage_cv_link: 'gestisci-cv',
    change_email_link: 'cambio-email',
    change_password_link: 'cambio-password',
    update_whatsapp_link: 'aggiorna-numero-whatsapp',
    save_whatsapp_link: 'conferma-numero-whatsapp',
    go1_link: 'partner/go1/training/login',
    view_email_link: 'viewemailink',
      gdpr_marketing_pref_contact_link: 'marketing-preferences-contact'
    },
    en_LU: lang_en_routes,
    fr_LU: {
        dropbox_link: 'DropBox',
        gdrive_link: 'GDrive',
        locale_job_search_link: 'recherche-emploi',
        locale_org_job_search_link: ':orgName/recherche-emploi',
        search_link: ':searchTerm',
        locale_job_detail_link: 'description-emploi',
        locale_org_job_detail_link: ':orgName/description-emploi',
        job_detail_link: ':recordId',
        job_apply_link: ':recordId/candidature',
        job_apply_confirmation_link: ':recordId/validation-candidature',
        locale_account_link: 'compte',
        locale_org_account_link: ':orgName/compte',
        spec_cv_link: 'envoi-cv',
        register_link: 'inscription',
        success_link: 'validation-envoi-cv',
        login_link: 'connexion',
        forgot_password_link: 'connexion/forgot-password',
        saved_jobs_link: 'emplois-favoris',
        unsubscribe_link: 'desinscription',
        unsubscribe_email_link: 'desinscription-email',
        update_preferences_email_link: 'mise-a-jour-preferences-email',
        sent_preferences_email_link: 'envoi-preferences-email',
        update_your_details_link: 'mise-a-jour-informations',
        update_your_privacy_link: 'mise-a-jour-donnees-personnelles',
        whatsapp_alert_link: 'mise-a-jour-alerte-whatsapp',
        reset_link: 'reinitialiser',
        error_link: 'erreur',
        locale_contact_link: 'contacter',
        locale_org_contact_link: ':orgName/contacter',
        all_office_link: 'nos-bureaux',
        office_locator_link: 'bureaux-pres-de-moi',
        office_locator_result_link: 'bureaux-pres-de-moi/result',
        locale_recruitment_link: 'recrutement',
        locale_org_recruitment_link: ':orgName/recrutement',
        recruiting_now_link: 'vous-recrutez',
        locale_my_account_link: 'mon-compte',
        locale_org_my_account_link: ':orgName/mon-compte',
        unsubscribe_email_confirmed_link: 'confirmation-desinscription-email',
        gdpr_marketing_pref_link: 'preferences-marketing',
        gdpr_marketing_pref_thankyou_link: 'confirmation-preferences-marketing',
        my_profile_link: 'profil',
        saved_searches_link: 'alertes-emploi',
        verify_alert_link: 'creation-alerte-emploi',
        job_alert_success_link: 'validation-alerte-emploi',
        delete_alert_link: 'suppression-alerte-emploi',
        manage_cv_link: 'gestion-cv',
        change_email_link: 'modification-email',
        change_password_link: 'changement-mot-de-passe',
        update_whatsapp_link: 'mise-a-jour-numero-whatsapp',
        save_whatsapp_link: 'confirmation-mise-a-jour-whatsapp-alerte',
        go1_link: 'partner/go1/training/login',
        view_email_link: 'viewemailink',
      gdpr_marketing_pref_contact_link: 'marketing-preferences-contact'
    },
    fr_FR: {
        dropbox_link: 'DropBox',
        gdrive_link: 'GDrive',
        locale_job_search_link:'recherche-emploi',
        locale_org_job_search_link:':orgName/recherche-emploi',
        search_link: ':searchTerm',
        locale_job_detail_link:'description-emploi',
        locale_org_job_detail_link:':orgName/description-emploi',
        job_detail_link: ':recordId',
        job_apply_link: ':recordId/candidature',
        job_apply_confirmation_link: ':recordId/validation-candidature',
        locale_account_link:'compte',
        locale_org_account_link:':orgName/compte',
        spec_cv_link: 'envoi-cv',
        register_link: 'inscription',
        success_link: 'validation-envoi-cv',
        login_link: 'connexion',
        forgot_password_link: 'connexion/forgot-password',
        saved_jobs_link: 'emplois-favoris',
        unsubscribe_link:'desinscription',
        unsubscribe_email_link:  'desinscription-email',
        update_preferences_email_link: 'mise-a-jour-preferences-email',
        sent_preferences_email_link : 'envoi-preferences-email',
        update_your_details_link : 'mise-a-jour-informations',
        update_your_privacy_link : 'mise-a-jour-donnees-personnelles',
        whatsapp_alert_link: 'mise-a-jour-alerte-whatsapp',
        reset_link: 'reinitialiser',
        error_link: 'erreur',
        locale_contact_link:'contacter',
        locale_org_contact_link:':orgName/contacter',
        all_office_link: 'nos-bureaux',
        office_locator_link: 'bureaux-pres-de-moi',
        office_locator_result_link: 'bureaux-pres-de-moi/result',
        locale_recruitment_link:'recrutement',
        locale_org_recruitment_link:':orgName/recrutement',
        recruiting_now_link: 'vous-recrutez',
        locale_my_account_link:'mon-compte',
        locale_org_my_account_link:':orgName/mon-compte',
        unsubscribe_email_confirmed_link : 'confirmation-desinscription-email',
        gdpr_marketing_pref_link: 'preferences-marketing',
        gdpr_marketing_pref_thankyou_link: 'confirmation-preferences-marketing',
        my_profile_link: 'profil',
        saved_searches_link : 'alertes-emploi',
        verify_alert_link : 'creation-alerte-emploi',
        job_alert_success_link : 'validation-alerte-emploi',
        delete_alert_link: 'suppression-alerte-emploi',
        manage_cv_link: 'gestion-cv',
        change_email_link: 'modification-email',
        change_password_link: 'changement-mot-de-passe',
        update_whatsapp_link: 'mise-a-jour-numero-whatsapp',
        save_whatsapp_link: 'confirmation-mise-a-jour-whatsapp-alerte',
        go1_link: 'partner/go1/training/login',
        view_email_link: 'viewemailink',
      gdpr_marketing_pref_contact_link: 'marketing-preferences-contact'
    },
    pt_PT: {
        dropbox_link: 'DropBox',
        gdrive_link: 'GDrive',
        locale_job_search_link: 'emprego-pesquisa',
        locale_org_job_search_link: ':orgName/emprego-pesquisa',
        search_link: ':searchTerm',
        locale_job_detail_link: 'detalhes-oferta',
        locale_org_job_detail_link: ':orgName/detalhes-oferta',
        job_detail_link: ':recordId',
        job_apply_link: ':recordId/candidatura',
        job_apply_confirmation_link: ':recordId/emprego-confirmado',
        locale_account_link: 'conta',
        locale_org_account_link: ':orgName/conta',
        spec_cv_link: 'enviar-cv',
        register_link: 'registo',
        success_link: 'enviado',
        login_link: 'login',
        forgot_password_link: 'login/forgot-password',
        saved_jobs_link: 'ofertas-guardadas',
        unsubscribe_link: 'unsubscribe',
        unsubscribe_email_link: 'confirmacao-unsubscribe',
        update_preferences_email_link: 'atualizar-preferencias-email',
        sent_preferences_email_link: 'enviar-preferencias-email',
        update_your_details_link: 'enviar-detalhes',
        update_your_privacy_link: 'atualizar-privacidade-dados',
        whatsapp_alert_link: 'update-whatsapp-numero-email',
        reset_link: 'reset',
        error_link: 'erro',
        locale_contact_link: 'contactos',
        locale_org_contact_link: ':orgName/contactos',
        all_office_link: 'escritorios',
        office_locator_link: 'escritorios-proximos',
        office_locator_result_link: 'escritorios-proximos/result',
        locale_recruitment_link: 'recrutamento',
        locale_org_recruitment_link: ':orgName/recrutamento',
        recruiting_now_link: 'a-recrutar',
        locale_my_account_link: 'minha-conta',
        locale_org_my_account_link: ':orgName/minha-conta',
        unsubscribe_email_confirmed_link: 'unsubscribe-email-confirmado',
        gdpr_marketing_pref_link: 'preferencias-marketing',
        gdpr_marketing_pref_thankyou_link: 'preferencias-marketing/confirmado',
        my_profile_link: 'perfil',
        saved_searches_link: 'empregos-guardados',
        verify_alert_link: 'confirmar-alerta',
        job_alert_success_link: 'alerta-emprego-guardado',
        delete_alert_link: 'apagar-alerta',
        manage_cv_link: 'gerir-cvs',
        change_email_link: 'mudar-email',
        change_password_link: 'mudar-password',
        update_whatsapp_link: 'update-whatsapp',
        save_whatsapp_link: 'numero-whatsapp-confirmado',
        go1_link: 'partner/go1/training/login',
        view_email_link: 'viewemailink',
      gdpr_marketing_pref_contact_link: 'marketing-preferences-contact'
    },
    mx_MX: {
        dropbox_link: 'DropBox',
        gdrive_link: 'GDrive',
        locale_job_search_link: 'empleo-buscar',
        locale_org_job_search_link: ':orgName/empleo-buscar',
        search_link: ':searchTerm',
        locale_job_detail_link: 'empleo-detalles',
        locale_org_job_detail_link: ':orgName/empleo-detalles',
        job_detail_link: ':recordId',
        job_apply_link: ':recordId/aplica-ahora',
        job_apply_confirmation_link: ':recordId/empleo/aplicacion/confirmacion',
        locale_account_link: 'portal',
        locale_org_account_link: ':orgName/portal',
        spec_cv_link: 'enviar-CV',
        register_link: 'registro',
        success_link: 'exito',
        login_link: 'inicio-de-sesion',
        forgot_password_link: 'inicio-de-sesion/forgot-password',
        saved_jobs_link: 'empleo-guardado',
        unsubscribe_link: 'anular-suscripcion',
        unsubscribe_email_link: 'confirmacion-anulacion-suscripcion-emai',
        update_preferences_email_link: 'email-de-confirmacion',
        sent_preferences_email_link: 'enviado-preferencias-email',
        update_your_details_link: 'actualizar-informacion',
        update_your_privacy_link: 'actualizar-politicas-de-privacidad',
        whatsapp_alert_link: 'alerta-whatsapp/actualizar-alerta',
        reset_link: 'restablecer',
        error_link: 'error',
        locale_contact_link: 'portal',
        locale_org_contact_link: ':orgName/portal',
        all_office_link: 'oficinas',
        office_locator_link: 'oficinas-cerca-de-mi',
        office_locator_result_link: 'oficinas-cerca-de-mi/result',
        locale_recruitment_link: 'portal',
        locale_org_recruitment_link: ':orgName/portal',
        recruiting_now_link: 'contratando-ahora',
        locale_my_account_link: 'cuenta',
        locale_org_my_account_link: ':orgName/cuenta',
        unsubscribe_email_confirmed_link: 'confirmacion-anulacion-suscripcion-confirmado',
        gdpr_marketing_pref_link: 'preferencias-marketing',
        gdpr_marketing_pref_thankyou_link: 'preferencias-marketing/gracias',
        my_profile_link: 'perfil',
        saved_searches_link: 'busquedas-guardadas',
        verify_alert_link: 'verificar-alerta',
        job_alert_success_link: 'alerta-empleo-exito',
        delete_alert_link: 'verificar-alerta',
        manage_cv_link: 'gestion-cv',
        change_email_link: 'cambiar-email',
        change_password_link: 'cambiar-contrasena',
        update_whatsapp_link: 'actualizar-whatsapp',
        save_whatsapp_link: 'guardar-whatsapp',
        go1_link: 'partner/go1/training/login',
        view_email_link: 'viewemailink',
      gdpr_marketing_pref_contact_link: 'marketing-preferences-contact'
    },
    cl_CL: {
        dropbox_link: 'DropBox',
        gdrive_link: 'GDrive',
        locale_job_search_link: 'empleo-buscar',
        locale_org_job_search_link: ':orgName/empleo-buscar',
        search_link: ':searchTerm',
        locale_job_detail_link: 'empleo-detalles',
        locale_org_job_detail_link: ':orgName/empleo-detalles',
        job_detail_link: ':recordId',
        job_apply_link: ':recordId/aplica-ahora',
        job_apply_confirmation_link: ':recordId/empleo/aplicacion/confirmacion',
        locale_account_link: 'portal',
        locale_org_account_link: ':orgName/portal',
        spec_cv_link: 'enviar-CV',
        register_link: 'registro',
        success_link: 'exito',
        login_link: 'inicio-de-sesion',
        forgot_password_link: 'inicio-de-sesion/forgot-password',
        saved_jobs_link: 'empleo-guardado',
        unsubscribe_link: 'anular-suscripcion',
        unsubscribe_email_link: 'confirmacion-anulacion-suscripcion-emai',
        update_preferences_email_link: 'email-de-confirmacion',
        sent_preferences_email_link: 'enviado-preferencias-email',
        update_your_details_link: 'actualizar-informacion',
        update_your_privacy_link: 'actualizar-politicas-de-privacidad',
        whatsapp_alert_link: 'alerta-whatsapp/actualizar-alerta',
        reset_link: 'restablecer',
        error_link: 'error',
        locale_contact_link: 'portal',
        locale_org_contact_link: ':orgName/portal',
        all_office_link: 'oficinas',
        office_locator_link: 'oficinas-cerca-de-mi',
        office_locator_result_link: 'oficinas-cerca-de-mi/result',
        locale_recruitment_link: 'portal',
        locale_org_recruitment_link: ':orgName/portal',
        recruiting_now_link: 'contratando-ahora',
        locale_my_account_link: 'cuenta',
        locale_org_my_account_link: ':orgName/cuenta',
        unsubscribe_email_confirmed_link: 'confirmacion-anulacion-suscripcion-confirmado',
        gdpr_marketing_pref_link: 'preferencias-marketing',
        gdpr_marketing_pref_thankyou_link: 'preferencias-marketing/gracias',
        my_profile_link: 'perfil',
        saved_searches_link: 'busquedas-guardadas',
        verify_alert_link: 'verificar-alerta',
        job_alert_success_link: 'alerta-empleo-exito',
        delete_alert_link: 'verificar-alerta',
        manage_cv_link: 'gestion-cv',
        change_email_link: 'cambiar-email',
        change_password_link: 'cambiar-contrasena',
        update_whatsapp_link: 'actualizar-whatsapp',
        save_whatsapp_link: 'guardar-whatsapp',
        go1_link: 'partner/go1/training/login',
        view_email_link: 'viewemailink',
      gdpr_marketing_pref_contact_link: 'marketing-preferences-contact'
    },
    co_CO: {
        dropbox_link: 'DropBox',
        gdrive_link: 'GDrive',
        locale_job_search_link: 'empleo-buscar',
        locale_org_job_search_link: ':orgName/empleo-buscar',
        search_link: ':searchTerm',
        locale_job_detail_link: 'empleo-detalles',
        locale_org_job_detail_link: ':orgName/empleo-detalles',
        job_detail_link: ':recordId',
        job_apply_link: ':recordId/aplica-ahora',
        job_apply_confirmation_link: ':recordId/empleo/aplicacion/confirmacion',
        locale_account_link: 'portal',
        locale_org_account_link: ':orgName/portal',
        spec_cv_link: 'enviar-CV',
        register_link: 'registro',
        success_link: 'exito',
        login_link: 'inicio-de-sesion',
        forgot_password_link: 'inicio-de-sesion/forgot-password',
        saved_jobs_link: 'empleo-guardado',
        unsubscribe_link: 'anular-suscripcion',
        unsubscribe_email_link: 'confirmacion-anulacion-suscripcion-emai',
        update_preferences_email_link: 'email-de-confirmacion',
        sent_preferences_email_link: 'enviado-preferencias-email',
        update_your_details_link: 'actualizar-informacion',
        update_your_privacy_link: 'actualizar-politicas-de-privacidad',
        whatsapp_alert_link: 'alerta-whatsapp/actualizar-alerta',
        reset_link: 'restablecer',
        error_link: 'error',
        locale_contact_link: 'portal',
        locale_org_contact_link: ':orgName/portal',
        all_office_link: 'oficinas',
        office_locator_link: 'oficinas-cerca-de-mi',
        office_locator_result_link: 'oficinas-cerca-de-mi/result',
        locale_recruitment_link: 'portal',
        locale_org_recruitment_link: ':orgName/portal',
        recruiting_now_link: 'contratando-ahora',
        locale_my_account_link: 'cuenta',
        locale_org_my_account_link: ':orgName/cuenta',
        unsubscribe_email_confirmed_link: 'confirmacion-anulacion-suscripcion-confirmado',
        gdpr_marketing_pref_link: 'preferencias-marketing',
        gdpr_marketing_pref_thankyou_link: 'preferencias-marketing/gracias',
        my_profile_link: 'perfil',
        saved_searches_link: 'busquedas-guardadas',
        verify_alert_link: 'verificar-alerta',
        job_alert_success_link: 'alerta-empleo-exito',
        delete_alert_link: 'verificar-alerta',
        manage_cv_link: 'gestion-cv',
        change_email_link: 'cambiar-email',
        change_password_link: 'cambiar-contrasena',
        update_whatsapp_link: 'actualizar-whatsapp',
        save_whatsapp_link: 'guardar-whatsapp',
        go1_link: 'partner/go1/training/login',
        view_email_link: 'viewemailink',
      gdpr_marketing_pref_contact_link: 'marketing-preferences-contact'
    },
    br_BR: {
        dropbox_link: 'DropBox',
        gdrive_link: 'GDrive',
        locale_job_search_link: 'empregos-pesquisar',
        locale_org_job_search_link: ':orgName/empregos-pesquisar',
        search_link: ':searchTerm',
        locale_job_detail_link: 'emprego-detalhes',
        locale_org_job_detail_link: ':orgName/emprego-detalhes',
        job_detail_link: ':recordId',
        job_apply_link: ':recordId/candidatura',
        job_apply_confirmation_link: ':recordId/vagas/candidatura/confirmacao',
        locale_account_link: 'portal',
        locale_org_account_link: ':orgName/portal',
        spec_cv_link: 'enviar-CV',
        register_link: 'registro',
        success_link: 'cv-enviado',
        login_link: 'login',
        forgot_password_link: 'login/forgot-password',
        saved_jobs_link: 'emprego-salvo',
        unsubscribe_link: 'cancelar-inscricao',
        unsubscribe_email_link: 'cancelar-inscricao-email',
        update_preferences_email_link: 'email-de-confirmacao',
        sent_preferences_email_link: 'enviado-preferencias-email',
        update_your_details_link: 'atualizacao-informacao',
        update_your_privacy_link: 'atualizacao-politica-de-privacidade',
        whatsapp_alert_link: 'alerta-whatsapp/atualizacao-alerta',
        reset_link: 'redefinir',
        error_link: 'erro',
        locale_contact_link: 'portal',
        locale_org_contact_link: ':orgName/portal',
        all_office_link: 'escritorios',
        office_locator_link: 'escritorios-proximos',
        office_locator_result_link: 'escritorios-proximos/result',
        locale_recruitment_link: 'portal',
        locale_org_recruitment_link: ':orgName/portal',
        recruiting_now_link: 'recrutando-agora',
        locale_my_account_link: 'conta',
        locale_org_my_account_link: ':orgName/conta',
        unsubscribe_email_confirmed_link: 'cancelar-inscricao-email-confirmacao',
        gdpr_marketing_pref_link: 'preferencias-marketing',
        gdpr_marketing_pref_thankyou_link: 'preferencias-marketing/obrigado',
        my_profile_link: 'perfil',
        saved_searches_link: 'pesquisas-salvas',
        verify_alert_link: 'verificar-alerta',
        job_alert_success_link: 'alerta-emprego-sucesso',
        delete_alert_link: 'verificar-alerta',
        manage_cv_link: 'gerenciar-cv',
        change_email_link: 'alterar-email',
        change_password_link: 'alterar-senha',
        update_whatsapp_link: 'atualizacao-whatsapp',
        save_whatsapp_link: 'salvar-whatsapp',
        go1_link: 'partner/go1/training/login',
        view_email_link: 'viewemailink',
      gdpr_marketing_pref_contact_link: 'marketing-preferences-contact'
    },
    en_CA: {
        dropbox_link: 'DropBox',
        gdrive_link: 'GDrive',
        locale_job_search_link: 'job-search',
        locale_org_job_search_link: ':orgName/job-search',
        search_link: ':searchTerm',
        locale_job_detail_link: 'job-detail',
        locale_org_job_detail_link: ':orgName/job-detail',
        job_detail_link: ':recordId',
        job_apply_link: ':recordId/apply',
        job_apply_confirmation_link: ':recordId/job-apply-confirmation',
        locale_account_link: 'account',
        locale_org_account_link: ':orgName/account',
        spec_cv_link: 'submit-resume',
        register_link: 'register',
        success_link: 'resume-confirmation',
        login_link: 'login',
        forgot_password_link: 'login/forgot-password',
        saved_jobs_link: 'saved-jobs',
        unsubscribe_link: 'unsubscribe',
        unsubscribe_email_link: 'unsubscribe-email',
        update_preferences_email_link: 'update-marketing-preferences-email',
        sent_preferences_email_link: 'sent-marketing-preferences-email',
        update_your_details_link: 'update-your-details',
        update_your_privacy_link: 'update-data-privacy',
        whatsapp_alert_link: 'update-whatsapp-number',
        reset_link: 'reset',
        error_link: 'error',
        locale_contact_link: 'contact',
        locale_org_contact_link: ':orgName/contact',
        all_office_link: 'all-offices',
        office_locator_link: 'offices-near-me',
        office_locator_result_link: 'offices-near-me/result',
        locale_recruitment_link: 'recruitment',
        locale_org_recruitment_link: ':orgName/recruitment',
        recruiting_now_link: 'register-job',
        locale_my_account_link: 'my-account',
        locale_org_my_account_link: ':orgName/my-account',
        unsubscribe_email_confirmed_link: 'unsubscribe-email-confirmation',
        gdpr_marketing_pref_link: 'marketing-preferences',
        gdpr_marketing_pref_thankyou_link: 'marketing-preferences-confirmation',
        my_profile_link: 'profile',
        saved_searches_link: 'job-alerts',
        verify_alert_link: 'create-job-alert-email',
        job_alert_success_link: 'job-alert-confirmation',
        delete_alert_link: 'delete-job-alert',
        manage_cv_link: 'manage-resume',
        change_email_link: 'change-email',
        change_password_link: 'change-password',
        update_whatsapp_link: 'update-whatsapp',
        save_whatsapp_link: 'whatsapp-number-confirmation',
        go1_link: 'partner/go1/training/login',
        view_email_link: 'viewemailink',
      gdpr_marketing_pref_contact_link: 'marketing-preferences-contact'
    },
    cf_CA: {
        dropbox_link: 'DropBox',
        gdrive_link: 'GDrive',
        locale_job_search_link: ':lang/emplois-rechercher',
        locale_org_job_search_link: ':lang/:orgName/emplois-rechercher',
        search_link: ':searchTerm',
        locale_job_detail_link: ':lang/description-emploi',
        locale_org_job_detail_link: ':lang/:orgName/description-emploi',
        job_detail_link: ':recordId',
        job_apply_link: ':recordId/poser-une-candidature',
        job_apply_confirmation_link: ':recordId/validation-candidature',
        locale_account_link: ':lang/compte',
        locale_org_account_link: ':lang/:orgName/compte',
        spec_cv_link: 'cv-speculatif',
        register_link: 'inscription-emploi',
        success_link: 'validation-envoi-cv',
        login_link: 'se-connecter',
        forgot_password_link: 'se-connecter/forgot-password',
        saved_jobs_link: 'emplois-favoris',
        unsubscribe_link: 'se-desabonner',
        unsubscribe_email_link: 'se-desabonner-de-l-email',
        update_preferences_email_link: 'mise-a-jour-preference-courriel',
        sent_preferences_email_link: 'envoye-mercatique-preference-email',
        update_your_details_link: 'mise-a-jour-vos-coordonnees',
        update_your_privacy_link: 'mise-a-jour-vos-donnees-personnelles',
        whatsapp_alert_link: 'mise-a-jour-alerte-whatsapp',
        reset_link: 'reinitialiser',
        error_link: 'erreur',
        locale_contact_link: ':lang/contacter',
        locale_org_contact_link: ':lang/:orgName/contacter',
        all_office_link: 'nos-bureaux',
        office_locator_link: 'bureaux-pres-de-chez-moi',
        office_locator_result_link: 'bureaux-pres-de-chez-moi/result',
        locale_recruitment_link: ':lang/recrutement',
        locale_org_recruitment_link: ':lang/:orgName/recrutement',
        recruiting_now_link: 'registre-emploi',
        locale_my_account_link: ':lang/mon-compte',
        locale_org_my_account_link: ':lang/:orgName/mon-compte',
        unsubscribe_email_confirmed_link: 'desabonnement-email-confirmation',
        gdpr_marketing_pref_link: 'preferences-mercatique',
        gdpr_marketing_pref_thankyou_link: 'confirmation-preferences-marketing',
        my_profile_link: 'mon-profil',
        saved_searches_link: 'recherches-sauvegardees',
        verify_alert_link: 'creation-alerte-emploi',
        job_alert_success_link: 'validation-alerte-emploi',
        delete_alert_link: 'suppression-alerte-emploi',
        manage_cv_link: 'gestion-cv',
        change_email_link: 'changer-courriel',
        change_password_link: 'changer-mot-de-passe',
        update_whatsapp_link: 'mise-a-jour-numero-whatsapp',
        save_whatsapp_link: 'confirmation-mise-a-jour-whatsapp-alerte',
        go1_link: 'partner/go1/training/login',
        view_email_link: 'viewemailink',
      gdpr_marketing_pref_contact_link: 'marketing-preferences-contact'
    },
    en_JP: lang_en_routes,
    jp_JP: {
    dropbox_link: 'DropBox',
    gdrive_link: 'GDrive',
    locale_job_search_link: '求人-検索',
    locale_org_job_search_link: ':orgName/求人-検索',
    search_link: ':searchTerm',
    locale_job_detail_link: '求人-詳細',
    locale_org_job_detail_link: ':orgName/求人-詳細',
    job_detail_link: ':recordId',
    job_apply_link: ':recordId/応募',
    job_apply_confirmation_link: ':recordId/求人応募確認',
    locale_account_link: 'アカウント',
    locale_org_account_link: ':orgName/アカウント',
    spec_cv_link: '職務経歴書登録',
    register_link: '登録',
    success_link: '提出完了',
    login_link: 'ログイン',
    forgot_password_link: 'ログイン/forgot-password',
    saved_jobs_link: '保存した求人',
    unsubscribe_link: '配信停止',
    unsubscribe_email_link: 'メール配信停止',
    update_preferences_email_link: 'メールアドレスの確認',
    sent_preferences_email_link: 'ご希望のメールアドレスに送信',
    update_your_details_link: '登録情報の更新',
    update_your_privacy_link: '個人情報の更新',
    whatsapp_alert_link: 'whatsapp更新',
    reset_link: 'リセット',
    error_link: 'エラー',
    locale_contact_link: 'お問い合わせ',
    locale_org_contact_link: ':orgName/お問い合わせ',
    all_office_link: 'オフィス一覧',
    office_locator_link: '最寄りのオフィス',
    office_locator_result_link: '最寄りのオフィス/result',
    locale_recruitment_link: 'アカウント',
    locale_org_recruitment_link: ':orgName/アカウント',
    recruiting_now_link: '求人登録',
    locale_my_account_link: 'マイアカウント',
    locale_org_my_account_link: ':orgName/マイアカウント',
    unsubscribe_email_confirmed_link: 'メール配信停止の確認',
    gdpr_marketing_pref_link: '配信の設定',
    gdpr_marketing_pref_thankyou_link: '配信の設定確認',
    my_profile_link: 'プロフィール',
    saved_searches_link: '新着求人通知',
    verify_alert_link: '新着求人通知の設定',
    job_alert_success_link: '新着求人通知の設定確認',
    delete_alert_link: '新着求人通知の削除',
    manage_cv_link: '職務経歴書の設定',
    change_email_link: 'メールアドレスの変更',
    change_password_link: 'パスワードの変更',
    update_whatsapp_link: 'whatsapp登録',
    save_whatsapp_link: 'whatsapp更新の確認',
    go1_link: 'partner/go1/training/ログイン',
    view_email_link: 'viewemailink',
      gdpr_marketing_pref_contact_link: 'marketing-preferences-contact'
    },
    en_CHN: lang_en_routes,
    cn_CHN: en_routes,
    // cn_CHN: {
    //     dropbox_link: 'DropBox',
    //     gdrive_link: 'GDrive',
    //     locale_job_search_link: '职位-搜索',
    //     locale_org_job_search_link: ':orgName/职位-搜索',
    //     search_link: ':searchTerm',
    //     locale_job_detail_link: '职位-详情',
    //     locale_org_job_detail_link: ':orgName/职位-详情',
    //     job_detail_link: ':recordId',
    //     job_apply_link: ':recordId/申请',
    //     job_apply_confirmation_link: ':recordId/职位-申请成功',
    //     locale_account_link: '账号',
    //     locale_org_account_link: ':orgName/账号',
    //     spec_cv_link: '发送简历',
    //     register_link: '注册',
    //     success_link: '简历上传成功',
    //     login_link: '登录',
    //     forgot_password_link: '登录/forgot-password',
    //     saved_jobs_link: '职位收藏',
    //     unsubscribe_link: '取消订阅',
    //     unsubscribe_email_link: '取消电邮订阅',
    //     update_preferences_email_link: '修改电邮设置',
    //     sent_preferences_email_link: '发送电邮设置',
    //     update_your_details_link: '详细信息更新',
    //     update_your_privacy_link: 'update-data-privacy',
    //     whatsapp_alert_link: '修改微信号',
    //     reset_link: '重置账号',
    //     error_link: '报错',
    //     locale_contact_link: '联系我们',
    //     locale_org_contact_link: ':orgName/联系我们',
    //     all_office_link: '所有办公室',
    //     office_locator_link: '附近办公室',
    //     office_locator_result_link: '附近办公室/result',
    //     locale_recruitment_link: '招聘',
    //     locale_org_recruitment_link: ':orgName/招聘',
    //     recruiting_now_link: '开始招聘',
    //     locale_my_account_link: '我的账号',
    //     locale_org_my_account_link: ':orgName/我的账号',
    //     unsubscribe_email_confirmed_link: '确认取消电邮订阅',
    //     gdpr_marketing_pref_link: '营销设置',
    //     gdpr_marketing_pref_thankyou_link: '确认营销设置',
    //     my_profile_link: '个人资料',
    //     saved_searches_link: '职位提醒',
    //     verify_alert_link: '创建职位提醒电邮',
    //     job_alert_success_link: '职位提醒设置成功',
    //     delete_alert_link: '取消职位提醒',
    //     manage_cv_link: '管理简历',
    //     change_email_link: '修改电邮',
    //     change_password_link: '修改密码',
    //     update_whatsapp_link: '修改微信号',
    //     save_whatsapp_link: '微信号确认',
    //     go1_link: 'partner/go1/training/登录',
    //     view_email_link: 'viewemailink'
    // },
    // en_JP: { ...lang_en_routes, verify_alert_link: 'VerifyAlertV2' },
    // jp_JP: { ...en_routes, verify_alert_link: 'VerifyAlertV2' },
    en_NZL: en_routes,
    en_MLS: en_routes,
    en_SGP: en_routes,
    en_HKG: en_routes,
    en_AUS: en_routes,
    en_TH: en_routes
}

