
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environmentLocal.local.ts` with `environmentLocal.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { en_JP } from '../apiconfig/APAC/jobportal/preprod/en-JP';
import { jp_JP } from '../apiconfig/APAC/jobportal/preprod/jp-JP';
import { en_CHN } from '../apiconfig/APAC/jobportal/preprod/en-CHN';
import { cn_CHN } from '../apiconfig/APAC/jobportal/preprod/cn-CHN';
import {en_AUS} from '../apiconfig/APAC/jobportal/preprod/en-AUS';
import {en_NZL} from '../apiconfig/APAC/jobportal/preprod/en-NZL';
import {en_SGP} from '../apiconfig/APAC/jobportal/preprod/en-SGP';
import {en_MLS} from '../apiconfig/APAC/jobportal/preprod/en-MLS';
import {en_HKG} from '../apiconfig/APAC/jobportal/preprod/en-HKG';
import {en_TH} from '../apiconfig/APAC/jobportal/preprod/en-TH';

export const environment = {
  azure_enable:false,
  translations: '/assets/messages/jobportal/APAC/messages_',
  production: true,
  schedule: '45m',
  size: '500m',
  compress: true,
  count: 100,
  port:4002,
  platform: 'preprod-apac',
  jobportal_en_JP:en_JP,
  jobportal_jp_JP: jp_JP,
  jobportal_en_CHN: en_CHN,
  jobportal_cn_CHN: cn_CHN,
  jobportal_en_AUS: en_AUS,
  jobportal_en_NZL: en_NZL,
  jobportal_en_SGP: en_SGP,
  jobportal_en_MLS: en_MLS,
  jobportal_en_HKG: en_HKG,
  jobportal_en_TH: en_TH,
  hmac_key: 'MMJtsDKpqU8N7jXy',
  hmac_secret: 'WXkVVp4Ss2sfsTsA',
  google_captcha_client_key:'6Leb5hUTAAAAAFzQ2ThiPeTqaWujG5kVnsK2QLaZ',
  google_api_key:'AIzaSyDQvGyIJJLhVBRctZOAICL13NR9Wo1Q-uE',
  captchaJs:'https://www.recaptcha.net/recaptcha/api.js',
  getDefaultLang:'/user/browse/v1/getdefaultlang',
  baseAPIURL:'https://mcoat.hays.com.au/jobportal/int/ns/',
  indeedAPIURL:'https://cprofile1test.hays.com.au/portalservice/resources/registration/applyindeed/',
  indeedV2APIURL:'https://cprofile1test.hays.com.au/portalservice/resources/registration/applyindeedv2/',
  indeedExpressConsentAPIURL:'https://cprofile1test.hays.com.au/portalservice/resources/registration/applyindeeddk/',
  isChinaPopup : false,
};

export const config={
  SUCCESS:'HAYS_200',
  mobNumberPattern:'^((\\+91-?)|0)?[0-9]*$',
  countryCode:'/^([+]{1})[0-9]*$/',
  headerUri:'/header',
  notFoundStatus :'HAYS_204',
  fileUnit:['Bytes', 'KB', 'MB', 'GB', 'TB'],
  theme:'jobportal_',
  recruitingNowSuccess:'UC001',
  portalSuccessCode: 'WC000',
  invalidParamCode: 'WC001',
  emailAlreadyVerified: 'WC008',
  emailDoesNotExist:'WC010',
  existingEmailAddress: 'WC017',
  passwordIncorrect: 'WC025',
  loginCounterCode: 'WC002',
  portalFailureCode: 'WC002',
  emailPattern: '^([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,5})$',
  youtubePattern:'http(?:s)?:\\/\\/(?:m.)?(?:www\\.)?youtu(?:\\.be\\/|be\\.com\\/(?:watch\\?(?:feature=youtu.be\\&)?v=|v\\/|embed\\/|user\\/(?:[\\w#]+\\/)+))([^&#?\\n]+)',
  youtubeEmbedUrl:'https://www.youtube.com/embed/',
  youtubeEmbedProperties:'?enablejsapi=1&autoplay=1&mute=1',
  passwordPattern: '^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,32}$',
  protectedFileUpload: 'Protected file found',
  infectedFileUpload: 'Infected file found',
  usedIdentifiermsg: 'Identifier/Email does not exist',
  usedIdentifierCode : 'WC026',
  expireCode: '997',
  expireMsg: 'Data has been expired no decryption will be performed',
  mobileDevice: 'HaysGCJ-Mobile',
  desktopDevice:'HaysGCJ-Desktop',
  footerUrl:'office-locator,spec',
  crossCountryUrl:'/jobportal/as/job/browse/details',
  gdprEmailNotExist: 'WC005',
  followHaysUrl:"https://www.linkedin.com/company/hays",
  baseWebAppUrl : typeof window !== "undefined" ? window.location.origin:'',
  haysTechOrgCode:'HAYSTECH003',
  ALREADY_EXIST:'HAYS_409',
  HaysCareerJob: 'HCP005',
  HaysCareer: 'careers',
  HaysHTSJob: 'HTS007',
  HaysHTS: 'hts',
  ExpertClientsName: ['careers','hts'],
  ExpertClientsCode: ['HCP005','HTS007']
};
export const apiEndPoints={
  getDropBoxFiles:'getDropBoxFiles',
  connectdropbox:'connectdropbox',
  getFileLink: 'getFileLink',
  getSector:'getSector',
  getStateList:'getStateList',
  getLocationHierarchy:'getLocationHierarchy',
  getOfficeForSpecialism: 'getOfficeForSpecialism',
  getAllOffice:'getAllOffice',
  getSubSpecialism:'getSubSpecialism',
  getOfficeResultForAPAC:'getOfficeResultForAPAC',
  getLatLongOfficeResult:'getLatLongOfficeResult' ,
  isAutoSugesstionEnabled:'isAutoSugesstionEnabled',
  countryLanguage:'countryLanguage',
  isCaptchaDisabled:'isCaptchaDisabled',
  pagemanagement:'pagemanagement',
  pagemanagementSecure:'pagemanagementSecure',
  locationOnRecruitingNow:'locationOnRecruitingNow',
  employeeType:'employeeType',
  getTermsAndConditions:'getTermsAndConditions',
  go1getTermsAndConditions:'go1getTermsAndConditions',
  getTermsAndConditionsApply: 'getTermsAndConditionsApply',
  getTermsAndConditionsRegister: 'getTermsAndConditionsRegister',
  submitRecruitingForm:'submitRecruitingForm',
  getBrowseByExpertise:'getBrowseByExpertise',
  showOfficeEmail:'showOfficeEmail',
  getPagemenu:'getPagemenu',
  connectGdrive:'connectGdrive',
  gdriveFileLink:'gdriveFileLink',
  getDriveFiles:'getDriveFiles',
  liferaybaseUrl:'liferaybaseUrl',
  liferayclientBaseUrl:'liferayclientBaseUrl',
  liferayUrl:'liferayUrl',
  liferayCognizantUrl:'liferayCognizantUrl',
  htsBaseUrl: 'htsBaseUrl',
  getIsKanaEnabled:'getIsKanaEnabled',
  getGDriveAndDropbox:'getGDriveAndDropbox',
  register:'register',
  getAutoSuggetion:'getAutoSuggetion',
  gateWayAPI:'gateWayAPI',
  submitEmail: 'submitEmail',
  getgooglejobs:'getgooglejobs',
  jobflowconfig:'jobflowconfig',
  getJobFilters:'getJobFilters',
  decryptIdentifier:'decryptIdentifier',
  validateAlert:'validateAlert',
  getGdprConfig:'getGdprConfig',
  getMarketingPreferences:'getMarketingPreferences',
  updateMarketingPreferences: 'updateMarketingPreferences',
  getCustomerServiceEmail: 'getCustomerServiceEmail',
  createToken:'createToken',
  cookieDomain:'cookieDomain',
  cookieBannerDomain:'cookieBannerDomain',
  validatePage: 'validatePage',
  submitForgetPassEmail: 'submitForgetPassEmail',
  interviewCvTips: 'getInterviewAndCvTips',
  getRecentApplication:'getRecentApplication',
  getUpcomingInterview:'getUpcomingInterview',
  getgooglejobssecure:'getgooglejobssecure',
  getpersonaldetails:'getpersonaldetails',
  getGDriveFiles: 'getGDriveFiles',
  gcsPublish: 'gcsPublish',
  saveAlert: 'saveAlert',
  saveWhatsapp: 'saveWhatsapp',
  updateWhatsapp: 'updateWhatsapp',
  getWhatsappNumber: 'getWhatsappNumber',
  getWhatsAppAlerts: 'getWhatsAppAlerts',
  getJobAlerts: 'getJobAlerts',
  saveJob:'saveJob',
  deleteJob: 'deleteJob',
  getSavedJobs: 'getSavedJobs',
  resetPassword:'resetPassword',
  deleteJobAlert: 'deleteJobAlert',
  editAlert: 'editAlert',
  changePassword:'changePassword',
  changeEmail:'changeEmail',
  getCvs:'getCvs',
  downloadCV:'downloadCV',
  deleteCv:'deleteCv',
  getSingleJob: 'getSingleJob',
  uploadcv: 'uploadcv',
  jobApply:'jobApply',
  unsubscribe: 'unsubscribe',
  jobAlertVerify: 'jobAlertVerify',
  getWidgetList: 'getWidgetList',
  applyLinkedin: 'applyLinkedin',
  getKillerQuestions: 'getKillerQuestions',
  getTimeSheet: 'getTimeSheet',
  seekAuthUrl:'seekAuthUrl',
  seekApply:'seekApply',
  dmsDecryptIdentifier:'dmsDecryptIdentifier',
  submitDMSAlert:'submitDMSAlert',
  viewEmailLink:'viewEmailLink',
  go1generateLink:'go1generateLink',
  getGo1Trainings:'getGo1Trainings',
  signedURL:'signedURL',
  getErpMarketingPref:'getErpMarketingPref',
  updateErpMarketingPref:'updateErpMarketingPref',
  submitGlobalConsent:'submitGlobalConsent',
  erpValidateEmail:'erpValidateEmail',
  changePasswordV2:'changePasswordV2',
  marketingConsent: 'marketingConsent',
  getClientConfig:'getClientConfig',
  deleteAllAlerts: 'deleteAllAlerts',
  updateConsent:'updateConsent',
  sendUnsubscribeEmail:'sendUnsubscribeEmail',
  getMarketingPreferencesDK:'getMarketingPreferencesDK',
  updateMarketingPreferencesDK:'updateMarketingPreferencesDK',
  getLanguageSpoken : 'getLanguageSpoken',
  logoutUser:'logoutUser',
  getMarketingPreferencesContact:'getMarketingPreferencesContact',
  updateMarketingPreferencesContact:'updateMarketingPreferencesContact'
};
export const routingEndpoint={
  routing_url:'routing_url',
  routing_url_org:'routing_url_org',
  errorPage:'errorPage',
  successPage:'successPage',
  successUpdateEmailPrefences: 'successUpdateEmailPrefences',
  officeLocatorResultPage:'officeLocatorResultPage',
  officeLocatorPage:'officeLocatorPage',
  speculativeCV:'speculativeCV',
  forgetPassword:'forgetPassword',
  secureSearch:'secureSearch',
  secureJobDetails:'secureJobDetails',
  login:'login',
  search:'search',
  jobdetails:'jobdetails',
  header:'header',
  footer:'footer',
  myProfile:'myProfile',
  jobApply:'jobApply',
  secureJobApply:'secureJobApply',
  gdprThankyouPage: 'gdprThankyouPage',
  secureGdprThankyouPage:'secureGdprThankyouPage',
  marketingPrefPage: 'marketingPrefPage',
  resetPasswordPage:'resetPasswordPage',
  manageCV: 'manageCV',
  secureThankYou: 'secureThankYou',
  thankyou: 'thankyou',
  saveWhatsappPage:'saveWhatsappPage',
  updateWhatsappPage:'updateWhatsappPage',
  unsubscribePage:'unsubscribePage',
  alertThankYouPage:'alertThankYouPage',
  emailConfirmationPage:'emailConfirmationPage',
  recruitingNow:'recruitingNow',
  secureRecruitingNow:'secureRecruitingNow',
  viewEmailLinkPage:'viewEmailLinkPage',
  goLoginPage: 'goLoginPage',
  erpMarketingPref:'erpMarketingPref',
  secureErpMarketingPref:'secureErpMarketingPref',
  erpEmailConfirmation: 'erpEmailConfirmation',
  erpManageJobAlert:'erpManageJobAlert',
  secureErpManageJobAlert: 'secureErpManageJobAlert',
  erpThankyouPage: 'erpThankyouPage',
  secureErpThankyouPage:'secureErpThankyouPage',
  searchLinkTitle:'searchLinkTitle',
  searchLinkLocation:'searchLinkLocation',
  searchLinkInLocation:'searchLinkInLocation',
  savedSearchesPage: 'savedSearchesPage',
  shortlistedJobsPage: 'shortlistedJobsPage',
  changeEmailPage:'changeEmailPage',
  emailConfirmation:'emailConfirmation',
  marketingPrefContactPage:'marketingPrefContactPage'
};

export const keywords={
  radiusGeocoder:'radius_geocoder',
  defaultRadius:'default_radius',
  distanceUnit:'distance_unit',
  GetSpecialism:'GetSpecialism',
  GetOfficeForSpecialism:'GetOfficeForSpecialism',
  officeLocPlaceholder:"Enter a city or postal code",
  success:'SUCCESS',
  fail:'FAIL',
  invalid:'INVALID',
  expired:'expired',
  mailto:'mailto:',
  tel:'tel:',
  gdprFlag:'gdprFlag',
  email:'email',
  sms:'sms',
  whatapp:'whatapp',
  survey:'survey',
  resetFlag:'resetFlag',
  changeEmail:'ChangeEmail',
  changePassword: 'ChangePassword',
  findUsWidget: 'find us',
  changeEmailWidget: 'Change email',
  changePasswordWidget: 'change password',
  forgetPasswordWidget: 'forgot password',
  savedSearchesWidget: 'saved searches',
  jobApplyWidget: 'job apply',
  jobDetailWidget: 'job detail',
  specCvWidget: 'Speculative CV',
  allOfficesWidget: 'all offices',
  errorPageWidget: 'error page',
  savedJobsWidget: 'shortlisted jobs',
  jobAppliedWidget: 'job applied',
  myProfileWidget: 'my profile',
  manageCVPageName: 'manageCV',
  loginPageWidget: 'login',
  recruitingNowPageName: 'recruiting-now',
  whatsappPageName:'whatsAppNumber',
  marketingPrefPageName:'marketingPref',
  searchPageName: 'search',
  followHaysText:'follow hays',
  officeLocatorText:'office_locator',
  recentSearchText: 'recent_search',
  recruitingNowText :'recruiting now',
  salaryGuideText: 'salary guide',
  specCvText: 'speculative cv',
  timesheetText: 'timesheet',
  go1TrainingText: 'Go1Training',
  consAndProptyText:'Construction and Property',
  hays:'hays',
  haysGCJ:'HaysGCJ',
  seekApplyUrl:'/seekApply/',
  orgName:'it',
  orgSectorName:'hays:Technology',
  updateYourDetailsUrl:'update-your-details',
  speccv:'speccv',
  bdcall:'bdcall'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
