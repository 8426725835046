<section *ngIf="!isAlertErrorFlag">
  <div class="no-data-found error">
    <i class="icon-warning">
      <img src="assets/images/errors_icon.jpg">
    </i>
    <div *ngIf="query" id="recruiting-now-form">
      <div *ngIf="query.trainingRegister==='N' || query.orgName==='Incorrect'  || query.recruitingNow==='FAIL' || query.submitCV==='N' || query.getSavedAlert==='N' || query.getSavedJob==='N' ||
     query.jobApply==='N' || query.deleteJobAlert==='N' || query.gdprStatus==='FAIL' || query.emailConfirmation==='N' || query.changeEmail==='N' || query.changePassword==='N'">
        <div [translate]="'error_message_internal_error'"></div>
        <div [translate]="'error_page_info'"></div>
        <span [translate]="'error_page_search_jobs'"></span>
      </div>
      <div *ngIf="query.notFound==='404' ">
        <div [translate]="'error_page_sorry_message'"></div>
        <div> {{'error_page_info'|translate}} <a [translate]="'error_page_search_jobs'"></a></div>
      </div>
      <div *ngIf="query.erpExpire==='Y' ">
        <div [translate]="'error_page_erp_expire_message'"></div>
      </div>
      <div *ngIf="query.erpStatus==='FAIL' ">
        <div [translate]="'erp_service_fail_message'"></div>
        <a [href]="mailtoText+erpCustomerCareId" [textContent]="erpCustomerCareId"></a>
      </div>
      <div *ngIf="query.unsubscribe==='N' ">
        <div><span>{{'standardmsg_thankyou'|translate}} {{'alert_msg_unsubscribe'|translate}} {{'success_msg_on_err_page'|translate}} {{'error_page_search_jobs'|translate}} {{'login_form_register_text2'|translate}}
          <a id="gtm_unsubscribealert_login_redirect" [href]="loginUrl"
             [translate]="'success_msg_on_err_page1'"></a> {{'success_msg_on_err_page2' | translate}}</span></div>
      </div>
      <div *ngIf="query.activated==='E' ">
        <div><span>{{'alert_previously_active_text'|translate}}</span>
          <span
          *ngIf="_siteService.getDomain()!=='JP'">{{'alert_already_active_sub_text'|translate}}
          <a id="gtm_unsubscribealert_login_redirect1" [href]="loginUrl"
             [translate]="'success_msg_on_err_page1'"></a> {{'success_msg_on_err_page2' | translate}}</span>
          <span
            *ngIf="_siteService.getDomain()==='JP'">{{'alert_already_active_sub_text'|translate}} {{'login_form_register_text2'|translate}} {{'alert_previously_active_general'|translate}}
            <a id="gtm_unsubscribealert_login_redirect2" [href]="loginUrl" [translate]="'success_msg_on_err_page1'"></a></span>
        </div>
      </div>
      <div *ngIf="query.globalConsent==='N' || query.updateConsent==='N'">
        <span [translate]="'candidate_update_consent_error_text'"></span>
      </div>
      <div *ngIf="query.authorizationTimeout==='Y'">
        <span>Something went wrong</span>
      </div>
    </div>
  </div>

</section>

<section *ngIf="isAlertErrorFlag">
    <div class="container" >
      <ul class="">
        <li class="px-4 py-4 mb-4 bg-neutral rounded-lg border">
          <h3  class="fs-600 fs-lg-700 font-weight-bold lh-solid text-brand-blue pt-1 mt-1" >
            {{alert_error_link_expired_text}}
          </h3>
          <!--Japan country Flag-->
          <p *ngIf="isJapanCountry" class="lh-regular text-black mb-0" >
            <a  class="text-teal-dark mr-1" target='_blank' [href]= "lifeRaybaseURL + searchURL" >{{alert_error_job_search_text}}</a> {{alert_error_return_to_text}}
            {{alert_error_new_alert_text}}
          </p>
          <!--Italy country Flag-->
          <p *ngIf="isItalyCountry" class="lh-regular text-black mb-0" >
            {{alert_error_return_to_text}}{{alert_error_new_alert_text}}
            <a  class="text-teal-dark mr-1" target='_blank' [href]= "lifeRaybaseURL + searchURL" >{{alert_error_job_search_text}}</a>
          </p>
          <!--other country Flag-->
          <p  *ngIf="!isJapanCountry && !isItalyCountry" class="lh-regular text-black mb-0" >
            {{alert_error_return_to_text}}
            <a  class="text-teal-dark mr-1 job-underline" target='_blank' [href]= "lifeRaybaseURL + searchURL" >{{alert_error_job_search_text}}</a>
            {{alert_error_new_alert_text}}
          </p>
         </li>
      </ul>
      <div class="row">
        <div class="mb-4 col-12 col-sm-12 col-md-6">
          <div class="border px-4 h-100">
            <h3 class="text-brand-blue fs-600 fs-lg-700"><strong >{{alert_error_for_jobseekers_text}}</strong></h3>
            <p >{{alert_error_for_jobseekers_heading_text}}</p>

            <div class="mt-4 rte-section-links">
              <div class="pb-3">
                <a class="button button--no-style-teal-dark button--arrow" target='_blank' [href]= "lifeRaybaseURL + searchURL">
                  <span class="font-weight-bold" >{{alert_error_search_for_job_text}}</span>
                </a>
              </div>
              <div class="pb-3">
                <a class="button button--no-style-teal-dark button--arrow" target='_blank' [href]="lifeRaybaseURL +errorPageCareerAdvice">
                  <span class="font-weight-bold" >{{alert_error_get_career_advice_text}}</span>
                </a>
              </div>
              <div class="pb-3">
                <a class="button button--no-style-teal-dark button--arrow" target='_blank' [href]="azureLoginURL">
                  <span class="font-weight-bold" >{{alert_error_log_into_my_hays_text}}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-12 col-md-6">
          <div class="border px-4">
            <h3 class="text-brand-blue fs-600 fs-lg-700"><strong >{{alert_error_for_organisations_text}}</strong></h3>
            <p >{{alert_error_for_organisations_heading_text}}</p>

            <div class="mt-4 rte-section-links">
              <div class="pb-3" *ngIf="isErrorPageRecruitNowLink">
                <a class="button button--no-style-teal-dark button--arrow" target='_blank' [href]="errorPageRecruitNow">
                  <span class="font-weight-bold" >{{alert_error_register_vacancy_text}}</span>
                </a>
              </div>
              <div class="pb-3" *ngIf="!isErrorPageRecruitNowLink">
                <a class="button button--no-style-teal-dark button--arrow" target='_blank' [href]="lifeRaybaseURL + errorPageRecruitNow">
                  <span class="font-weight-bold" >{{alert_error_register_vacancy_text}}</span>
                </a>
              </div>
              <div class="pb-3">
                <a class="button button--no-style-teal-dark button--arrow" target='_blank' [href]="lifeRaybaseURL + errorPageRecruitment">
                  <span class="font-weight-bold" >{{alert_error_view_hays_services_text}}</span>
                </a>
              </div>
              <div class="pb-3">
                <a class="button button--no-style-teal-dark button--arrow" target='_blank' [href]="lifeRaybaseURL + errorPageMarketInsights">
                  <span class="font-weight-bold" >{{alert_error_explore_market_insights_text}}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</section>

