import { Component, Inject, OnDestroy, OnInit, Optional, PLATFORM_ID } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {SiteService} from '../../../services/site/site.service';
import {
  apiEndPoints,
  config,
  environment,
  keywords,
  routingEndpoint
} from '../../../../../environments/root/environment';
import {DataSharingService} from '../../../../jobportal/service/data-sharing.service';
import {JobSearchService} from '../../../../jobportal/service/job-search.service';
import {map} from "rxjs/operators";
import {CustomerServiceEmail, EmailConfirmation} from "../../../../jobportal/model/request/email-confirmation";
import {RequestService} from "../../../services/request/request.service";
import { SetTitleService } from 'src/app/jobportal/service/set-title.service';
import { REQUEST, RESPONSE } from '@nguniversal/express-engine/tokens';
import {Request , Response } from 'express';
import { isPlatformServer } from '@angular/common';
import { Subscription } from 'rxjs';
import {AzureGaService} from "../../../services/azure-ga/azure-ga.service";

@Component({
  selector: 'app-error-pages',
  templateUrl: './error-pages.component.html',
  styleUrls: ['./error-pages.component.scss']
})
export class ErrorPagesComponent implements OnInit, OnDestroy {

  public query:any;
  private baseUrl: string;
  public loginUrl: string;
  public emailConfirmation: string;
  public apacCountry: boolean;
  public erpCustomerCareId: string;
  public mailtoText: string = '';
  SubscriptionList:Subscription[]=[];

  siteBaseUrl: any;
  lifeRaybaseURL: any;
  azureLoginURL: any;
  errorPageRecruitNow:any;
  errorPageCareerAdvice:any;
  errorPageRecruitment:any;
  errorPageMarketInsights:any;
  searchURL:any;
  public alert_error_link_expired_text: any;
  public alert_error_return_to_text: any;
  public alert_error_job_search_text: any;
  public alert_error_new_alert_text: any;
  public alert_error_for_jobseekers_text: any;
  public alert_error_for_jobseekers_heading_text: any;
  public alert_error_search_for_job_text: any;
  public alert_error_get_career_advice_text: any;
  public alert_error_log_into_my_hays_text: any;
  public alert_error_for_organisations_text: any;
  public alert_error_for_organisations_heading_text: any;
  public alert_error_register_vacancy_text: any;
  public alert_error_view_hays_services_text: any;
  public alert_error_explore_market_insights_text: any;
  public isJapanCountry: boolean;
  public isItalyCountry: boolean;
  public isBelgiumCountry: boolean;
  public isErrorPageRecruitNowLink:boolean = false;
  public alertErrorValue: string;
  public isAlertErrorFlag: boolean=false;

  constructor(private activateRoute:ActivatedRoute, private translate:TranslateService,
              public jobSearchService: JobSearchService, public _siteService:SiteService,
              private dataSharingService : DataSharingService, private requestService : RequestService, private router:Router,private titleService:SetTitleService,
              @Optional() @Inject(REQUEST) private request: Request,private activatedRoute: ActivatedRoute,
              @Optional() @Inject(RESPONSE) private response: Response, @Inject(PLATFORM_ID) private platformId: any,private gaService: AzureGaService) {
    this.getAzureLoginURL();
    this.translate.setDefaultLang(this._siteService.getSiteLocale());
    if(environment.platform.indexOf('apac')>-1)
      this.apacCountry=true;
    // this.dataSharingService.getWidgetListName(keywords.errorPageWidget);
    this.getRouteUrls();
    const activateRouteQueryParamsSubscription = this.activateRoute.queryParams.subscribe((params)=>{
      if(params)
        this.query=params;
      if(params['lang']) {
        let locale=params['lang'];
        let domain=this._siteService.getDomain();
        this._siteService.setSite2(domain,locale);
        this.translate.setDefaultLang(this._siteService.getSiteLocale());
      }
      if(params['notFound']){
        this.titleService.setTitleForPage('Hays 404 Page');
        if (isPlatformServer(this.platformId)) {
          this.response.status(404);
        }
      }
      this.SubscriptionList.push(activateRouteQueryParamsSubscription);
    });
    this.siteBaseUrl =environment[config.theme + this._siteService.getSiteLocale()]['config']['siteBaseUrl'];
    this.lifeRaybaseURL = environment[config.theme + this._siteService.getSiteLocale()]['liferaybaseUrl'];
    this.errorPageRecruitNow =environment[config.theme + this._siteService.getSiteLocale()]['config']['errorPageRecruitNow'];
    this.errorPageCareerAdvice =environment[config.theme + this._siteService.getSiteLocale()]['config']['errorPageCareerAdvice'];
    this.errorPageMarketInsights =environment[config.theme + this._siteService.getSiteLocale()]['config']['errorPageMarketInsights'];
    this.errorPageRecruitment =environment[config.theme + this._siteService.getSiteLocale()]['config']['errorPageRecruitment'];
    this.isErrorPageRecruitNowLink =environment[config.theme + this._siteService.getSiteLocale()]['config']['isErrorPageRecruitNowLink'];
    this.searchURL =environment[config.theme + this._siteService.getSiteLocale()]['config']['searchURL'];
    this.translate.getTranslation(this._siteService.getSiteLocale()).subscribe(response => {
      this.alert_error_link_expired_text = response.alert_error_link_expired_text;
      this.alert_error_for_jobseekers_text = response.alert_error_for_jobseekers_text;
      this.alert_error_return_to_text = response.alert_error_return_to_text;
      this.alert_error_job_search_text = response.alert_error_job_search_text;
      this.alert_error_new_alert_text = response.alert_error_new_alert_text;
      this.alert_error_for_jobseekers_heading_text = response.alert_error_for_jobseekers_heading_text;
      this.alert_error_search_for_job_text = response.alert_error_search_for_job_text;
      this.alert_error_get_career_advice_text = response.alert_error_get_career_advice_text;
      this.alert_error_log_into_my_hays_text = response.alert_error_log_into_my_hays_text;
      this.alert_error_for_organisations_text = response.alert_error_for_organisations_text;
      this.alert_error_for_organisations_heading_text = response.alert_error_for_organisations_heading_text;
      this.alert_error_view_hays_services_text = response.alert_error_view_hays_services_text;
      this.alert_error_explore_market_insights_text = response.alert_error_explore_market_insights_text;
      this.alert_error_register_vacancy_text = response.alert_error_register_vacancy_text;
    })
    this.isJapanCountry = this._siteService.getSiteLocale()=='jp_JP' ? true : false;
    this.isItalyCountry = this._siteService.getSiteLocale()=='it_ITL' ? true : false;

    const activatedRouteQueryParamsSubscription= this.activatedRoute.queryParams.subscribe((params)=> {
      this.alertErrorValue=params['alertValue'];
     if(this.alertErrorValue=='Y')
      {
        this.isAlertErrorFlag=true;
      }
      else
      {
        this.isAlertErrorFlag=false;
      }
    });
  }

  ngOnInit() {
    if(this.query.erpStatus === 'FAIL')
      this.getErpCustomerCareId();
  }
  private getAzureLoginURL(){
    if(this.gaService.azureLoginURL){
      this.azureLoginURL = this.gaService.updateAndGetAzureURL();
    }
    else{
      this.gaService.azureURLObservable.subscribe((response) => {
        if(response){
          this.azureLoginURL = this.gaService.updateAndGetAzureURL();
        }
      });
    }
    if(!this.azureLoginURL && !this.gaService.isIntervalExecuting){
      this.gaService.generateAzureURL();
    }
  }
  getErpCustomerCareId(){
    const requestServiceGetRequestSubscription = this.requestService.getRequest(config.theme, apiEndPoints.gateWayAPI, apiEndPoints.getCustomerServiceEmail)
      .subscribe(response => {
        if (response.status === config.SUCCESS) {
          this.erpCustomerCareId = response.data.customerServiceEmail;
          this.mailtoText = keywords.mailto;
      };
    }, (error) => {
    });
    this.SubscriptionList.push(requestServiceGetRequestSubscription);
  }

  getRouteUrls(){
  let orgName = this.router.routerState.snapshot.root.firstChild.params["orgName"];
  this.loginUrl = environment[config.theme + this._siteService.getSiteLocale()][routingEndpoint.routing_url][routingEndpoint.login];
  this.emailConfirmation = environment[config.theme + this._siteService.getSiteLocale()][routingEndpoint.routing_url][routingEndpoint.emailConfirmationPage];
  if(orgName){
    this.loginUrl = (environment[config.theme + this._siteService.getSiteLocale()][routingEndpoint.routing_url_org][routingEndpoint.login]).replace(':orgName',orgName);
    this.emailConfirmation = (environment[config.theme + this._siteService.getSiteLocale()][routingEndpoint.routing_url_org][routingEndpoint.emailConfirmationPage]).replace(':orgName',orgName);
  }
}
ngOnDestroy(): void {
  this.SubscriptionList.forEach(subscribe=>{
    if(subscribe){
      subscribe.unsubscribe();
  }
  });
}
}
